"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
// Put here messages that are used all over the place
const messages = (0, _reactIntl.defineMessages)({
  formServerError: {
    "id": "vv11HN",
    "defaultMessage": [{
      "type": 0,
      "value": "Er is iets misgegaan"
    }]
  },
  formError: {
    "id": "+hmh3z",
    "defaultMessage": [{
      "type": 0,
      "value": "Nog niet alle velden zijn correct ingevuld."
    }]
  },
  fieldError: {
    "id": "bcGOuW",
    "defaultMessage": [{
      "type": 0,
      "value": "Dit veld is verplicht"
    }]
  },
  maximumCharsError: {
    "id": "GDhrLj",
    "defaultMessage": [{
      "type": 0,
      "value": "Dit veld mag maximaal "
    }, {
      "type": 1,
      "value": "maxLength"
    }, {
      "type": 0,
      "value": " karakters bevatten"
    }]
  },
  minimumAgeError: {
    "id": "XoLBx3",
    "defaultMessage": [{
      "type": 0,
      "value": "Je moet minimaal "
    }, {
      "type": 1,
      "value": "age"
    }, {
      "type": 0,
      "value": " jaar oud zijn om te kunnen boeken"
    }]
  },
  emailError: {
    "id": "yL2j2l",
    "defaultMessage": [{
      "type": 0,
      "value": "Vul een geldige e-mail in"
    }]
  },
  countryCodeError: {
    "id": "AzjR1G",
    "defaultMessage": [{
      "type": 0,
      "value": "Vul een land in"
    }]
  },
  phoneNumberError: {
    "id": "dv5t0G",
    "defaultMessage": [{
      "type": 0,
      "value": "Vul een geldig telefoonnummer in"
    }]
  },
  agreedToTermsError: {
    "id": "YQnCnd",
    "defaultMessage": [{
      "type": 0,
      "value": "Om door te gaan moet u akkoord gaan met de Algemene voorwaarden"
    }]
  },
  ibanError: {
    "id": "Uw900K",
    "defaultMessage": [{
      "type": 0,
      "value": "Vul een geldig rekeningnummer (IBAN) in"
    }]
  },
  guests: {
    "id": "zQ9lS6",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountGuests",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " gast"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " gasten"
          }]
        }
      }
    }]
  },
  adults: {
    "id": "s0nysC",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountAdults",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " volwassene"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " volwassenen"
          }]
        }
      }
    }]
  },
  pets: {
    "id": "iQIAen",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountPets",
      "offset": 0,
      "options": {
        "=0": {
          "value": [{
            "type": 0,
            "value": "0 huisdieren"
          }]
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " huisdier"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " huisdieren"
          }]
        }
      }
    }]
  },
  children: {
    "id": "9on3lD",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountChildren",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " kind"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " kinderen"
          }]
        }
      }
    }]
  },
  babies: {
    "id": "5mYMKc",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountBabies",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " baby"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " baby's"
          }]
        }
      }
    }]
  },
  bedrooms: {
    "id": "1KFcLF",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountBedrooms",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " slaapkamer"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " slaapkamers"
          }]
        }
      }
    }]
  },
  persons: {
    "id": "EJtAkf",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountPersons",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " persoon"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " personen"
          }]
        }
      }
    }]
  },
  inOneBedroom: {
    "id": "L4Z+VF",
    "defaultMessage": [{
      "type": 0,
      "value": "in 1 slaapkamer"
    }]
  },
  youth: {
    "id": "JtZeCg",
    "defaultMessage": [{
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountYouths",
      "offset": 0,
      "options": {
        "=0": {
          "value": []
        },
        "one": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " jongere"
          }]
        },
        "other": {
          "value": [{
            "type": 7
          }, {
            "type": 0,
            "value": " jongeren"
          }]
        }
      }
    }]
  },
  anonymous: {
    "id": "oobqbx",
    "defaultMessage": [{
      "type": 0,
      "value": "Anoniem"
    }]
  },
  moreInfo: {
    "id": "Mv6ZTa",
    "defaultMessage": [{
      "type": 0,
      "value": "Meer informatie"
    }]
  },
  flexCancellation: {
    "id": "7FjBlT",
    "defaultMessage": [{
      "type": 0,
      "value": "Gratis annuleren"
    }]
  },
  flexCancellationUntil: {
    "id": "mNyBCc",
    "defaultMessage": [{
      "type": 0,
      "value": "Tot "
    }, {
      "type": 1,
      "value": "flexCancellationDays"
    }, {
      "type": 0,
      "value": " dagen voor aankomst"
    }]
  },
  flexCancellationDays: {
    "id": "0ixrnk",
    "defaultMessage": [{
      "type": 0,
      "value": "Gratis annuleren kan bij deze accommodatie tot "
    }, {
      "type": 1,
      "value": "latestCancellationDate"
    }, {
      "type": 0,
      "value": ". Eventuele reserveringskosten en verzekeringspremie worden bij annulering altijd in rekening gebracht."
    }]
  },
  buyTickets: {
    "id": "3rOyAb",
    "defaultMessage": [{
      "type": 0,
      "value": "Koop tickets"
    }]
  }
});
var _default = exports.default = messages;