"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _TripGroupInput = _interopRequireDefault(require("./TripGroupInput"));
var _formik = require("formik");
var _TenantContext = require("../context/TenantContext");
var __jsx = _react.default.createElement;
const TripGroupInputField = ({
  name,
  className,
  variant = 'dropdown',
  onClose,
  open,
  maxOccupancy,
  minOccupancy,
  maxExtraBabyOccupancy,
  petsAllowed,
  surfaceElementVariant
}) => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const [{
    value
  },, {
    setValue
  }] = (0, _formik.useField)(name);
  return __jsx(_TripGroupInput.default, {
    className: className,
    loading: false,
    booking: value,
    surfaceElementVariant: surfaceElementVariant,
    setBooking: booking => {
      setValue(booking);
    },
    maxOccupancy: brandConfig.searchBoxGroup?.maxOccupancy ?? maxOccupancy,
    minOccupancy: brandConfig.searchBoxGroup?.minOccupancy ?? minOccupancy,
    maxExtraBabyOccupancy: maxExtraBabyOccupancy,
    petsAllowed: petsAllowed,
    onToggle: onClose,
    open: open,
    width: 1,
    dropDownBorderRadius: 0,
    variant: variant
  });
};
var _default = exports.default = TripGroupInputField;