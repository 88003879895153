/* eslint-disable */
// @ts-nocheck
import { disableFragmentWarnings } from '@apollo/client';
disableFragmentWarnings();
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Array: any;
    DateTime: any;
    Json: any;
    Number: any;
    QueryArgument: any;
};

/**
 *   An Accommodation is a representation of a location which can contain one or more RentalUnits.
 *  For example: a hotel would be represented by an Accommodation with each room type represented by a separate RentalUnit.
 */
export type Accommodation = {
    __typename?: 'Accommodation';
    /**   The address where this Accommodation is located. */
    address: Address;
    /**   The attributes assigned to this Accommodation, organized into categories. */
    attributeCategories: Array<AttributeCategory>;
    /**   Indicates the Travelbase brand this Accommodation belongs to (used on aggregate sites). */
    brand: Scalars['String'];
    /**
     *   The time at which check-in closes.
     *  Formatted as HH:MM.
     */
    checkInEndTime: Scalars['String'];
    /**
     *   The time at which check-in opens.
     *  Formatted as HH:MM.
     */
    checkInStartTime: Scalars['String'];
    /**
     *   The time before which guests have to check out.
     *  Formatted as HH:MM.
     */
    checkOutTime: Scalars['String'];
    collapseInSearch: Scalars['Boolean'];
    /**   The GPS coordinates of this Accommodation. */
    coordinates: LatLon;
    /**
     *   A general description of the Accommodation.
     *  This value is language dependent.
     */
    descriptionGeneral?: Maybe<Scalars['String']>;
    /**
     *   A description of the location this Accommodation is in.
     *  This value is language dependent.
     */
    descriptionLocation: Scalars['String'];
    /**   Indicates whether this Accommodation should have it's own page on the website, otherwise only the pages of the underlying RentalUnits exist. */
    hasPublicPage: Scalars['Boolean'];
    /**   Unique ID representing this Accommodation. */
    id: Scalars['ID'];
    /**   Image gallery for the Accommodation. */
    images: Array<AccommodationImage>;
    /**   The main (hero) image for the Accommodation. */
    mainImage?: Maybe<GenericImage>;
    /**   The highest max occupancy of all the rental units within the accommodation */
    maxOccupancy?: Maybe<Scalars['Int']>;
    /**   The name of the Accommodation. */
    name: Scalars['String'];
    /**   An avatar image for the Accommodation's owner. */
    ownerImage?: Maybe<GenericImage>;
    /**
     *   A short descriptive text about the Accommodation's owner.
     *  This value is language dependent.
     */
    ownerIntroduction?: Maybe<Scalars['String']>;
    /**   The name of the Accommodation's owner. */
    ownerName?: Maybe<Scalars['String']>;
    /**
     *   A text containing tips for visitors from the Accommodation's owner.
     *  This value is language dependent.
     */
    ownerTips?: Maybe<Scalars['String']>;
    /**   The name of the Place this Accommodation is located in. */
    place: Scalars['String'];
    /**   Distances to POI's. */
    pointsOfInterest: Array<PointOfInterest>;
    /**   Public email address for reservations. */
    publicReservationsEmail?: Maybe<Scalars['String']>;
    /**   Public phone number for reservations. */
    publicReservationsPhoneNumber?: Maybe<Scalars['String']>;
    /**   Public URL for reservations. */
    publicReservationsWebsiteUrl?: Maybe<Scalars['String']>;
    /**   List the RentalUnits within this Accommodation. */
    rentalUnits: Array<RentalUnit>;
    /**   Search the RentalUnits within this Accommodation. */
    searchRentalUnits: UnitSearchResult;
    /**   Unique slug to represent this Accommodation, most commonly in URLs. */
    slug: Scalars['String'];
    /**   The type of this Accommodation. */
    type: AccommodationTypeEnum;
};

/**
 *   An Accommodation is a representation of a location which can contain one or more RentalUnits.
 *  For example: a hotel would be represented by an Accommodation with each room type represented by a separate RentalUnit.
 */
export type AccommodationSearchRentalUnitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    params?: InputMaybe<UnitSearchParamsInput>;
};

/**   An image file available through a CDN URL. */
export type AccommodationImage = Image & {
    __typename?: 'AccommodationImage';
    /**   The category assigned to this Image. */
    category: AccommodationImageCategoryEnum;
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type AccommodationImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   Categories for AccommodationImages, describing what the image displays. */
export enum AccommodationImageCategoryEnum {
    Bathroom = 'BATHROOM',
    Bedroom = 'BEDROOM',
    Entrance = 'ENTRANCE',
    Facilities = 'FACILITIES',
    FloorPlan = 'FLOOR_PLAN',
    Front = 'FRONT',
    Kitchen = 'KITCHEN',
    LivingRoom = 'LIVING_ROOM',
    Other = 'OTHER',
    Outdoor = 'OUTDOOR',
    Surroundings = 'SURROUNDINGS',
    View = 'VIEW',
}

/**   Types of Accommodation. */
export enum AccommodationTypeEnum {
    ApartmentBlock = 'APARTMENT_BLOCK',
    BedAndBreakfast = 'BED_AND_BREAKFAST',
    BungalowPark = 'BUNGALOW_PARK',
    Camping = 'CAMPING',
    Hotel = 'HOTEL',
    Location = 'LOCATION',
}

export type Activity = {
    __typename?: 'Activity';
    bookingType: ActivityBookingTypesEnum;
    company?: Maybe<Company>;
    coordinates?: Maybe<LatLon>;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionLocation?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionParticulars?: Maybe<Scalars['String']>;
    externalBookingUrl?: Maybe<Scalars['String']>;
    externalInfoUrl?: Maybe<Scalars['String']>;
    highlighted: Scalars['Boolean'];
    id: Scalars['ID'];
    images: Array<ActivityImage>;
    listImage?: Maybe<GenericImage>;
    /**   The address where this Activity is located. */
    locationAddress?: Maybe<Address>;
    mainImage?: Maybe<GenericImage>;
    maxCapacity?: Maybe<Scalars['Int']>;
    minCapacity?: Maybe<Scalars['Int']>;
    /**   This value is language dependent. */
    name: Scalars['String'];
    place?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
    targetAudiences: Array<TargetAudienceEnum>;
    themes: Array<ActivityTheme>;
    /** @deprecated Now handled by bookingType of Agreement */
    ticketSalesEnabled: Scalars['Boolean'];
    timeslots: Array<Timeslot>;
    videoUrl?: Maybe<Scalars['String']>;
};

export type ActivityTimeslotsArgs = {
    endDate: Scalars['String'];
    startDate: Scalars['String'];
};

/**   Activity booking types */
export enum ActivityBookingTypesEnum {
    /**  Activity is directly bookable */
    Direct = 'DIRECT',
    /**  Activity is externally bookable */
    External = 'EXTERNAL',
    /**  activity is not bookable */
    NotBookable = 'NOT_BOOKABLE',
}

/**   An image file available through a CDN URL. */
export type ActivityImage = Image & {
    __typename?: 'ActivityImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type ActivityImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

export type ActivityRateGroup = {
    __typename?: 'ActivityRateGroup';
    /** @deprecated Now handled by bookingType of Agreement */
    canBuyTickets: Scalars['Boolean'];
    rates: Array<Rate>;
    releaseHours?: Maybe<Scalars['Int']>;
};

/**   Activity search result ordering options. */
export enum ActivitySearchOrderEnum {
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    Random = 'RANDOM',
    /**  Order by first available Timeslot. */
    TimeslotsAsc = 'TIMESLOTS_ASC',
}

export type ActivitySearchParamsInput = {
    companyId?: InputMaybe<Scalars['ID']>;
    /**   Formatted as yyyy-mm-dd, inclusive */
    endDate?: InputMaybe<Scalars['String']>;
    highlighted?: InputMaybe<Scalars['Boolean']>;
    placeId?: InputMaybe<Scalars['ID']>;
    /**   Formatted as yyyy-mm-dd */
    startDate?: InputMaybe<Scalars['String']>;
    targetAudiences?: InputMaybe<Array<TargetAudienceEnum>>;
    themeSlugs?: InputMaybe<Array<Scalars['String']>>;
};

export type ActivitySearchResult = {
    __typename?: 'ActivitySearchResult';
    hits: Array<Activity>;
    placeCounts: Array<GenericAggregationCount>;
    targetAudienceCount: Array<TargetAudienceAggregationCount>;
    themeCounts: Array<GenericAggregationCount>;
    totalHits: Scalars['Int'];
};

export type ActivityTheme = {
    __typename?: 'ActivityTheme';
    id: Scalars['ID'];
    /**   This value is language dependent. */
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

export type AddFavoriteActivityInput = {
    activityId: Scalars['ID'];
};

export type AddFavoriteActivityPayload = {
    __typename?: 'AddFavoriteActivityPayload';
    favorite?: Maybe<FavoriteActivity>;
};

export type AddFavoriteCompanyInput = {
    companyId: Scalars['ID'];
};

export type AddFavoriteCompanyPayload = {
    __typename?: 'AddFavoriteCompanyPayload';
    favorite?: Maybe<FavoriteCompany>;
};

export type AddFavoriteRentalUnitInput = {
    rentalUnitId: Scalars['ID'];
};

export type AddFavoriteRentalUnitPayload = {
    __typename?: 'AddFavoriteRentalUnitPayload';
    favorite?: Maybe<FavoriteRentalUnit>;
};

export type Address = AddressInterface &
    ElementInterface & {
        __typename?: 'Address';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** First line of the address */
        addressLine1?: Maybe<Scalars['String']>;
        /** Second line of the address */
        addressLine2?: Maybe<Scalars['String']>;
        /** Third line of the address */
        addressLine3?: Maybe<Scalars['String']>;
        /** Administrative area. */
        administrativeArea?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        city?: Maybe<Scalars['String']>;
        /** Two-letter country code */
        countryCode: Scalars['String'];
        countryISOCode?: Maybe<Scalars['String']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Dependent locality */
        dependentLocality?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The first name on the address. */
        firstName?: Maybe<Scalars['String']>;
        /** The full name on the address. */
        fullName?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The last name on the address. */
        lastName?: Maybe<Scalars['String']>;
        /** Latitude */
        latitude?: Maybe<Scalars['String']>;
        /** Locality */
        locality?: Maybe<Scalars['String']>;
        /** Longitude */
        longitude?: Maybe<Scalars['String']>;
        number?: Maybe<Scalars['String']>;
        /** Organization name */
        organization?: Maybe<Scalars['String']>;
        /** Organization tax ID */
        organizationTaxId?: Maybe<Scalars['String']>;
        postalCode?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** Sorting code */
        sortingCode?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        street?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Address_CountArgs = {
    field: Scalars['String'];
};

export type AddressInput = {
    city?: InputMaybe<Scalars['String']>;
    countryCode?: InputMaybe<Scalars['String']>;
    number?: InputMaybe<Scalars['String']>;
    postalCode?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
};

/** This is the interface implemented by all addresses. */
export type AddressInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** First line of the address */
    addressLine1?: Maybe<Scalars['String']>;
    /** Second line of the address */
    addressLine2?: Maybe<Scalars['String']>;
    /** Third line of the address */
    addressLine3?: Maybe<Scalars['String']>;
    /** Administrative area. */
    administrativeArea?: Maybe<Scalars['String']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Two-letter country code */
    countryCode: Scalars['String'];
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Dependent locality */
    dependentLocality?: Maybe<Scalars['String']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The first name on the address. */
    firstName?: Maybe<Scalars['String']>;
    /** The full name on the address. */
    fullName?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The last name on the address. */
    lastName?: Maybe<Scalars['String']>;
    /** Latitude */
    latitude?: Maybe<Scalars['String']>;
    /** Locality */
    locality?: Maybe<Scalars['String']>;
    /** Longitude */
    longitude?: Maybe<Scalars['String']>;
    /** Organization name */
    organization?: Maybe<Scalars['String']>;
    /** Organization tax ID */
    organizationTaxId?: Maybe<Scalars['String']>;
    /** Postal code */
    postalCode?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** Sorting code */
    sortingCode?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all addresses. */
export type AddressInterface_CountArgs = {
    field: Scalars['String'];
};

export type AddressType = {
    __typename?: 'AddressType';
    /** The address1 value of the address. */
    address1?: Maybe<Scalars['String']>;
    /** The address2 value of the address. */
    address2?: Maybe<Scalars['String']>;
    /** The address3 value of the address. */
    address3?: Maybe<Scalars['String']>;
    /** The city value of the address. */
    city?: Maybe<Scalars['String']>;
    /** The country value of the address. */
    country?: Maybe<Scalars['String']>;
    /** The full address value. */
    fullAddress?: Maybe<Scalars['String']>;
    /** The state value of the address. */
    state?: Maybe<Scalars['String']>;
    /** The zip value of the address. */
    zip?: Maybe<Scalars['String']>;
};

export type Affiliate = {
    __typename?: 'Affiliate';
    handle: Scalars['String'];
    name: Scalars['String'];
};

export type AffiliateInfoInput = {
    handle: Scalars['String'];
    reference?: InputMaybe<Scalars['String']>;
};

export type AlterBookingInput = {
    bookingId: Scalars['ID'];
    /**
     *   Submitting `null` will leave the surcharges unchanged, submitting an empty array indicates none of the available optional surcharges are desired.
     *  Submitting something other than `null` will result in an error if `Booking.isSurchargesAlterable` is `false`.
     */
    optionalSurcharges?: InputMaybe<Array<BookingCartItemSurchargeInput>>;
    /**   Submitting something other than `null` will result in an error if `Booking.isTravelGroupAlterable` is `false`. */
    travelGroup?: InputMaybe<TravelGroupInput>;
};

export type AlterBookingPayload = {
    __typename?: 'AlterBookingPayload';
    booking?: Maybe<Booking>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Alternative text for the asset. */
    alt?: Maybe<Scalars['String']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the asset file was last modified. */
    dateModified?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The file extension for the asset file. */
    extension: Scalars['String'];
    /** The filename of the asset file. */
    filename: Scalars['String'];
    /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
    focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
    /** The ID of the folder that the asset belongs to. */
    folderId: Scalars['Int'];
    /** Returns the file’s format. */
    format?: Maybe<Scalars['String']>;
    /** Whether a user-defined focal point is set on the asset. */
    hasFocalPoint: Scalars['Boolean'];
    /** The height in pixels or null if it’s not an image. */
    height?: Maybe<Scalars['Int']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** An `<img>` tag based on this asset. */
    img?: Maybe<Scalars['String']>;
    /** The file kind. */
    kind: Scalars['String'];
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The file’s MIME type, if it can be determined. */
    mimeType?: Maybe<Scalars['String']>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<AssetInterface>;
    /** The asset’s path in the volume. */
    path: Scalars['String'];
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<AssetInterface>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The file size in bytes. */
    size?: Maybe<Scalars['String']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
    srcset?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
    url?: Maybe<Scalars['String']>;
    /** The ID of the volume that the asset belongs to. */
    volumeId?: Maybe<Scalars['Int']>;
    /** The width in pixels or null if it’s not an image. */
    width?: Maybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceFormatArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceHeightArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceNextArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfacePrevArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceSrcsetArgs = {
    sizes: Array<Scalars['String']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceWidthArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type AssetRelationCriteriaInput = {
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ files’ last-modified dates. */
    dateModified?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ filenames. */
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the assets have alternative text. */
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ image heights. */
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ file kinds. */
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in, based on their language. */
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to elements that do not relate to the provided element IDs. */
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    /** Narrows the relations based on the field they were created in. */
    relatedViaField?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the relations based on the site they were created in. */
    relatedViaSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ file sizes (in bytes). */
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ image widths. */
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** A list of transform handles to preload. */
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**   An attribute which is assigned to either an Accommodation or a RentalUnit. */
export type Attribute = {
    __typename?: 'Attribute';
    /**   A URL-friendly handle unique to this Attribute, this should never change even if the description changes. */
    handle: Scalars['String'];
    /**   URL of an SVG icon representing this Attribute. */
    iconUrl?: Maybe<Scalars['String']>;
    /**   Unique ID representing this Attribute. */
    id: Scalars['ID'];
    /**
     *   The name of this Attribute.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

/**   A category for Attributes. */
export type AttributeCategory = {
    __typename?: 'AttributeCategory';
    /**   The Attributes in this category. */
    attributes: Array<Attribute>;
    /**   A URL-friendly handle unique to this AttributeCategory, this should never change even if the description changes. */
    handle: Scalars['String'];
    /**   Unique ID representing this AttributeCategory. */
    id: Scalars['ID'];
    /**
     *   The name of this AttributeCategory.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type Booking = OrderItem & {
    __typename?: 'Booking';
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
    /**   Formatted as yyyy-mm-dd */
    arrivalDate: Scalars['String'];
    customerComment?: Maybe<Scalars['String']>;
    /**   Formatted as yyyy-mm-dd */
    departureDate: Scalars['String'];
    deposit: Scalars['Float'];
    downloadVoucherUrl?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    /**   Formatted as yyyy-mm-dd HH:mm */
    flexCancellationUntil?: Maybe<Scalars['String']>;
    handleDepositPayment: Scalars['Boolean'];
    hasCancellationInsurance: Scalars['Boolean'];
    hasContentsInsurance: Scalars['Boolean'];
    id?: Maybe<Scalars['ID']>;
    /**   If true, some part of the Booking can be altered by the Customer (via the `alterBooking` Mutation). Use the `is...Alterable` fields to determine which parts specifically can be altered. */
    isAlterable: Scalars['Boolean'];
    isCancellable: Scalars['Boolean'];
    /**   If true, the optional surcharges for this Booking can be altered by the Customer (via the `alterBooking` Mutation). */
    isSurchargesAlterable: Scalars['Boolean'];
    /**   If true, the travel group for this Booking can be altered by the Customer (via the `alterBooking` Mutation). */
    isTravelGroupAlterable: Scalars['Boolean'];
    optionalSurcharges: Array<BookingCartItemSurcharge>;
    partnerComment?: Maybe<Scalars['String']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    rentalUnit?: Maybe<RentalUnit>;
    requiresApproval: Scalars['Boolean'];
    review?: Maybe<Review>;
    special?: Maybe<Special>;
    status: BookingStatus;
    totalPrice: Scalars['Float'];
};

export type BookingCartItemInput = {
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
    /**   Formatted as yyyy-mm-dd */
    arrivalDate: Scalars['String'];
    cancellationInsurance: Scalars['Boolean'];
    contentsInsurance: Scalars['Boolean'];
    /**   Formatted as yyyy-mm-dd */
    departureDate: Scalars['String'];
    optionalSurcharges: Array<BookingCartItemSurchargeInput>;
    rentalUnitId: Scalars['ID'];
    specialId?: InputMaybe<Scalars['ID']>;
};

export type BookingCartItemSurcharge = {
    __typename?: 'BookingCartItemSurcharge';
    amount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
};

export type BookingCartItemSurchargeInput = {
    amount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
};

export type BookingChangePreview = {
    __typename?: 'BookingChangePreview';
    bookingPreview: Booking;
    newOrderAmountDue: Scalars['Float'];
};

export enum BookingStatus {
    Accepted = 'ACCEPTED',
    CancelledExternal = 'CANCELLED_EXTERNAL',
    CancelledGuest = 'CANCELLED_GUEST',
    CancelledPartner = 'CANCELLED_PARTNER',
    Declined = 'DECLINED',
    Init = 'INIT',
    NoShow = 'NO_SHOW',
    Pending = 'PENDING',
    Waived = 'WAIVED',
}

/**   A bounding box of GPS coordinates. */
export type BoundingBoxInput = {
    bottom: Scalars['Float'];
    left: Scalars['Float'];
    right: Scalars['Float'];
    top: Scalars['Float'];
};

export type Bucket = {
    __typename?: 'Bucket';
    count?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
};

export type CancelBookingInput = {
    bookingId: Scalars['ID'];
};

export type CancelBookingPayload = {
    __typename?: 'CancelBookingPayload';
    booking?: Maybe<Booking>;
};

export type Cart = {
    __typename?: 'Cart';
    availableUpsellOffers: Array<UpsellOffer>;
    id: Scalars['ID'];
    order: Order;
    status: CartStatusEnum;
};

export enum CartStatusEnum {
    Closed = 'CLOSED',
    Open = 'OPEN',
    PaymentFailed = 'PAYMENT_FAILED',
    PaymentPending = 'PAYMENT_PENDING',
}

/** This is the interface implemented by all categories. */
export type CategoryInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** The category’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<CategoryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The category’s children. */
    children: Array<CategoryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** The category’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<CategoryInterface>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The handle of the group that contains the category. */
    groupHandle: Scalars['String'];
    /** The ID of the group that contains the category. */
    groupId: Scalars['Int'];
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']>;
    /** The same element in other locales. */
    localized: Array<CategoryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<CategoryInterface>;
    /** The category’s parent. */
    parent?: Maybe<CategoryInterface>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<CategoryInterface>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all categories. */
export type CategoryInterfaceAncestorsArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterfaceChildrenArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterfaceDescendantsArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterfaceLocalizedArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterfaceNextArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterfaceParentArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterfacePrevArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryRelationCriteriaInput = {
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    /** Whether to only return categories that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Determines which site(s) the elements should be queried in, based on their language. */
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to elements that do not relate to the provided element IDs. */
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    /** Narrows the relations based on the field they were created in. */
    relatedViaField?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the relations based on the site they were created in. */
    relatedViaSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type CheckoutCartInput = {
    affiliateInfo?: InputMaybe<AffiliateInfoInput>;
    agreedToTerms: Scalars['Boolean'];
    bookings?: InputMaybe<Array<BookingCartItemInput>>;
    cartId: Scalars['ID'];
    customerInfo?: InputMaybe<CustomerInfoInput>;
    paymentOptions: PaymentOptionsInput;
    tickets?: InputMaybe<Array<TicketCartItemInput>>;
    upsellPurchases?: InputMaybe<Array<UpsellPurchaseCartItemInput>>;
};

export type CheckoutCartPayload = {
    __typename?: 'CheckoutCartPayload';
    cart?: Maybe<Cart>;
    redirectUrl?: Maybe<Scalars['String']>;
    success?: Maybe<Scalars['Boolean']>;
};

export type Company = {
    __typename?: 'Company';
    activities: Array<Activity>;
    address: Address;
    categories: Array<CompanyCategory>;
    companyRateGroups: Array<CompanyRateGroup>;
    coordinates?: Maybe<LatLon>;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionParticulars?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    images: Array<CompanyImage>;
    listImage?: Maybe<GenericImage>;
    logoImage?: Maybe<GenericImage>;
    mainImage?: Maybe<GenericImage>;
    name: Scalars['String'];
    normalOperatingHours?: Maybe<NormalOperatingHours>;
    phoneNumber?: Maybe<Scalars['String']>;
    place: Scalars['String'];
    showInCalendar: Scalars['Boolean'];
    slug: Scalars['String'];
    socialsFacebookUrl?: Maybe<Scalars['String']>;
    socialsInstagramUrl?: Maybe<Scalars['String']>;
    socialsTwitterUrl?: Maybe<Scalars['String']>;
    specialOperatingHours: Array<SpecialOperatingHours>;
    targetAudiences: Array<TargetAudienceEnum>;
    themes: Array<ActivityTheme>;
    videoUrl?: Maybe<Scalars['String']>;
    websiteUrl?: Maybe<Scalars['String']>;
};

export type CompanyCategory = {
    __typename?: 'CompanyCategory';
    children: Array<CompanyCategory>;
    id: Scalars['ID'];
    isRoot: Scalars['Boolean'];
    /**   This value is language dependent. */
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type CompanyImage = Image & {
    __typename?: 'CompanyImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type CompanyImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

export type CompanyRateGroup = {
    __typename?: 'CompanyRateGroup';
    canBuyTickets: Scalars['Boolean'];
    endDate?: Maybe<Scalars['String']>;
    rates: Array<Rate>;
    startDate?: Maybe<Scalars['String']>;
};

export type CompanySearchParamsInput = {
    categorySlugs?: InputMaybe<Array<Scalars['String']>>;
    /**   Formatted as yyyy-mm-dd, inclusive */
    endDate?: InputMaybe<Scalars['String']>;
    mainCategorySlugs?: InputMaybe<Array<Scalars['String']>>;
    placeId?: InputMaybe<Scalars['ID']>;
    showInCalendar?: InputMaybe<Scalars['Boolean']>;
    /**   Formatted as yyyy-mm-dd */
    startDate?: InputMaybe<Scalars['String']>;
    targetAudiences?: InputMaybe<Array<TargetAudienceEnum>>;
    themeSlugs?: InputMaybe<Array<Scalars['String']>>;
};

export type CompanySearchResult = {
    __typename?: 'CompanySearchResult';
    categoryCounts: Array<GenericAggregationCount>;
    hits: Array<Company>;
    mainCategoryCounts: Array<GenericAggregationCount>;
    placeCounts: Array<GenericAggregationCount>;
    targetAudienceCount: Array<TargetAudienceAggregationCount>;
    themeCounts: Array<GenericAggregationCount>;
    totalHits: Scalars['Int'];
};

export type ContactInfo = {
    __typename?: 'ContactInfo';
    contactName?: Maybe<Scalars['String']>;
    emails: Array<Scalars['String']>;
    phoneNumbers: Array<Scalars['String']>;
};

export type Customer = {
    __typename?: 'Customer';
    address?: Maybe<Address>;
    agreedToTermsAt: Scalars['String'];
    birthdate?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    favorites: Array<Favorite>;
    firstName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lastName?: Maybe<Scalars['String']>;
    locale: Scalars['String'];
    orders: Array<Order>;
    phoneNumber?: Maybe<Scalars['String']>;
};

export type CustomerInfoInput = {
    address?: InputMaybe<AddressInput>;
    birthdate?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    newsletterOptin: Scalars['Boolean'];
    phoneNumber?: InputMaybe<Scalars['String']>;
};

export type DateCount = {
    __typename?: 'DateCount';
    /**   Formatted as yyyy-mm-dd */
    date: Scalars['String'];
    hits: Scalars['Int'];
};

export type DateHitCount = {
    __typename?: 'DateHitCount';
    /**   Formatted as yyyy-mm-dd */
    date: Scalars['String'];
    hits: Scalars['Int'];
};

export type Element = ElementInterface & {
    __typename?: 'Element';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

export type Element_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all elements. */
export type ElementInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all entries. */
export type EntryInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors: Array<EntryInterface>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children: Array<EntryInterface>;
    /** The current revision for the entry. */
    currentRevision?: Maybe<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants: Array<EntryInterface>;
    /** The creator of a given draft. */
    draftCreator?: Maybe<UserInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']>;
    /** The drafts for the entry. */
    drafts?: Maybe<Array<Maybe<EntryInterface>>>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** Whether the element is enabled for the site. */
    enabledForSite?: Maybe<Scalars['Boolean']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']>;
    /** The handle of the field that contains the entry. */
    fieldHandle?: Maybe<Scalars['String']>;
    /** The ID of the field that contains the entry. */
    fieldId?: Maybe<Scalars['Int']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']>;
    /** The same element in other locales. */
    localized: Array<EntryInterface>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The ID of the entry’s owner elementt. */
    ownerId?: Maybe<Scalars['Int']>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The creator of a given revision. */
    revisionCreator?: Maybe<UserInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']>;
    /** The revisions for the entry. */
    revisions?: Maybe<Array<Maybe<EntryInterface>>>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the section that contains the entry. */
    sectionHandle?: Maybe<Scalars['String']>;
    /** The ID of the section that contains the entry. */
    sectionId?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The entry’s position within the field that contains it. */
    sortOrder?: Maybe<Scalars['Int']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle: Scalars['String'];
    /** The ID of the entry type that contains the entry. */
    typeId: Scalars['Int'];
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfacePrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type EntryRelationCriteriaInput = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that were posted on or after a certain date. */
    after?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user group the entries’ authors belong to. */
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the entries’ authors. */
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that were posted before a certain date. */
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** The drafts’ creator ID */
    draftCreator?: InputMaybe<Scalars['Int']>;
    /** The ID of the draft to return (from the `drafts` table) */
    draftId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only drafts of a given element.  Set to `false` to fetch unpublished drafts. */
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    /** Whether draft elements should be returned. */
    drafts?: InputMaybe<Scalars['Boolean']>;
    /** Whether to only return entries that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the entries’ expiry dates. */
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the field the entries are contained by. */
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the field the entries are contained by, per the fields’ IDs. */
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Determines which site(s) the elements should be queried in, based on their language. */
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to elements that do not relate to the provided element IDs. */
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the owner element of the entries, per the owners’ IDs. */
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the entries’ post dates. */
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the primary owner element of the entries, per the owners’ IDs. */
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Whether provisional drafts should be returned. */
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    /** Narrows the relations based on the field they were created in. */
    relatedViaField?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the relations based on the site they were created in. */
    relatedViaSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** The revisions’ creator ID */
    revisionCreator?: InputMaybe<Scalars['Int']>;
    /** The ID of the revision to return (from the `revisions` table) */
    revisionId?: InputMaybe<Scalars['Int']>;
    /** The source element ID that revisions should be returned for */
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    /** Whether revision elements should be returned. */
    revisions?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the section handles the entries belong to. */
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the entries’ entry type handles. */
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Ether_Map = {
    __typename?: 'Ether_Map';
    /** The full address. */
    address?: Maybe<Scalars['String']>;
    /** The distance to this location. */
    distance?: Maybe<Scalars['Float']>;
    /** The maps latitude. */
    lat?: Maybe<Scalars['Float']>;
    /** The maps longitude. */
    lng?: Maybe<Scalars['Float']>;
    /** The maps address parts. */
    parts?: Maybe<Ether_MapParts>;
    /** The maps zoom level. */
    zoom?: Maybe<Scalars['Int']>;
};

export type Ether_MapCoords = {
    lat: Scalars['Float'];
    lng: Scalars['Float'];
};

export type Ether_MapParts = {
    __typename?: 'Ether_MapParts';
    /** The street address. */
    address?: Maybe<Scalars['String']>;
    /** The city. */
    city?: Maybe<Scalars['String']>;
    /** The country. */
    country?: Maybe<Scalars['String']>;
    /** The county. */
    county?: Maybe<Scalars['String']>;
    /** The address name / number. */
    number?: Maybe<Scalars['String']>;
    /** The postal code. */
    postcode?: Maybe<Scalars['String']>;
    /** The state. */
    state?: Maybe<Scalars['String']>;
};

export type Ether_MapQuery = {
    coordinate?: InputMaybe<Ether_MapCoords>;
    country?: InputMaybe<Scalars['String']>;
    location?: InputMaybe<Scalars['String']>;
    radius?: InputMaybe<Scalars['Float']>;
    unit?: InputMaybe<Ether_MapUnit>;
};

export enum Ether_MapUnit {
    Kilometres = 'Kilometres',
    Miles = 'Miles',
}

export type Ether_SeoKeyword = {
    __typename?: 'Ether_SEOKeyword';
    keyword?: Maybe<Scalars['String']>;
    rating?: Maybe<Scalars['String']>;
};

/** Robots and canonical data */
export type Ether_SeoAdvanced = {
    __typename?: 'Ether_SeoAdvanced';
    canonical?: Maybe<Scalars['String']>;
    robots?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Ether_SeoData = {
    __typename?: 'Ether_SeoData';
    advanced?: Maybe<Ether_SeoAdvanced>;
    description?: Maybe<Scalars['String']>;
    keywords?: Maybe<Array<Maybe<Ether_SeoKeyword>>>;
    social?: Maybe<Ether_SeoSocialNetworks>;
    title?: Maybe<Scalars['String']>;
};

/** Social data for an individual Social network */
export type Ether_SeoSocialData = {
    __typename?: 'Ether_SeoSocialData';
    description?: Maybe<Scalars['String']>;
    image?: Maybe<AssetInterface>;
    title?: Maybe<Scalars['String']>;
};

export type Ether_SeoSocialNetworks = {
    __typename?: 'Ether_SeoSocialNetworks';
    facebook?: Maybe<Ether_SeoSocialData>;
    twitter?: Maybe<Ether_SeoSocialData>;
};

export type Favorite = {
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteActivity = Favorite & {
    __typename?: 'FavoriteActivity';
    activity?: Maybe<Activity>;
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteCompany = Favorite & {
    __typename?: 'FavoriteCompany';
    company?: Maybe<Company>;
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteRentalUnit = Favorite & {
    __typename?: 'FavoriteRentalUnit';
    id: Scalars['ID'];
    rentalUnit?: Maybe<RentalUnit>;
    targetId: Scalars['ID'];
};

export type FieldAttribute = {
    __typename?: 'FieldAttribute';
    label?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all fields. */
export type FieldInterface = {
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type FieldOption = {
    __typename?: 'FieldOption';
    /** Whether this option has been marked as disabled. */
    disabled?: Maybe<Scalars['Boolean']>;
    /** Whether this option has been marked as a default. */
    isDefault?: Maybe<Scalars['Boolean']>;
    /** Whether this option has been marked as an `optgroup`. */
    isOptgroup?: Maybe<Scalars['Boolean']>;
    /** The label of the option. */
    label?: Maybe<Scalars['String']>;
    /** The value of the option. */
    value?: Maybe<Scalars['String']>;
};

export type Field_Address = FieldInterface & {
    __typename?: 'Field_Address';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    subFieldLabelPosition?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_AddressFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_AddressNestedRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_Address1 = FieldInterface & {
    __typename?: 'Field_Address1';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Address2 = FieldInterface & {
    __typename?: 'Field_Address2';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Address3 = FieldInterface & {
    __typename?: 'Field_Address3';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_AddressAutoComplete = FieldInterface & {
    __typename?: 'Field_AddressAutoComplete';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_AddressCity = FieldInterface & {
    __typename?: 'Field_AddressCity';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_AddressCountry = FieldInterface & {
    __typename?: 'Field_AddressCountry';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_AddressState = FieldInterface & {
    __typename?: 'Field_AddressState';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_AddressZip = FieldInterface & {
    __typename?: 'Field_AddressZip';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Agree = FieldInterface & {
    __typename?: 'Field_Agree';
    checkedValue?: Maybe<Scalars['String']>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultState?: Maybe<Scalars['Boolean']>;
    defaultValue?: Maybe<Scalars['String']>;
    descriptionHtml?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uncheckedValue?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Calculations = FieldInterface & {
    __typename?: 'Field_Calculations';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    decimals?: Maybe<Scalars['Int']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    formatting?: Maybe<Scalars['String']>;
    formula?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    prefix?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    suffix?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Categories = FieldInterface & {
    __typename?: 'Field_Categories';
    categories?: Maybe<Array<Maybe<CategoryInterface>>>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultCategory?: Maybe<CategoryInterface>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limitOptions?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    orderBy?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    rootCategory?: Maybe<CategoryInterface>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_CategoriesCategoriesArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Field_CategoriesDefaultCategoryArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Field_CategoriesRootCategoryArgs = {
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Field_Checkboxes = FieldInterface & {
    __typename?: 'Field_Checkboxes';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Date = FieldInterface & {
    __typename?: 'Field_Date';
    availableDaysOfWeek?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    datePickerOptions?: Maybe<Array<Maybe<FieldAttribute>>>;
    defaultDate?: Maybe<Scalars['DateTime']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    maxDate?: Maybe<Scalars['DateTime']>;
    minDate?: Maybe<Scalars['DateTime']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    subFieldLabelPosition?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_DateNestedRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_DateAmPmDropdown = FieldInterface & {
    __typename?: 'Field_DateAmPmDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateAmPmNumber = FieldInterface & {
    __typename?: 'Field_DateAmPmNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateDayDropdown = FieldInterface & {
    __typename?: 'Field_DateDayDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateDayNumber = FieldInterface & {
    __typename?: 'Field_DateDayNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateHourDropdown = FieldInterface & {
    __typename?: 'Field_DateHourDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateHourNumber = FieldInterface & {
    __typename?: 'Field_DateHourNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateMinuteDropdown = FieldInterface & {
    __typename?: 'Field_DateMinuteDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateMinuteNumber = FieldInterface & {
    __typename?: 'Field_DateMinuteNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateMonthDropdown = FieldInterface & {
    __typename?: 'Field_DateMonthDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateMonthNumber = FieldInterface & {
    __typename?: 'Field_DateMonthNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateSecondDropdown = FieldInterface & {
    __typename?: 'Field_DateSecondDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateSecondNumber = FieldInterface & {
    __typename?: 'Field_DateSecondNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateYearDropdown = FieldInterface & {
    __typename?: 'Field_DateYearDropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_DateYearNumber = FieldInterface & {
    __typename?: 'Field_DateYearNumber';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Dropdown = FieldInterface & {
    __typename?: 'Field_Dropdown';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Email = FieldInterface & {
    __typename?: 'Field_Email';
    blockedDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Entries = FieldInterface & {
    __typename?: 'Field_Entries';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultEntry?: Maybe<EntryInterface>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    entries?: Maybe<Array<Maybe<EntryInterface>>>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limitOptions?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    orderBy?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_EntriesDefaultEntryArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Field_EntriesEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Field_FileUpload = FieldInterface & {
    __typename?: 'Field_FileUpload';
    allowedKinds?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limitFiles?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    orderBy?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    sizeLimit?: Maybe<Scalars['String']>;
    sizeMinLimit?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
    volumeHandle?: Maybe<Scalars['String']>;
};

export type Field_Group = FieldInterface & {
    __typename?: 'Field_Group';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_GroupFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_GroupNestedRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_Heading = FieldInterface & {
    __typename?: 'Field_Heading';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    headingSize?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Hidden = FieldInterface & {
    __typename?: 'Field_Hidden';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cookieName?: Maybe<Scalars['String']>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultOption?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    queryParameter?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Html = FieldInterface & {
    __typename?: 'Field_Html';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    htmlContent?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_MultiLineText = FieldInterface & {
    __typename?: 'Field_MultiLineText';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    richTextButtons?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    useRichText?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Name = FieldInterface & {
    __typename?: 'Field_Name';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    subFieldLabelPosition?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    useMultipleFields?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_NameFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_NameNestedRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_NameFirst = FieldInterface & {
    __typename?: 'Field_NameFirst';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_NameLast = FieldInterface & {
    __typename?: 'Field_NameLast';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_NameMiddle = FieldInterface & {
    __typename?: 'Field_NameMiddle';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_NamePrefix = FieldInterface & {
    __typename?: 'Field_NamePrefix';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Newsletter = FieldInterface & {
    __typename?: 'Field_Newsletter';
    checkedValue?: Maybe<Scalars['String']>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultState?: Maybe<Scalars['Boolean']>;
    defaultValue?: Maybe<Scalars['String']>;
    descriptionHtml?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uncheckedValue?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Number = FieldInterface & {
    __typename?: 'Field_Number';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxValue?: Maybe<Scalars['Float']>;
    min?: Maybe<Scalars['Int']>;
    minValue?: Maybe<Scalars['Float']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Password = FieldInterface & {
    __typename?: 'Field_Password';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Payment = FieldInterface & {
    __typename?: 'Field_Payment';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    paymentIntegration?: Maybe<Scalars['String']>;
    paymentIntegrationType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Phone = FieldInterface & {
    __typename?: 'Field_Phone';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    countryAllowed?: Maybe<Scalars['String']>;
    countryDefaultValue?: Maybe<Scalars['String']>;
    countryEnabled?: Maybe<Scalars['Boolean']>;
    countryOptions?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Radio = FieldInterface & {
    __typename?: 'Field_Radio';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Recipients = FieldInterface & {
    __typename?: 'Field_Recipients';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multiple?: Maybe<Scalars['Boolean']>;
    options?: Maybe<Array<Maybe<FieldOption>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Repeater = FieldInterface & {
    __typename?: 'Field_Repeater';
    addLabel?: Maybe<Scalars['String']>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    maxRows?: Maybe<Scalars['Int']>;
    minRows?: Maybe<Scalars['Int']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_RepeaterFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_RepeaterNestedRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Field_Section = FieldInterface & {
    __typename?: 'Field_Section';
    borderColor?: Maybe<Scalars['String']>;
    borderStyle?: Maybe<Scalars['String']>;
    borderWidth?: Maybe<Scalars['Int']>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Signature = FieldInterface & {
    __typename?: 'Field_Signature';
    backgroundColor?: Maybe<Scalars['String']>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    penColor?: Maybe<Scalars['String']>;
    penWeight?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_SingleLineText = FieldInterface & {
    __typename?: 'Field_SingleLineText';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['Int']>;
    maxType?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['Int']>;
    minType?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uniqueValue?: Maybe<Scalars['Boolean']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Summary = FieldInterface & {
    __typename?: 'Field_Summary';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Table = FieldInterface & {
    __typename?: 'Field_Table';
    columns?: Maybe<Array<Maybe<KeyValueType>>>;
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s default value as a string. Some fields have different fields for their default value. */
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_Tags = FieldInterface & {
    __typename?: 'Field_Tags';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultTag?: Maybe<TagInterface>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limitOptions?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    orderBy?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    tags?: Maybe<Array<Maybe<TagInterface>>>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_TagsDefaultTagArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Field_TagsTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Field_Users = FieldInterface & {
    __typename?: 'Field_Users';
    /** The field’s conditions as a JSON string. */
    conditions?: Maybe<Scalars['Json']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    defaultUser?: Maybe<UserInterface>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name (last portion of the class). */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    /** The field’s value type to use in email notifications. */
    emailValue?: Maybe<Scalars['String']>;
    /** Whether the field has conditions enabled. */
    enableConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the field has content encryption enabled. */
    enableContentEncryption?: Maybe<Scalars['Boolean']>;
    /** Whether the field is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the field should be included in email content. */
    includeInEmail?: Maybe<Scalars['Boolean']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s full GQL input type. Useful for mutations. */
    inputTypeName?: Maybe<Scalars['String']>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label. */
    label?: Maybe<Scalars['String']>;
    /** The field’s label position. This will be a `verbb\formie\positions` class name. */
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limitOptions?: Maybe<Scalars['String']>;
    /** The field handle for another field that this value should match exactly. */
    matchField?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['Boolean']>;
    orderBy?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** The field’s pre-populated value extracted from the query string. */
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The field’s full GQL type. */
    typeName?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    users?: Maybe<Array<Maybe<UserInterface>>>;
    /** The field’s visibility. */
    visibility?: Maybe<Scalars['String']>;
};

export type Field_UsersDefaultUserArgs = {
    affiliatedSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    affiliatedSiteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    assetUploaders?: InputMaybe<Scalars['Boolean']>;
    authors?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    fullName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasPhoto?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    username?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Field_UsersUsersArgs = {
    affiliatedSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    affiliatedSiteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    assetUploaders?: InputMaybe<Scalars['Boolean']>;
    authors?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    fullName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasPhoto?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    username?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FormIntegrationsType = {
    __typename?: 'FormIntegrationsType';
    /** Whether the integration is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The integration’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The integration’s name. */
    name?: Maybe<Scalars['String']>;
    /** The integration’s settings as a JSON string. */
    settings?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** A list of captcha values (name and value) to assist with spam protection. */
    captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
    /** The form’s config as JSON. */
    configJson?: Maybe<Scalars['String']>;
    /** A CSRF token (name and value). */
    csrfToken?: Maybe<FormieCsrfTokenType>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The form’s fields. */
    formFields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The form’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether the form is considered available according to user checks, scheduling and more. */
    isAvailable?: Maybe<Scalars['Boolean']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The form’s pages. */
    pages?: Maybe<Array<Maybe<PageInterface>>>;
    /** The form’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The form’s settings. */
    settings?: Maybe<FormSettingsType>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The form’s endpoint for sending submissions to, if using POST requests. */
    submissionEndpoint?: Maybe<Scalars['String']>;
    /** The form’s GQL mutation name for submissions to use. */
    submissionMutationName?: Maybe<Scalars['String']>;
    /** The form’s CSS for rendering. */
    templateCss?: Maybe<Scalars['String']>;
    /** The form’s rendered HTML. */
    templateHtml?: Maybe<Scalars['String']>;
    /** The form’s JS for rendering and functionality. */
    templateJs?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all forms. */
export type FormInterfaceFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all forms. */
export type FormInterfaceRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all forms. */
export type FormInterfaceTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FormSettingsType = {
    __typename?: 'FormSettingsType';
    /** The form’s default instructions position for fields. This will be a `verbb\formie\positions` class name. */
    defaultInstructionsPosition?: Maybe<Scalars['String']>;
    /** The form’s default label position for fields. This will be a `verbb\formie\positions` class name. */
    defaultLabelPosition?: Maybe<Scalars['String']>;
    /** Whether to show the form’s current page title. */
    displayCurrentPageTitle?: Maybe<Scalars['Boolean']>;
    /** Whether to show the form’s title. */
    displayFormTitle?: Maybe<Scalars['Boolean']>;
    /** Whether to show the form’s page progress. */
    displayPageProgress?: Maybe<Scalars['Boolean']>;
    /** Whether to show the form’s page tabs. */
    displayPageTabs?: Maybe<Scalars['Boolean']>;
    /** The form’s submit error message. */
    errorMessageHtml?: Maybe<Scalars['String']>;
    /** The form’s error message position. Either `null`, `top-form` or `bottom-form`. */
    errorMessagePosition?: Maybe<Scalars['String']>;
    /** The form’s enabled integrations. */
    integrations?: Maybe<Array<Maybe<FormIntegrationsType>>>;
    /** The type of loading indicator to use. Either `spinner` or `text`. */
    loadingIndicator?: Maybe<Scalars['String']>;
    /** The form’s loading indicator text. */
    loadingIndicatorText?: Maybe<Scalars['String']>;
    /** The form’s progress bar position. Either `start` or `end`. */
    progressPosition?: Maybe<Scalars['String']>;
    /** The form’s submit action entry (for redirection), if `submitAction` is `entry`. */
    redirectEntry?: Maybe<EntryInterface>;
    /** The form’s submit action redirect URL, resolved depending on `submitAction` being `entry` or `url`. */
    redirectUrl?: Maybe<Scalars['String']>;
    /** The form’s required fields indicator. Either `asterisk` or `optional`. */
    requiredIndicator?: Maybe<Scalars['String']>;
    /** Whether to the form should scroll to the top of the page when submitted. */
    scrollToTop?: Maybe<Scalars['Boolean']>;
    /** The form’s submit action. Either `message`, `entry`, `url`, `reload`. */
    submitAction?: Maybe<Scalars['String']>;
    /** Whether to hide the form’s success message. */
    submitActionFormHide?: Maybe<Scalars['Boolean']>;
    /** The form’s submit success message. */
    submitActionMessageHtml?: Maybe<Scalars['String']>;
    /** The form’s submit message position. Either `top-form` or `bottom-form`. */
    submitActionMessagePosition?: Maybe<Scalars['String']>;
    /** The form’s submit success message timeout in seconds. */
    submitActionMessageTimeout?: Maybe<Scalars['Int']>;
    /** The form’s submit redirect option (if in new tab or same tab). Either `same-tab` or `new-tab`. */
    submitActionTab?: Maybe<Scalars['String']>;
    /** The form’s submit method. Either `page-reload` or `ajax`. */
    submitMethod?: Maybe<Scalars['String']>;
    /** Whether to validate the form’s on focus. */
    validationOnFocus?: Maybe<Scalars['Boolean']>;
    /** Whether to validate the form’s on submit. */
    validationOnSubmit?: Maybe<Scalars['Boolean']>;
};

export type FormSettingsTypeRedirectEntryArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FormieCaptchaType = {
    __typename?: 'FormieCaptchaType';
    /** The captcha handle. */
    handle?: Maybe<Scalars['String']>;
    /** The captcha name. */
    name?: Maybe<Scalars['String']>;
    /** The catpcha value. */
    value?: Maybe<Scalars['String']>;
};

export type FormieCsrfTokenType = {
    __typename?: 'FormieCsrfTokenType';
    /** The CSRF name. */
    name?: Maybe<Scalars['String']>;
    /** The CSRF token. */
    value?: Maybe<Scalars['String']>;
};

export type FotowedstrijdHondDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'FotowedstrijdHondDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdHondDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdHondDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdHondDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdHondDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdHondDe_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdHondEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'FotowedstrijdHondEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdHondEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdHondEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdHondEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdHondEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdHondEn_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdHondNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'FotowedstrijdHondNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdHondNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdHondNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdHondNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdHondNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdHondNl_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdPerSeizoenDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'FotowedstrijdPerSeizoenDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdPerSeizoenDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdPerSeizoenDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdPerSeizoenDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdPerSeizoenDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdPerSeizoenDe_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdPerSeizoenEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'FotowedstrijdPerSeizoenEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdPerSeizoenEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdPerSeizoenEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdPerSeizoenEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdPerSeizoenEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdPerSeizoenEn_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdPerSeizoen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'FotowedstrijdPerSeizoen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdPerSeizoen_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdPerSeizoen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdPerSeizoen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdPerSeizoen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdPerSeizoen_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GenericAggregationCount = {
    __typename?: 'GenericAggregationCount';
    hits: Scalars['Int'];
    value: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type GenericImage = Image & {
    __typename?: 'GenericImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type GenericImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The handle of the global set. */
    handle: Scalars['String'];
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The name of the global set. */
    name: Scalars['String'];
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all link fields. */
export type HyperLinkInterface = {
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

/**   An image file available through a CDN URL. */
export type Image = {
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type ImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   A version of an image transformed according to the requested parameters. */
export type ImageTransform = {
    __typename?: 'ImageTransform';
    /**
     *   This is an extremely downscaled version of the original image which can be used as a placeholder while loading the full image.
     *  This is formatted as a string of base64 encoded WebP data, and is therefore only available on transforms with the `WEBP` format.
     */
    placeholder?: Maybe<Scalars['String']>;
    /**   The aspect ratio of this image. */
    ratio: Scalars['Float'];
    /**   The URL for the largest version of this transform. */
    src: Scalars['String'];
    /**   The different available sizes of this transform, formatted for use in an HTML `srcSet` attribute. */
    srcSet: Scalars['String'];
};

/**   Available image transform configurations. This determines the scaling method and the generated sizes. */
export enum ImageTransformConfigEnum {
    /**
     *  Used for displaying avatar images, generally inside a circular frame. Images are transformed and cropped to fill a 1:1 frame.
     * # Available on avatar image fields.
     */
    Avatar = 'AVATAR',
    /**
     *  Used for displaying images of varying aspect ratio in a gallery. Images are transformed to fit inside a 4:3 frame without cropping.
     * # Available on fields containing multiple images.
     */
    Gallery = 'GALLERY',
    /**
     *  Used for displaying large landscape images in a hero element. Images are transformed and cropped to fill a 4:3 frame.
     * # Available on fields called `mainImage`.
     */
    Hero = 'HERO',
    /**
     *  Used for displaying logos. Images are transformed to fit inside a 1:1 frame and extended with empty space to always produce a 1:1 image.
     * # Available on logo image fields.
     */
    Logo = 'LOGO',
    /**
     *  Used for displaying landscape images in a listing. Images are transformed and cropped to fill a 4:3 frame.
     * # Available on fields called `listImage`.
     */
    Tile = 'TILE',
}

/**
 *   Available image formats for transforms. Use WebP whenever possible for lowest image size.
 *  Note that not all formats are available for every transform.
 */
export enum ImageTransformFormatEnum {
    /**  JPEG is not available for transforms with the `LOGO` config. */
    Jpeg = 'JPEG',
    /**  PNG is only available for transforms with the `LOGO` config. */
    Png = 'PNG',
    /**  WebP is available for all transforms. */
    Webp = 'WEBP',
}

export type KeyValueType = {
    __typename?: 'KeyValueType';
    handle?: Maybe<Scalars['String']>;
    heading?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    width?: Maybe<Scalars['String']>;
};

/**   A set of GPS coordinates. */
export type LatLon = {
    __typename?: 'LatLon';
    /**   The latitude. */
    lat: Scalars['Float'];
    /**   The longitude. */
    lon: Scalars['Float'];
};

/**   A set of GPS coordinates. */
export type LatLonInput = {
    /**   The latitude. */
    lat: Scalars['Float'];
    /**   The longitude. */
    lon: Scalars['Float'];
};

export type LeaveReviewInput = {
    bookingId: Scalars['ID'];
    comment: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    tips?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType: TravelGroupType;
};

export type LeaveReviewPayload = {
    __typename?: 'LeaveReviewPayload';
    review?: Maybe<Review>;
};

export type LeaveUnauthenticatedReviewInput = {
    bookingHash: Scalars['ID'];
    comment: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    tips?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType: TravelGroupType;
};

export type Mutation = {
    __typename?: 'Mutation';
    /**
     *   Add an Activity to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteActivity: AddFavoriteActivityPayload;
    /**
     *   Add a Company to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteCompany: AddFavoriteCompanyPayload;
    /**
     *   Add a RentalUnit to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteRentalUnit: AddFavoriteRentalUnitPayload;
    /**
     *   Alter an existing Booking.
     *  The Booking must belong to the currently logged in Customer and Booking.isAlterable must be TRUE.
     */
    alterBooking: AlterBookingPayload;
    /**
     *   Cancel an existing Booking.
     *  The Booking must belong to the currently logged in Customer and Booking.isCancellable must be TRUE.
     */
    cancelBooking: CancelBookingPayload;
    /**   Initiate final checkout for an existing Cart. */
    checkoutCart: CheckoutCartPayload;
    /**
     *   Leave a review for a Booking.
     *  The Booking must be accepted, in the past, linked to the currently logged in Customer, and not already have a Review.
     */
    leaveReview: LeaveReviewPayload;
    /**   Leave a review without an active login session by supplying the hash from a review request email. */
    leaveUnauthenticatedReview: LeaveReviewPayload;
    /**
     *   Initiate payment for an existing Order.
     *  The Order must belong to the currently logged in Customer.
     */
    payOrder: PayOrderPayload;
    ping?: Maybe<Scalars['String']>;
    /**   Register a new Customer. */
    register: RegisterPayload;
    /**
     *   Remove an item for a Customer's favorites.
     *  The Favorite must belong to the currently logged in Customer.
     */
    removeFavorite: RemoveFavoritePayload;
    /**
     *   Request a login email for a Customer.
     *  The email contains a link which supplies an `authCode` for the oAuth token endpoint.
     */
    sendAuthenticationEmail: SendAuthenticationEmailPayload;
    /**   Create or update a Cart with contents and/or details. */
    updateCart: UpdateCartPayload;
    /**
     *   Update the details of an existing Customer.
     *  This can only be performed on the currently logged in Customer.
     */
    updateCustomer: UpdateCustomerPayload;
};

export type MutationAddFavoriteActivityArgs = {
    input: AddFavoriteActivityInput;
};

export type MutationAddFavoriteCompanyArgs = {
    input: AddFavoriteCompanyInput;
};

export type MutationAddFavoriteRentalUnitArgs = {
    input: AddFavoriteRentalUnitInput;
};

export type MutationAlterBookingArgs = {
    input: AlterBookingInput;
};

export type MutationCancelBookingArgs = {
    input: CancelBookingInput;
};

export type MutationCheckoutCartArgs = {
    input: CheckoutCartInput;
};

export type MutationLeaveReviewArgs = {
    input: LeaveReviewInput;
};

export type MutationLeaveUnauthenticatedReviewArgs = {
    input: LeaveUnauthenticatedReviewInput;
};

export type MutationPayOrderArgs = {
    input: PayOrderInput;
};

export type MutationRegisterArgs = {
    input: RegisterInput;
};

export type MutationRemoveFavoriteArgs = {
    input: RemoveFavoriteInput;
};

export type MutationSendAuthenticationEmailArgs = {
    input: SendAuthenticationEmailInput;
};

export type MutationUpdateCartArgs = {
    input: UpdateCartInput;
};

export type MutationUpdateCustomerArgs = {
    input: UpdateCustomerInput;
};

export type NameType = {
    __typename?: 'NameType';
    /** The first name value of the name. */
    firstName?: Maybe<Scalars['String']>;
    /** The full name value. */
    fullName?: Maybe<Scalars['String']>;
    /** The last name value of the name. */
    lastName?: Maybe<Scalars['String']>;
    /** The middle name value of the name. */
    middleName?: Maybe<Scalars['String']>;
    /** The prefix value of the name. */
    prefix?: Maybe<Scalars['String']>;
};

export type NormalOperatingHours = {
    __typename?: 'NormalOperatingHours';
    friday: Array<TimeOpenClosed>;
    monday: Array<TimeOpenClosed>;
    saturday: Array<TimeOpenClosed>;
    sunday: Array<TimeOpenClosed>;
    thursday: Array<TimeOpenClosed>;
    tuesday: Array<TimeOpenClosed>;
    wednesday: Array<TimeOpenClosed>;
};

export type Order = {
    __typename?: 'Order';
    affiliate?: Maybe<Affiliate>;
    affiliateReference?: Maybe<Scalars['String']>;
    agreedToTerms?: Maybe<Scalars['Boolean']>;
    createdDate?: Maybe<Scalars['String']>;
    customer?: Maybe<Customer>;
    downloadOverviewUrl?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    invoiceAddress?: Maybe<Address>;
    isDeskOrder: Scalars['Boolean'];
    newsletterOptin?: Maybe<Scalars['Boolean']>;
    number?: Maybe<Scalars['String']>;
    orderItems: Array<OrderItem>;
    paymentOptions?: Maybe<PaymentOptions>;
    payments: Array<Payment>;
    totalPrice: Scalars['Float'];
};

export type OrderItem = {
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    totalPrice: Scalars['Float'];
};

/** This is the interface implemented by all pages. */
export type PageInterface = {
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The page’s label. */
    label?: Maybe<Scalars['String']>;
    /** The page’s fields. */
    pageFields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The page’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The page’s settings, including buttons. */
    settings?: Maybe<PageSettingsInterface>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all pages. */
export type PageInterfacePageFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all pages. */
export type PageInterfaceRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all pages. */
export type PageSettingsInterface = {
    /** The page’s back button label. */
    backButtonLabel?: Maybe<Scalars['String']>;
    /** The page’s button (back and submit) positions. */
    buttonsPosition?: Maybe<Scalars['String']>;
    /** The page’s button (back and submit) container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The page’s button (back and submit) CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** Whether the page’s next button has conditions enabled, for multi-page forms. */
    enableNextButtonConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the page has conditions enabled. */
    enablePageConditions?: Maybe<Scalars['Boolean']>;
    /** The page’s button (back and submit) input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The page’s conditions for whether to show the next button, for multi-page forms as a JSON string. */
    nextButtonConditions?: Maybe<Scalars['Json']>;
    /** The page’s conditions as a JSON string. */
    pageConditions?: Maybe<Scalars['Json']>;
    /** The page’s draft button label. */
    saveButtonLabel?: Maybe<Scalars['String']>;
    /** Whether to show the page’s back button. */
    showBackButton?: Maybe<Scalars['Boolean']>;
    /** Whether to show the page’s draft button. */
    showSaveButton?: Maybe<Scalars['Boolean']>;
    /** The page’s submit button label. */
    submitButtonLabel?: Maybe<Scalars['String']>;
};

export type PageSettingsType = PageSettingsInterface & {
    __typename?: 'PageSettingsType';
    /** The page’s back button label. */
    backButtonLabel?: Maybe<Scalars['String']>;
    /** The page’s button (back and submit) positions. */
    buttonsPosition?: Maybe<Scalars['String']>;
    /** The page’s button (back and submit) container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The page’s button (back and submit) CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** Whether the page’s next button has conditions enabled, for multi-page forms. */
    enableNextButtonConditions?: Maybe<Scalars['Boolean']>;
    /** Whether the page has conditions enabled. */
    enablePageConditions?: Maybe<Scalars['Boolean']>;
    /** The page’s button (back and submit) input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The page’s conditions for whether to show the next button, for multi-page forms as a JSON string. */
    nextButtonConditions?: Maybe<Scalars['Json']>;
    /** The page’s conditions as a JSON string. */
    pageConditions?: Maybe<Scalars['Json']>;
    /** The page’s draft button label. */
    saveButtonLabel?: Maybe<Scalars['String']>;
    /** Whether to show the page’s back button. */
    showBackButton?: Maybe<Scalars['Boolean']>;
    /** Whether to show the page’s draft button. */
    showSaveButton?: Maybe<Scalars['Boolean']>;
    /** The page’s submit button label. */
    submitButtonLabel?: Maybe<Scalars['String']>;
};

export type PageType = PageInterface & {
    __typename?: 'PageType';
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The page’s label. */
    label?: Maybe<Scalars['String']>;
    /** The page’s fields. */
    pageFields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The page’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The page’s settings, including buttons. */
    settings?: Maybe<PageSettingsInterface>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type PageTypePageFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type PageTypeRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type PayOrderInput = {
    orderId: Scalars['ID'];
    paymentOptions: PaymentOptionsInput;
};

export type PayOrderPayload = {
    __typename?: 'PayOrderPayload';
    order?: Maybe<Order>;
    redirectUrl?: Maybe<Scalars['String']>;
};

export type Payment = {
    __typename?: 'Payment';
    amount: Scalars['Float'];
    date: Scalars['String'];
    method: PaymentMethodEnum;
    status: PaymentStatusEnum;
};

export enum PaymentMethodEnum {
    ApplePay = 'APPLE_PAY',
    Bancontact = 'BANCONTACT',
    BankAccount = 'BANK_ACCOUNT',
    Cash = 'CASH',
    CreditCard = 'CREDIT_CARD',
    CreditNote = 'CREDIT_NOTE',
    DeskBooking = 'DESK_BOOKING',
    GiftCard = 'GIFT_CARD',
    Giropay = 'GIROPAY',
    Ideal = 'IDEAL',
    IngHomepay = 'ING_HOMEPAY',
    None = 'NONE',
    Other = 'OTHER',
    Paypal = 'PAYPAL',
    PointOfSale = 'POINT_OF_SALE',
    SepaDirectDebit = 'SEPA_DIRECT_DEBIT',
    SepaTransfer = 'SEPA_TRANSFER',
    Sofort = 'SOFORT',
    Trustly = 'TRUSTLY',
}

export type PaymentOptions = {
    __typename?: 'PaymentOptions';
    /**   The methods available for this payment. */
    availableMethods: Array<PaymentMethodEnum>;
    /**   The date before which the totalAmountDue must be paid. Formatted as: yyyy-mm-dd. */
    fullPaymentDueDate?: Maybe<Scalars['String']>;
    /**   The minimum amount that needs to be paid at this point. */
    partialPaymentAmount: Scalars['Float'];
    /**   The total remaining amount due to be paid through Travelbase. */
    totalAmountDue: Scalars['Float'];
};

export type PaymentOptionsInput = {
    /**   Method "SEPA_DIRECT_DEBIT" only, this must be supplied */
    accountHolderName?: InputMaybe<Scalars['String']>;
    /**   Method "SEPA_DIRECT_DEBIT" only, this must be supplied and must be a valid IBAN */
    iban?: InputMaybe<Scalars['String']>;
    landingUrl: Scalars['String'];
    method: PaymentMethodEnum;
    payPartialAmount: Scalars['Boolean'];
    /**   Method "POINT_OF_SALE" only, this must be supplied and be stored in the PaymentTerminal table */
    sellDeviceId?: InputMaybe<Scalars['String']>;
};

export enum PaymentStatusEnum {
    Completed = 'COMPLETED',
    Failed = 'FAILED',
    Initializing = 'INITIALIZING',
    Pending = 'PENDING',
    Provisional = 'PROVISIONAL',
}

export type Place = {
    __typename?: 'Place';
    id: Scalars['ID'];
    name: Scalars['String'];
    position: Scalars['Int'];
};

/**   Description of a POI and the distance to it from the parent object. */
export type PointOfInterest = {
    __typename?: 'PointOfInterest';
    /**   The distance in meters from the parent object to the POI (as the crow flies). */
    distance: Scalars['Int'];
    /**
     *   Name of the POI.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type PriceLine = {
    __typename?: 'PriceLine';
    category?: Maybe<PriceLineCategoryEnum>;
    label: Scalars['String'];
    quantity?: Maybe<Scalars['String']>;
    totalPrice: Scalars['Float'];
    unitPrice?: Maybe<Scalars['Float']>;
};

export enum PriceLineCategoryEnum {
    Accommodation = 'ACCOMMODATION',
    AdminCosts = 'ADMIN_COSTS',
    Charges = 'CHARGES',
    Insurance = 'INSURANCE',
    NotInTotal = 'NOT_IN_TOTAL',
    Special = 'SPECIAL',
    Surcharges = 'SURCHARGES',
}

export type PropertyFilterCount = {
    __typename?: 'PropertyFilterCount';
    hits: Scalars['Int'];
    property: Scalars['String'];
};

/**   Properties to filter by in a search query. */
export type PropertyFilterInput = {
    /**
     *   The handles of the properties to filter by.
     *  Multiples handles are combined as "OR".
     *  Be aware that when treating filters as "OR", the corresponding FilterBuckets in the result are essentially useless as possible values will be excluded based on already active filters.
     */
    properties: Array<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    /**   Retrieve details for a specific Accommodation. */
    accommodation?: Maybe<Accommodation>;
    /**   Retrieve details for a specific set of Accommodations. */
    accommodationsBySlug: Array<Accommodation>;
    /** Entries within the “Winactie” section. */
    actionEntries?: Maybe<Array<Maybe<ActionSectionEntryUnion>>>;
    /**   Search within all available Activities. */
    activities: ActivitySearchResult;
    /**   Retrieve details for a specific set of Activities. */
    activitiesBySlug: Array<Activity>;
    /**   Retrieve details for a specific Activity. */
    activity?: Maybe<Activity>;
    /**   Retrieve details for all available ActivityThemes. */
    activityThemes: Array<ActivityTheme>;
    /** Entries within the “Agenda” section. */
    agendaEntries?: Maybe<Array<Maybe<AgendaSectionEntryUnion>>>;
    /** Entries within the “Artikelen” section. */
    articlesEntries?: Maybe<Array<Maybe<ArticlesSectionEntryUnion>>>;
    /** This query is used to query for a single asset. */
    asset?: Maybe<AssetInterface>;
    /** This query is used to return the number of assets. */
    assetCount: Scalars['Int'];
    /** This query is used to query for assets. */
    assets?: Maybe<Array<Maybe<AssetInterface>>>;
    /** Entries within the “Banners” section. */
    bannersEntries?: Maybe<Array<Maybe<BannersSectionEntryUnion>>>;
    /**
     *   Preview the effects of altering a specific Booking.
     *  The booking must belong to the currently logged in Customer.
     */
    bookingAlterationPreview?: Maybe<BookingChangePreview>;
    /**
     *   Preview the effects of cancelling a specific Booking.
     *  The booking must belong to the currently logged in Customer.
     */
    bookingCancellationPreview?: Maybe<BookingChangePreview>;
    /**   Retrieve details for a specific Cart. */
    cart?: Maybe<Cart>;
    /**   Search within all available Companies. */
    companies: CompanySearchResult;
    /**   Retrieve details for a specific set of Companies. */
    companiesBySlug: Array<Company>;
    /** Entries within the “Bedrijvengids” section. */
    companiesEntries?: Maybe<Array<Maybe<CompaniesSectionEntryUnion>>>;
    /**   Retrieve details for a specific Company. */
    company?: Maybe<Company>;
    /**   Retrieve details for all available CompanyCategories. */
    companyCategories: Array<CompanyCategory>;
    /** Entries within the “Contact” section. */
    contactEntries?: Maybe<Array<Maybe<ContactSectionEntryUnion>>>;
    /** This query is used to query for entries. */
    entries?: Maybe<Array<Maybe<EntryInterface>>>;
    /** This query is used to query for a single entry. */
    entry?: Maybe<EntryInterface>;
    /** This query is used to return the number of entries. */
    entryCount: Scalars['Int'];
    /** Entries within the “Footer” section. */
    footerEntries?: Maybe<Array<Maybe<FooterSectionEntryUnion>>>;
    /** This query is used to query for a single form. */
    formieForm?: Maybe<FormInterface>;
    /** This query is used to return the number of forms. */
    formieFormCount: Scalars['Int'];
    /** This query is used to query for forms. */
    formieForms?: Maybe<Array<Maybe<FormInterface>>>;
    /** Search within both CMS and Travelbase content. */
    globalSearch?: Maybe<SearchResult>;
    /** This query is used to query for a single global set. */
    globalSet?: Maybe<GlobalSetInterface>;
    /** This query is used to query for global sets. */
    globalSets?: Maybe<Array<Maybe<GlobalSetInterface>>>;
    /** Entries within the “Home” section. */
    homepageEntries?: Maybe<Array<Maybe<HomepageSectionEntryUnion>>>;
    /** Entries within the “Menu” section. */
    menuEntries?: Maybe<Array<Maybe<MenuSectionEntryUnion>>>;
    /** Entries within the “Mijn Texel” section. */
    myTexelEntries?: Maybe<Array<Maybe<MyTexelSectionEntryUnion>>>;
    /** Entries within the “Nieuws” section. */
    newsEntries?: Maybe<Array<Maybe<NewsSectionEntryUnion>>>;
    /**   Retrieve details for a specific Order. */
    order?: Maybe<Order>;
    /** Entries within the “Pagina's” section. */
    pagesEntries?: Maybe<Array<Maybe<PagesSectionEntryUnion>>>;
    ping?: Maybe<Scalars['String']>;
    /**   Retrieve details for all available Places. */
    places: Array<Place>;
    /**   Retrieve details for a specific RentalUnit. */
    rentalUnit?: Maybe<RentalUnit>;
    /**   Retrieve details for a specific RentalUnit by id. */
    rentalUnitById?: Maybe<RentalUnit>;
    /**   Retrieve details for a specific set of RentalUnits. */
    rentalUnits: Array<RentalUnit>;
    /**
     * This query is used to query for Retour redirects.
     * @deprecated This query is deprecated and will be removed in the future. You should use `retourResolveRedirect` instead.
     */
    retour?: Maybe<RetourInterface>;
    /** This query is used to query for all Retour redirects for a site. */
    retourRedirects?: Maybe<Array<Maybe<RetourInterface>>>;
    /** This query is used to query for Retour redirects. */
    retourResolveRedirect?: Maybe<RetourInterface>;
    /**
     * This query is used to search for keywords.
     * @deprecated Limited and contains bugs, use `globalSearch` instead.
     */
    search?: Maybe<Array<Maybe<SearchType>>>;
    /** Entries within the “Zoeken” section. */
    searchEntries?: Maybe<Array<Maybe<SearchSectionEntryUnion>>>;
    /**   Search within all available RentalUnits. */
    searchRentalUnits: SearchRentalUnitsResult;
    /**
     *   Search for available Trips.
     *  Note that this will only ever return and/or count 1 Trip per RentalUnit, even if multiple Trips for the same RentalUnit match the search parameters.
     */
    searchTrips: SearchTripsResult;
    /**   Retrieve details for a specific Special. */
    special?: Maybe<Special>;
    /** This query is used to query for a single tag. */
    tag?: Maybe<TagInterface>;
    /** This query is used to return the number of tags. */
    tagCount: Scalars['Int'];
    /** This query is used to query for tags. */
    tags?: Maybe<Array<Maybe<TagInterface>>>;
    /** Entries within the “Bedankt” section. */
    thankYouEntries?: Maybe<Array<Maybe<ThankYouSectionEntryUnion>>>;
    /** Entries within the “Tips” section. */
    tipsEntries?: Maybe<Array<Maybe<TipsSectionEntryUnion>>>;
    /** This query is used to get all the accommodations for the sitemap. */
    travelbaseObjectsSitemap?: Maybe<Array<Maybe<TravelbaseObjectsSitemapType>>>;
    /**   Retrieve all possible properties by which RentalUnits can be filtered. */
    unitFilterProperties: Array<UnitFilterPropertyCategory>;
    /** @deprecated Use searchRentalUnits or searchTrips instead */
    unitSearch: UnitSearchResult;
    /** @deprecated Use searchTrips.stats.dateCounts instead */
    unitSearchAlternativeDates: Array<DateHitCount>;
    /**   Returns the currently logged in Customer, if any. */
    viewer?: Maybe<Customer>;
    /** Entries within the “Vouchers” section. */
    vouchersEntries?: Maybe<Array<Maybe<VouchersSectionEntryUnion>>>;
};

export type QueryAccommodationArgs = {
    slug: Scalars['String'];
};

export type QueryAccommodationsBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryActionEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<ActivitySearchOrderEnum>;
    params: ActivitySearchParamsInput;
};

export type QueryActivitiesBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryActivityArgs = {
    slug: Scalars['String'];
};

export type QueryAgendaEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryArticlesEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssetArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryAssetCountArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryAssetsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryBannersEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBookingAlterationPreviewArgs = {
    input: AlterBookingInput;
};

export type QueryBookingCancellationPreviewArgs = {
    input: CancelBookingInput;
};

export type QueryCartArgs = {
    id: Scalars['ID'];
};

export type QueryCompaniesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    params: CompanySearchParamsInput;
    sortByDistanceTo?: InputMaybe<LatLonInput>;
};

export type QueryCompaniesBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryCompaniesEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCompanyArgs = {
    slug: Scalars['String'];
};

export type QueryContactEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEntriesArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEntryArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEntryCountArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFooterEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFormieFormArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormieFormCountArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormieFormsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGlobalSearchArgs = {
    language: Scalars['String'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    query: Scalars['String'];
    type?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGlobalSetArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactEmailText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMessengerText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMyTexelText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactPhoneText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactShopText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactWhatsappText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGlobalSetsArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactEmailText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMessengerText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMyTexelText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactPhoneText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactShopText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactWhatsappText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryHomepageEntriesArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMenuEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMyTexelEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryNewsEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrderArgs = {
    id: Scalars['ID'];
};

export type QueryPagesEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRentalUnitArgs = {
    slug: Scalars['String'];
};

export type QueryRentalUnitByIdArgs = {
    id: Scalars['ID'];
};

export type QueryRentalUnitsArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryRetourArgs = {
    site?: InputMaybe<Scalars['String']>;
    siteId?: InputMaybe<Scalars['Int']>;
    uri?: InputMaybe<Scalars['String']>;
};

export type QueryRetourRedirectsArgs = {
    site?: InputMaybe<Scalars['String']>;
    siteId?: InputMaybe<Scalars['Int']>;
};

export type QueryRetourResolveRedirectArgs = {
    site?: InputMaybe<Scalars['String']>;
    siteId?: InputMaybe<Scalars['Int']>;
    uri?: InputMaybe<Scalars['String']>;
};

export type QuerySearchArgs = {
    language?: InputMaybe<Scalars['String']>;
    q?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchRentalUnitsArgs = {
    babies?: InputMaybe<Scalars['Int']>;
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    limit?: InputMaybe<Scalars['Int']>;
    mapArea?: InputMaybe<BoundingBoxInput>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<SearchRentalUnitsOrderEnum>;
    persons?: InputMaybe<Scalars['Int']>;
    pets?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchTripsArgs = {
    babies: Scalars['Int'];
    date: Array<Scalars['String']>;
    duration: Array<Scalars['Int']>;
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    mapArea?: InputMaybe<BoundingBoxInput>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: InputMaybe<RangeFilterInput>;
    specialId?: InputMaybe<Array<Scalars['ID']>>;
    tripType?: InputMaybe<Array<TripTypeEnum>>;
};

export type QuerySpecialArgs = {
    id: Scalars['ID'];
};

export type QueryTagArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTagCountArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryThankYouEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTipsEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTravelbaseObjectsSitemapArgs = {
    language?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type QueryUnitSearchArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    mapArea?: InputMaybe<BoundingBoxInput>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<UnitSearchOrderEnum>;
    params: UnitSearchParamsInput;
};

export type QueryUnitSearchAlternativeDatesArgs = {
    params: UnitAndTripSearchParamsInput;
};

export type QueryVouchersEntriesArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type RangeFilterInput = {
    max?: InputMaybe<Scalars['Float']>;
    min?: InputMaybe<Scalars['Float']>;
};

export type Rate = {
    __typename?: 'Rate';
    id: Scalars['ID'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    position: Scalars['Int'];
    price: Scalars['Float'];
};

export type RegisterInput = {
    agreedToTerms: Scalars['Boolean'];
    email: Scalars['String'];
    landingUrl: Scalars['String'];
    newsletterOptIn: Scalars['Boolean'];
};

export type RegisterPayload = {
    __typename?: 'RegisterPayload';
    customer?: Maybe<Customer>;
};

export type RemoveFavoriteInput = {
    favoriteId: Scalars['ID'];
};

export type RemoveFavoritePayload = {
    __typename?: 'RemoveFavoritePayload';
    success: Scalars['Boolean'];
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnit = {
    __typename?: 'RentalUnit';
    /**   The Accommodation this RentalUnit is a part of. */
    accommodation: Accommodation;
    /**   Search for Trips in other RentalUnits within the same Accommodation. */
    alternativesInAccommodation?: Maybe<UnitSearchResult>;
    /**   The amount of bathrooms present in this RentalUnit. */
    amountBathrooms?: Maybe<Scalars['Int']>;
    /**   The amount of bedrooms present in this RentalUnit. */
    amountBedrooms?: Maybe<Scalars['Int']>;
    /**   The amount of beds present in this RentalUnit. */
    amountBeds?: Maybe<Scalars['Int']>;
    /**   The amount of toilets present in this RentalUnit. */
    amountToilets?: Maybe<Scalars['Int']>;
    /**   The attributes assigned to this RentalUnit, organized into categories. */
    attributeCategories: Array<AttributeCategory>;
    /**   Indicates the Travelbase brand this RentalUnit belongs to (used on aggregate sites). */
    brand: Scalars['String'];
    /**   Whether or not cancellation insurance can be purchased during checkout for this RentalUnit. */
    cancellationInsuranceAvailable: Scalars['Boolean'];
    /**   Unique code representing this RentalUnit to facilitate communication with partners and customers. */
    code: Scalars['String'];
    /**   Whether or not contents insurance can be purchased during checkout for this RentalUnit. */
    contentsInsuranceAvailable: Scalars['Boolean'];
    /**
     *   An optional description of features unique to this RentalUnit.
     *  This value is language dependent.
     */
    descriptionExtras?: Maybe<Scalars['String']>;
    /**
     *   A general description of the RentalUnit, describing its layout.
     *  This value is language dependent.
     */
    descriptionLayout: Scalars['String'];
    /**
     *   A text containing important information for any (prospective) guests.
     *  This information is also displayed on the booking voucher.
     *  This value is language dependent.
     */
    descriptionParticulars?: Maybe<Scalars['String']>;
    /**
     *   The first date for which a Trip is available, if any.
     *  Formatted as yyyy-mm-dd.
     */
    firstTripDate?: Maybe<Scalars['String']>;
    /**   If set, bookings for this RentalUnit can be cancelled with full refund up to this many days before arrival. */
    flexCancellationDays?: Maybe<Scalars['Int']>;
    /**   A URL for an embeddable 3D view of the RentalUnit. */
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    /**   Way to check if this RentalUnit matches a specific filter property. */
    hasFilterProperty: Scalars['Boolean'];
    /**   Unique ID representing this RentalUnit. */
    id: Scalars['ID'];
    /**   A set of images intended to be displayed as some form of photo gallery. */
    images: Array<AccommodationImage>;
    /**   An image (photo) intended for use in a listing/as a thumbnail. */
    listImage: GenericImage;
    /**   The living area (in square meters) of the RentalUnit. */
    livingArea?: Maybe<Scalars['Int']>;
    /**   An image (photo) intended for use in a large (hero) display. */
    mainImage?: Maybe<GenericImage>;
    /**   The maximum amount of babies that may be brought along without counting against the occupancy and without being charged as an occupant. */
    maxExtraBabyOccupancy: Scalars['Int'];
    /**   The maximum travel party size for which this RentalUnit can be booked. */
    maxOccupancy: Scalars['Int'];
    /**   The minimum travel party size for which this RentalUnit can be booked. */
    minOccupancy: Scalars['Int'];
    /**   The municipal registration number for this RentalUnit. */
    municipalRegistration?: Maybe<Scalars['String']>;
    /**   The name of this RentalUnit. */
    name: Scalars['String'];
    /**   The outdoor area (in square meters) private to the RentalUnit. */
    outdoorArea?: Maybe<Scalars['Int']>;
    /**   The amount of pets allowed in this RentalUnit. */
    petsAllowed: Scalars['Int'];
    /**
     *   Search for alternative RentalUnits given the specified search parameters.
     *  Alternatives will always have the same `type` and `maxOccupancy`, and will prioritize similar `livingArea` and geographic location.
     */
    relevantAlternatives?: Maybe<UnitSearchResult>;
    /**   A summary of the reviews for this RentalUnit. */
    reviewStats?: Maybe<ReviewStats>;
    /**   The reviews submitted by past guests for this RentalUnit. */
    reviews: Array<Review>;
    /**   Unique slug to represent this RentalUnit, most commonly in URLs. */
    slug: Scalars['String'];
    /**   The surcharges valid on the specified arrival date, or today if no date is submitted. */
    surcharges: Array<RentalUnitSurcharge>;
    /**
     *   This is a short "marketing-oriented" description used as a display title.
     *  This value is language dependent.
     */
    tagline: Scalars['String'];
    /**   Search within available Trips for this RentalUnit in a specific time period. */
    trips: Array<Trip>;
    /**   The type of this RentalUnit. */
    type: RentalUnitTypeEnum;
    /**   A URL for an embeddable video presenting the RentalUnit. */
    videoUrl?: Maybe<Scalars['String']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitAlternativesInAccommodationArgs = {
    params?: InputMaybe<UnitSearchParamsInput>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitHasFilterPropertyArgs = {
    handle: Scalars['String'];
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitRelevantAlternativesArgs = {
    params?: InputMaybe<UnitSearchParamsInput>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitReviewsArgs = {
    limit: Scalars['Int'];
    offset?: InputMaybe<Scalars['Int']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitSurchargesArgs = {
    arrivalDate?: InputMaybe<Scalars['String']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitTripsArgs = {
    babies: Scalars['Int'];
    endDate: Scalars['String'];
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    startDate: Scalars['String'];
};

export type RentalUnitSurcharge = {
    __typename?: 'RentalUnitSurcharge';
    calculation: SurchargeCalculationEnum;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    maxAmount: Scalars['Int'];
    minAmount: Scalars['Int'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    unitPrice: Scalars['Float'];
};

/**   Types of RentalUnit. */
export enum RentalUnitTypeEnum {
    Bed = 'BED',
    Boat = 'BOAT',
    CamperSpot = 'CAMPER_SPOT',
    CampingPitch = 'CAMPING_PITCH',
    Home = 'HOME',
    Room = 'ROOM',
    Tent = 'TENT',
}

/** This is the interface implemented by Retour. */
export type RetourInterface = {
    /** The id of the Element associated with this redirect (unused/vestigial). */
    associatedElementId?: Maybe<Scalars['Int']>;
    /** Whether the redirect is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The number of times this redirect has been hit. */
    hitCount?: Maybe<Scalars['Int']>;
    /** A datetime string of when this redirect was last hit. */
    hitLastTime?: Maybe<Scalars['String']>;
    /** The id of the redirect. */
    id?: Maybe<Scalars['Int']>;
    /** The URL that should be redirected to. */
    redirectDestUrl?: Maybe<Scalars['String']>;
    /** The http status code that should be used for the redirect. */
    redirectHttpCode?: Maybe<Scalars['Int']>;
    /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
    redirectMatchType?: Maybe<Scalars['String']>;
    /** Should the legacy URL be matched by path or by full URL? */
    redirectSrcMatch?: Maybe<Scalars['String']>;
    /** The unparsed URL pattern that Retour should match. */
    redirectSrcUrl?: Maybe<Scalars['String']>;
    /** The parsed URL pattern that Retour should match. */
    redirectSrcUrlParsed?: Maybe<Scalars['String']>;
    /** The site handle of the redirect (or null for all sites). */
    site?: Maybe<Scalars['String']>;
    /** The siteId of the redirect (0 or null for all sites). */
    siteId?: Maybe<Scalars['Int']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/** This entity has all the Retour fields */
export type RetourType = RetourInterface & {
    __typename?: 'RetourType';
    /** The id of the Element associated with this redirect (unused/vestigial). */
    associatedElementId?: Maybe<Scalars['Int']>;
    /** Whether the redirect is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The number of times this redirect has been hit. */
    hitCount?: Maybe<Scalars['Int']>;
    /** A datetime string of when this redirect was last hit. */
    hitLastTime?: Maybe<Scalars['String']>;
    /** The id of the redirect. */
    id?: Maybe<Scalars['Int']>;
    /** The URL that should be redirected to. */
    redirectDestUrl?: Maybe<Scalars['String']>;
    /** The http status code that should be used for the redirect. */
    redirectHttpCode?: Maybe<Scalars['Int']>;
    /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
    redirectMatchType?: Maybe<Scalars['String']>;
    /** Should the legacy URL be matched by path or by full URL? */
    redirectSrcMatch?: Maybe<Scalars['String']>;
    /** The unparsed URL pattern that Retour should match. */
    redirectSrcUrl?: Maybe<Scalars['String']>;
    /** The parsed URL pattern that Retour should match. */
    redirectSrcUrlParsed?: Maybe<Scalars['String']>;
    /** The site handle of the redirect (or null for all sites). */
    site?: Maybe<Scalars['String']>;
    /** The siteId of the redirect (0 or null for all sites). */
    siteId?: Maybe<Scalars['Int']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/**   A review of a RentalUnit submitted by a Customer. */
export type Review = {
    __typename?: 'Review';
    /** @deprecated No longer supported */
    anonymous?: Maybe<Scalars['Boolean']>;
    /**   The average of the specific ratings given. */
    average: Scalars['Float'];
    /**   The city the Customer is from. */
    city?: Maybe<Scalars['String']>;
    /**   The main text submitted by the Customer. */
    comment: Scalars['String'];
    /**
     *   The date this Review was submitted.
     *  Formatted as yyyy-mm-dd.
     */
    date: Scalars['String'];
    /** @deprecated No longer supported */
    displayName?: Maybe<Scalars['String']>;
    /**   Unique ID representing this Review. */
    id: Scalars['ID'];
    /**
     *   The language in which this Review was written.
     *  This is a 2-letter language code.
     */
    language?: Maybe<Scalars['String']>;
    /**   Rating (1-10) of the hospitality provided. */
    ratingHospitality?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the RentalUnit's layout. */
    ratingLayout?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the location of the Accommodation. */
    ratingLocation?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the level of maintenance. */
    ratingMaintenance?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of value for money. */
    ratingValueForMoney?: Maybe<Scalars['Int']>;
    /**   The reply to this review submitted by the Partner, if any. */
    reply?: Maybe<Scalars['String']>;
    /**
     *   Tips the Customer submitted for the Partner, if any.
     *  This field is not publicly visible, only logged in Customers can see this for the Reviews they submitted themselves.
     */
    tips?: Maybe<Scalars['String']>;
    /**   The title/short summary the Customer submitted. */
    title: Scalars['String'];
    /**
     *   The type of travel group this Customer visited the RentalUnit with.
     *  This field is not publicly visible, only logged in Customers can see this for the Reviews they submitted themselves.
     */
    travelGroupType?: Maybe<TravelGroupType>;
};

/**   Summary of the Reviews submitted. */
export type ReviewStats = {
    __typename?: 'ReviewStats';
    /**   The average of all ratings across all Reviews. */
    average: Scalars['Float'];
    /**   Amount of Reviews available. */
    count: Scalars['Int'];
    /**   The average of the `hospitality` ratings across all Reviews. */
    hospitality: Scalars['Float'];
    /**   The average of the `layout` ratings across all Reviews. */
    layout: Scalars['Float'];
    /**   The average of the `location` ratings across all Reviews. */
    location: Scalars['Float'];
    /**   The average of the `maintenance` ratings across all Reviews. */
    maintenance: Scalars['Float'];
    /**   The average of the `valueForMoney` ratings across all Reviews. */
    valueForMoney: Scalars['Float'];
};

/** This is the interface implemented by all rows. */
export type RowInterface = {
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The row’s fields. */
    rowFields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all rows. */
export type RowInterfaceRowFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type RowType = RowInterface & {
    __typename?: 'RowType';
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The row’s fields. */
    rowFields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type RowTypeRowFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type SearchImage = {
    __typename?: 'SearchImage';
    /** The image's extension */
    extension?: Maybe<Scalars['String']>;
    /** The image's placeholderUrl */
    placeholderUrl?: Maybe<Scalars['String']>;
    /** The image's srcset */
    srcset?: Maybe<Scalars['String']>;
    /** The image's srcsetWebp */
    srcsetWebp?: Maybe<Scalars['String']>;
    /** The image's title */
    title?: Maybe<Scalars['String']>;
    /** The image's url */
    url?: Maybe<Scalars['String']>;
};

/**   RentalUnit search result ordering options. */
export enum SearchRentalUnitsOrderEnum {
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    RandomBoosted = 'RANDOM_BOOSTED',
    /**  Sort by amount of recent bookings. */
    RecentBookingsBoosted = 'RECENT_BOOKINGS_BOOSTED',
    /**  Sort by average RentalUnit review rating, descending. */
    ReviewRatingDesc = 'REVIEW_RATING_DESC',
}

/**   Results of a RentalUnit search query. */
export type SearchRentalUnitsResult = {
    __typename?: 'SearchRentalUnitsResult';
    /**   The search results. */
    hits: Array<RentalUnit>;
    /**   Available filter properties and corresponding result counts within the result set. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   Total amount of matching RentalUnits. */
    totalHits: Scalars['Int'];
};

export type SearchResult = {
    __typename?: 'SearchResult';
    hits?: Maybe<Array<Maybe<SearchType>>>;
    totalHits?: Maybe<Scalars['Int']>;
    typeCounts?: Maybe<Array<Maybe<Bucket>>>;
};

/**   The results of a search within Trips. */
export type SearchTripsResult = {
    __typename?: 'SearchTripsResult';
    /**   Fetch Trips matching the specified parameters. */
    hits: Array<Trip>;
    /**   Statistical information about the Trips matching the specified parameters. */
    stats: SearchTripsStats;
};

/**   The results of a search within Trips. */
export type SearchTripsResultHitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<UnitSearchOrderEnum>;
};

/**   Statistics for a specific search whitin Trips. */
export type SearchTripsStats = {
    __typename?: 'SearchTripsStats';
    /**
     *   List of dates and the respective amount of Trips found for that date.
     *  Only useful in case the search parameters contain multiple dates. Any date with 0 Trips found will not appear in this result.
     */
    dateCounts: Array<DateCount>;
    /**   The highest price among the Trips found. */
    maxPrice?: Maybe<Scalars['Float']>;
    /**   The lowest price among the Trips found. */
    minPrice?: Maybe<Scalars['Float']>;
    /**   List of filter properties and the respective amount of Trips found. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   List of Specials and the respective amount of Trips found for that Special. */
    specialCounts: Array<SpecialCount>;
    /**   The total amount of Trips found. */
    totalHits: Scalars['Int'];
    /**   List of TripTypes and the respective amount of Trips found for that TripType. */
    tripTypeCounts: Array<TripTypeCount>;
};

export type SearchType = {
    __typename?: 'SearchType';
    /** The document's image */
    image?: Maybe<SearchImage>;
    /** The document's language */
    language?: Maybe<Scalars['String']>;
    /** The document's post date */
    postDate?: Maybe<Scalars['String']>;
    /** The document's tags */
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The document's title */
    title?: Maybe<Scalars['String']>;
    /** The document's type */
    type?: Maybe<Scalars['String']>;
    /** The document's uri */
    uri?: Maybe<Scalars['String']>;
};

export type SendAuthenticationEmailInput = {
    email: Scalars['String'];
    landingUrl: Scalars['String'];
};

export type SendAuthenticationEmailPayload = {
    __typename?: 'SendAuthenticationEmailPayload';
    success?: Maybe<Scalars['Boolean']>;
};

export type Special = {
    __typename?: 'Special';
    /**   This value is language dependent. */
    description: Scalars['String'];
    id: Scalars['ID'];
    landingPageUrl?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    name: Scalars['String'];
};

export type SpecialCount = {
    __typename?: 'SpecialCount';
    hits: Scalars['Int'];
    special: Special;
};

export type SpecialOperatingHours = {
    __typename?: 'SpecialOperatingHours';
    endDate: Scalars['String'];
    friday?: Maybe<Array<TimeOpenClosed>>;
    monday?: Maybe<Array<TimeOpenClosed>>;
    saturday?: Maybe<Array<TimeOpenClosed>>;
    startDate: Scalars['String'];
    sunday?: Maybe<Array<TimeOpenClosed>>;
    thursday?: Maybe<Array<TimeOpenClosed>>;
    tuesday?: Maybe<Array<TimeOpenClosed>>;
    wednesday?: Maybe<Array<TimeOpenClosed>>;
};

export type StadswandelingDenBurgNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'StadswandelingDenBurgNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        wilJeOnzeNieuwsbriefOokOntvangen?: Maybe<Scalars['String']>;
    };

export type StadswandelingDenBurgNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type StadswandelingDenBurgNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type StadswandelingDenBurgNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type StadswandelingDenBurgNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type StadswandelingVeldnamenEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'StadswandelingVeldnamenEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type StadswandelingVeldnamenEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type StadswandelingVeldnamenEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type StadswandelingVeldnamenEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type StadswandelingVeldnamenEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export enum SurchargeCalculationEnum {
    PerPerson = 'PER_PERSON',
    PerPersonPerNight = 'PER_PERSON_PER_NIGHT',
    PerPet = 'PER_PET',
    PerPetPerNight = 'PER_PET_PER_NIGHT',
    PerPiece = 'PER_PIECE',
    PerPiecePerNight = 'PER_PIECE_PER_NIGHT',
    Subsequent = 'SUBSEQUENT',
}

/** This is the interface implemented by all tags. */
export type TagInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The handle of the group that contains the tag. */
    groupHandle: Scalars['String'];
    /** The ID of the group that contains the tag. */
    groupId: Scalars['Int'];
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all tags. */
export type TagInterface_CountArgs = {
    field: Scalars['String'];
};

export type TagRelationCriteriaInput = {
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Determines which site(s) the elements should be queried in, based on their language. */
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to elements that do not relate to the provided element IDs. */
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    /** Narrows the relations based on the field they were created in. */
    relatedViaField?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the relations based on the site they were created in. */
    relatedViaSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TargetAudienceAggregationCount = {
    __typename?: 'TargetAudienceAggregationCount';
    hits: Scalars['Int'];
    value: TargetAudienceEnum;
};

/**   Available target audience options. */
export enum TargetAudienceEnum {
    Adults = 'ADULTS',
    AllAges = 'ALL_AGES',
    Children = 'CHILDREN',
    Pets = 'PETS',
    WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

export type TexelAgendaEvent_Form = ElementInterface &
    FormInterface & {
        __typename?: 'TexelAgendaEvent_Form';
        Vraag?: Maybe<Scalars['String']>;
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type TexelAgendaEvent_Form_CountArgs = {
    field: Scalars['String'];
};

export type TexelAgendaEvent_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelAgendaEvent_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelAgendaEvent_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type TexelReisefuhrerherunterladenFamilie_Form = ElementInterface &
    FormInterface & {
        __typename?: 'TexelReisefuhrerherunterladenFamilie_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nieuwsbrief?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type TexelReisefuhrerherunterladenFamilie_Form_CountArgs = {
    field: Scalars['String'];
};

export type TexelReisefuhrerherunterladenFamilie_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelReisefuhrerherunterladenFamilie_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelReisefuhrerherunterladenFamilie_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type TexelReisefuhrerherunterladen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'TexelReisefuhrerherunterladen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nieuwsbrief?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type TexelReisefuhrerherunterladen_Form_CountArgs = {
    field: Scalars['String'];
};

export type TexelReisefuhrerherunterladen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelReisefuhrerherunterladen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelReisefuhrerherunterladen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Ticket = OrderItem & {
    __typename?: 'Ticket';
    activity?: Maybe<Activity>;
    downloadVoucherUrl?: Maybe<Scalars['String']>;
    endDateTime: Scalars['String'];
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    rateLines: Array<TicketRateLine>;
    startDateTime: Scalars['String'];
    status: TicketStatus;
    timeslot?: Maybe<Timeslot>;
    totalPrice: Scalars['Float'];
};

export type TicketCartItemInput = {
    rateLines: Array<TicketRateLineInput>;
    timeslotId: Scalars['ID'];
};

export type TicketRateLine = {
    __typename?: 'TicketRateLine';
    amount: Scalars['Int'];
    rateId?: Maybe<Scalars['ID']>;
    totalPrice: Scalars['Float'];
    unitPrice: Scalars['Float'];
};

export type TicketRateLineInput = {
    amount: Scalars['Int'];
    rateId: Scalars['ID'];
};

export enum TicketStatus {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

export type TimeOpenClosed = {
    __typename?: 'TimeOpenClosed';
    timeClosed: Scalars['String'];
    timeOpen: Scalars['String'];
};

export type Timeslot = {
    __typename?: 'Timeslot';
    /**   If `null`, unlimited tickets are available */
    allotment?: Maybe<Scalars['Int']>;
    /**   Formatted as yyyy-mm-dd HH:MM */
    endDateTime: Scalars['String'];
    id: Scalars['ID'];
    label?: Maybe<Scalars['String']>;
    rateGroup?: Maybe<ActivityRateGroup>;
    /**   Formatted as yyyy-mm-dd HH:MM */
    startDateTime: Scalars['String'];
};

export type TravelGroupInput = {
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
};

export enum TravelGroupType {
    Alone = 'ALONE',
    BusinessTrip = 'BUSINESS_TRIP',
    FamilyWithOlderChildren = 'FAMILY_WITH_OLDER_CHILDREN',
    FamilyWithYoungChildren = 'FAMILY_WITH_YOUNG_CHILDREN',
    GroupOfFriends = 'GROUP_OF_FRIENDS',
    GroupTrip = 'GROUP_TRIP',
    OlderCouple = 'OLDER_COUPLE',
    YoungCouple = 'YOUNG_COUPLE',
}

export type TravelbaseObjectsSitemapType = {
    __typename?: 'TravelbaseObjectsSitemapType';
    /** The document's image */
    image?: Maybe<SearchImage>;
    /** The document's url */
    url?: Maybe<Scalars['String']>;
};

export type Trip = {
    __typename?: 'Trip';
    date: Scalars['String'];
    duration: Scalars['Int'];
    originalPrice?: Maybe<Scalars['Float']>;
    price: Scalars['Float'];
    rentalUnit: RentalUnit;
    special?: Maybe<Special>;
    specialId?: Maybe<Scalars['ID']>;
    type: TripTypeEnum;
};

export type TripTypeCount = {
    __typename?: 'TripTypeCount';
    hits: Scalars['Int'];
    tripType: TripTypeEnum;
};

/**   Available types of Trip. */
export enum TripTypeEnum {
    /**  Rate including an early booking discount. */
    EarlyBooking = 'EARLY_BOOKING',
    /**  Rate including a last minute discount. */
    LastMinute = 'LAST_MINUTE',
    /**  The regular rate. */
    Regular = 'REGULAR',
    /**  Rate including a Special. */
    Special = 'SPECIAL',
}

export type UnitAndTripSearchParamsInput = {
    babies: Scalars['Int'];
    date: Scalars['String'];
    duration: Scalars['Int'];
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: InputMaybe<RangeFilterInput>;
    specialId?: InputMaybe<Scalars['ID']>;
    tripType?: InputMaybe<Array<TripTypeEnum>>;
};

/**   A property of a RentalUnit or Accommodation which can be used to filter by. */
export type UnitFilterProperty = {
    __typename?: 'UnitFilterProperty';
    /**   A URL-friendly handle unique to this property, this should never change even if the name changes. */
    handle: Scalars['String'];
    /**
     *   Name of this property.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

/**   A category of FilterProperties. */
export type UnitFilterPropertyCategory = {
    __typename?: 'UnitFilterPropertyCategory';
    /**   The properties in this category. */
    filterProperties: Array<UnitFilterProperty>;
    /**   A URL-friendly handle unique to this category, this should never change even if the name changes. */
    handle: Scalars['String'];
    /**
     *   Name of this category.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type UnitSearchHit = {
    __typename?: 'UnitSearchHit';
    rentalUnit: RentalUnit;
    trips: Array<Trip>;
};

/**   RentalUnit/Trip search result ordering options. */
export enum UnitSearchOrderEnum {
    /**  Sort by Trip price, ascending. */
    PriceAsc = 'PRICE_ASC',
    /**  Sort by Trip price, descending. */
    PriceDesc = 'PRICE_DESC',
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    RandomBoosted = 'RANDOM_BOOSTED',
    /**  Sort by amount of recent bookings. */
    RecentBookingsBoosted = 'RECENT_BOOKINGS_BOOSTED',
    /**  Sort by average RentalUnit review rating, descending. */
    ReviewRatingDesc = 'REVIEW_RATING_DESC',
}

/**   Parameters for searching within RentalUnits (optionally filtered by specific Trip availability). */
export type UnitSearchParamsInput = {
    /**   The amount of babies in the travel party. */
    babies?: InputMaybe<Scalars['Int']>;
    /**
     *   Filter by this arrival date.
     *  Formatted as yyyy-mm-dd.
     */
    date?: InputMaybe<Scalars['String']>;
    /**   Filter by this Trip duration. */
    duration?: InputMaybe<Scalars['Int']>;
    /**
     *   Filter by properties the RentalUnit/Accommodation must posses.
     *  Multiple filters are combined as "AND".
     */
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    /**   The amount of non-baby people in the travel party. */
    persons?: InputMaybe<Scalars['Int']>;
    /**   The amount of pets in the travel party. */
    pets?: InputMaybe<Scalars['Int']>;
    /**   Filter by a price range. */
    price?: InputMaybe<RangeFilterInput>;
    /**   Filter by a specific Special (by ID). */
    specialId?: InputMaybe<Scalars['ID']>;
    /**   Filter by this/these TripType(s). */
    tripType?: InputMaybe<Array<TripTypeEnum>>;
};

/**   Results of a RentalUnit/Trip search query. */
export type UnitSearchResult = {
    __typename?: 'UnitSearchResult';
    /**   The search results. */
    hits: Array<UnitSearchHit>;
    /**   The maximum Trip price within the result set. */
    maxPrice?: Maybe<Scalars['Float']>;
    /**   The minimum Trip price within the result set. */
    minPrice?: Maybe<Scalars['Float']>;
    /**   Available filter properties and corresponding result counts within the result set. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   Available Specials and corresponding counts within the result set. */
    specialCounts: Array<SpecialCount>;
    /**   Total amount of matching RentalUnits. */
    totalHits: Scalars['Int'];
    /**   Available TripTypes and corresponding counts within the result set. */
    tripTypeCounts: Array<TripTypeCount>;
};

export type UpdateCartInput = {
    affiliateInfo?: InputMaybe<AffiliateInfoInput>;
    agreedToTerms?: InputMaybe<Scalars['Boolean']>;
    bookings?: InputMaybe<Array<BookingCartItemInput>>;
    cartId?: InputMaybe<Scalars['ID']>;
    customerInfo?: InputMaybe<CustomerInfoInput>;
    paymentOptions?: InputMaybe<UpdateCartPaymentOptionsInput>;
    tickets?: InputMaybe<Array<TicketCartItemInput>>;
    upsellPurchases?: InputMaybe<Array<UpsellPurchaseCartItemInput>>;
};

export type UpdateCartPayload = {
    __typename?: 'UpdateCartPayload';
    cart?: Maybe<Cart>;
    /**   Indicates whether the current user (if any) is allowed to perform the checkout action for the submitted customer email address. */
    checkoutAllowed?: Maybe<Scalars['Boolean']>;
};

export type UpdateCartPaymentOptionsInput = {
    /**   Method "POINT_OF_SALE" only, this must be supplied and be stored in the PaymentTerminal table */
    sellDeviceId?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
    address?: InputMaybe<AddressInput>;
    /**   Formatted as yyyy-mm-dd */
    birthdate?: InputMaybe<Scalars['String']>;
    customerId: Scalars['ID'];
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerPayload = {
    __typename?: 'UpdateCustomerPayload';
    customer?: Maybe<Customer>;
};

export type UpsellOffer = {
    __typename?: 'UpsellOffer';
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    image?: Maybe<GenericImage>;
    maxAmount: Scalars['Int'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    unitPrice: Scalars['Float'];
};

export type UpsellPurchase = OrderItem & {
    __typename?: 'UpsellPurchase';
    amount: Scalars['Int'];
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    offer?: Maybe<UpsellOffer>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    status: UpsellPurchaseStatus;
    totalPrice: Scalars['Float'];
};

export type UpsellPurchaseCartItemInput = {
    amount: Scalars['Int'];
    upsellOfferId: Scalars['ID'];
};

export enum UpsellPurchaseStatus {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

export type User = ElementInterface &
    UserInterface & {
        __typename?: 'User';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The user’s addresses. */
        addresses?: Maybe<Array<Maybe<AddressInterface>>>;
        /** The handle of the site the user is affiliated with. */
        affiliatedSiteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the user is affiliated with. */
        affiliatedSiteId?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The user’s email. */
        email?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The user’s first name. */
        firstName?: Maybe<Scalars['String']>;
        /** The user’s first name or username. */
        friendlyName?: Maybe<Scalars['String']>;
        /** The user’s full name. */
        fullName?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The user’s last name. */
        lastName?: Maybe<Scalars['String']>;
        /** The user’s full name or username. */
        name: Scalars['String'];
        /** The user’s photo. */
        photo?: Maybe<AssetInterface>;
        /** The user’s preferences. */
        preferences: Scalars['String'];
        /** The user’s preferred language. */
        preferredLanguage?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The username. */
        username?: Maybe<Scalars['String']>;
    };

export type User_CountArgs = {
    field: Scalars['String'];
};

export type UserAddressesArgs = {
    administrativeArea?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    countryCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all users. */
export type UserInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** The user’s addresses. */
    addresses?: Maybe<Array<Maybe<AddressInterface>>>;
    /** The handle of the site the user is affiliated with. */
    affiliatedSiteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the user is affiliated with. */
    affiliatedSiteId?: Maybe<Scalars['Int']>;
    /** Whether the element is archived. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** The user’s email. */
    email?: Maybe<Scalars['String']>;
    /** Whether the element is enabled. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The user’s first name. */
    firstName?: Maybe<Scalars['String']>;
    /** The user’s first name or username. */
    friendlyName?: Maybe<Scalars['String']>;
    /** The user’s full name. */
    fullName?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The user’s last name. */
    lastName?: Maybe<Scalars['String']>;
    /** The user’s full name or username. */
    name: Scalars['String'];
    /** The user’s photo. */
    photo?: Maybe<AssetInterface>;
    /** The user’s preferences. */
    preferences: Scalars['String'];
    /** The user’s preferred language. */
    preferredLanguage?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['Int']>;
    /** The handle of the site the element is associated with. */
    siteHandle?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The username. */
    username?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all users. */
export type UserInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all users. */
export type UserInterfaceAddressesArgs = {
    administrativeArea?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    archived?: InputMaybe<Scalars['Boolean']>;
    countryCode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserRelationCriteriaInput = {
    /** Determines which site(s) the users should be affiliated with. */
    affiliatedSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the users should be affiliated with. */
    affiliatedSiteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the users have uploaded any assets. */
    assetUploaders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the users are listed as the author of any entries. */
    authors?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ email addresses. */
    email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ first names. */
    firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ full names. */
    fullName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the users belong to. */
    group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only users that have (or don’t have) a user photo. */
    hasPhoto?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Determines which site(s) the elements should be queried in, based on their language. */
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ last names. */
    lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to elements that do not relate to the provided element IDs. */
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    /** Narrows the relations based on the field they were created in. */
    relatedViaField?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the relations based on the site they were created in. */
    relatedViaSite?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ usernames. */
    username?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WinactieMeetandGreetMaksWavesFestival_Form = ElementInterface &
    FormInterface & {
        __typename?: 'WinactieMeetandGreetMaksWAVESFestival_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieMeetandGreetMaksWavesFestival_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieMeetandGreetMaksWavesFestival_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieMeetandGreetMaksWavesFestival_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieMeetandGreetMaksWavesFestival_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelblues2024En_Form = ElementInterface &
    FormInterface & {
        __typename?: 'WinactieTexelblues2024EN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        doYouAgreeToTheTermsAndConditions?: Maybe<Scalars['String']>;
        emailAddress?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        firstName?: Maybe<Scalars['String']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        lastName?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieTexelblues2024En_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelblues2024En_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2024En_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2024En_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanmeldenTexelApp2_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanmeldenTexelApp2_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        aanbieding?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedrijfsnaam?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        contactpersoon?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        looptijd1?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadEenBijpassendeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AanmeldenTexelApp2_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanmeldenTexelApp2_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTexelApp2_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTexelApp2_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanmeldenTexelApp2_FormUploadEenBijpassendeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AanmeldenTexelApp3_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanmeldenTexelApp3_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        aanbieding?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedrijfsnaam?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        contactpersoon?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        looptijd?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AanmeldenTexelApp3_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanmeldenTexelApp3_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTexelApp3_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTexelApp3_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanmeldenTexelApp_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanmeldenTexelApp_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        aanbieding?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedrijfsnaam?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        contactpersoon?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        looptijd1?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadEenBijpassendeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AanmeldenTexelApp_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanmeldenTexelApp_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTexelApp_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTexelApp_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanmeldenTexelApp_FormUploadEenBijpassendeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AanmeldenTopLedenVoorFotos_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanmeldenTopLedenVoorFotos_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedrijfsnaam?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeveelAccommodatiesA85WilJeLatenFotograferen?: Maybe<Scalars['Number']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikWilFotosVan?: Maybe<Array<Maybe<Scalars['String']>>>;
        ikWilGebruikMakenVan?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        locatieadresVoorDeFotoshoot?: Maybe<Scalars['String']>;
        naamContactpersoon?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        telefoonnummerContactpersoon?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichting?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorkeurVoorFotograaf?: Maybe<Array<Maybe<Scalars['String']>>>;
    };

export type AanmeldenTopLedenVoorFotos_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanmeldenTopLedenVoorFotos_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTopLedenVoorFotos_FormIkWilFotosVanArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTopLedenVoorFotos_FormIkWilGebruikMakenVanArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTopLedenVoorFotos_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenTopLedenVoorFotos_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanmeldenTopLedenVoorFotos_FormVoorkeurVoorFotograafArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenVvv125Jaar_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanmeldenVvv125Jaar_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        aantalPersonen?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedrijfIndienVanToepassing?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        telefoonnummer?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AanmeldenVvv125Jaar_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanmeldenVvv125Jaar_FormAantalPersonenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenVvv125Jaar_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenVvv125Jaar_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanmeldenVvv125Jaar_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type AanvraagFietsrouteAchterDeRugediekEnMeerDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        wilJeOnzeNieuwsbriefOokOntvangen?: Maybe<Scalars['String']>;
    };

export type AanvraagFietsrouteAchterDeRugediekEnMeerNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeerNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeer_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AanvraagFietsrouteAchterDeRugediekEnMeer_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagFietsrouteAchterDeRugediekEnMeer_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeer_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagFietsrouteAchterDeRugediekEnMeer_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteAvontuurlijkeZuidenDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteAvontuurlijkeZuidenDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteAvontuurlijkeZuidenDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteAvontuurlijkeZuidenDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteAvontuurlijkeZuidenDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteDurperrondjeDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteDurperrondjeDU_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteDurperrondjeDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteDurperrondjeDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteDurperrondjeDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteDurperrondjeDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteEierlandseDuinenDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteEierlandseDuinenDU_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteEierlandseDuinenDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteEierlandseDuinenDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteEierlandseDuinenDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteEierlandseDuinenDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteKrimbosEnHanenpadDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteKrimbosEnHanenpadDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteKrimbosEnHanenpadEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteKrimbosEnHanenpadEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteKrimbosEnHanenpadNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteKrimbosEnHanenpadNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteKrimbosEnHanenpadNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteRondomDeKopVanTexelDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteRondomDeKopVanTexelDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteRondomDeKopVanTexelDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteRondomDeKopVanTexelDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteRondomDeKopVanTexelDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteRondomDeKopVanTexelNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        wilJeOnzeNieuwsbriefOntvangen?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteRondomDeKopVanTexelNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteRondomDeKopVanTexelNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteRondomDeKopVanTexelNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteRondomDeKopVanTexelNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteStadswandelingNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteStadswandelingNl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteStadswandelingNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteStadswandelingNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteStadswandelingNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteStadswandelingNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AanvraagWandelrouteveldnamenrouteDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagWandelrouteveldnamenrouteDU_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type AanvraagWandelrouteveldnamenrouteDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type AanvraagWandelrouteveldnamenrouteDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteveldnamenrouteDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type AanvraagWandelrouteveldnamenrouteDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Aanvraagwerkstukpakket_Form = ElementInterface &
    FormInterface & {
        __typename?: 'aanvraagwerkstukpakket_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Aanvraagwerkstukpakket_Form_CountArgs = {
    field: Scalars['String'];
};

export type Aanvraagwerkstukpakket_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Aanvraagwerkstukpakket_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Aanvraagwerkstukpakket_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type ActionSectionEntryUnion = Action_Entry;

export type Action_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'action_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        button: Array<Maybe<HyperLinkInterface>>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        description?: Maybe<Scalars['String']>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Action_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Action_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Activiteitaanmelden_Form = ElementInterface &
    FormInterface & {
        __typename?: 'activiteitaanmelden_Form';
        Bedrijfsnaam?: Maybe<Scalars['String']>;
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        contactgegevens?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naamVanActiviteitEvenement?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        overDeActiviteit?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        plaatsVanActiviteitEvenement?: Maybe<Scalars['String']>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Activiteitaanmelden_Form_CountArgs = {
    field: Scalars['String'];
};

export type Activiteitaanmelden_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Activiteitaanmelden_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Activiteitaanmelden_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender2_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender2_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender2_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender2_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender2_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender2_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender2_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender4_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender4_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender4_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender4_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender4_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender4_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender4_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender5_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender5_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender5_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender5_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender5_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender5_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender5_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender9_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender9_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender9_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender9_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender9_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender9_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender9_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender14_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender14_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender14_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender14_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender14_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender14_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender14_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender15_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender15_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender15_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender15_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender15_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender15_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender15_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender16_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender16_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender16_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender16_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender16_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender16_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender16_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender18_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender18_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender18_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender18_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender18_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender18_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender18_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender19_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender19_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender19_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender19_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender19_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender19_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender19_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender20_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender20_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender20_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender20_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender20_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender20_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender20_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender21_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender21_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender21_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender21_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender21_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender21_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender21_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender22_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender22_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender22_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender22_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender22_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender22_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender22_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender23_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender23_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender23_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender23_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender23_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender23_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender23_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Adventskalender24_Form = ElementInterface &
    FormInterface & {
        __typename?: 'adventskalender24_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikGaAkkoordMetDeAlgemeneVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Adventskalender24_Form_CountArgs = {
    field: Scalars['String'];
};

export type Adventskalender24_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender24_FormIkGaAkkoordMetDeAlgemeneVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender24_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Adventskalender24_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type AgendaSectionEntryUnion = Agenda_Entry;

export type Agenda_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'agenda_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Agenda_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Agenda_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Article_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'article_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        legacyId?: Maybe<Scalars['String']>;
        legacyUrl?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        newsHeroImage: Array<Maybe<AssetInterface>>;
        newsImages: Array<Maybe<AssetInterface>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        textBody?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Article_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Article_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryNewsHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Article_EntryNewsImagesArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Article_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Article_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticlesSectionEntryUnion = Article_Entry;

export type AssetTags_Tag = ElementInterface &
    TagInterface & {
        __typename?: 'assetTags_Tag';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the group that contains the tag. */
        groupHandle: Scalars['String'];
        /** The ID of the group that contains the tag. */
        groupId: Scalars['Int'];
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AssetTags_Tag_CountArgs = {
    field: Scalars['String'];
};

export type Avontuurlijkezuidennl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'avontuurlijkezuidennl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Avontuurlijkezuidennl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Avontuurlijkezuidennl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Avontuurlijkezuidennl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Avontuurlijkezuidennl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Banner_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'banner_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        button: Array<Maybe<HyperLinkInterface>>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        heroImage: Array<Maybe<AssetInterface>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Banner_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Banner_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Banner_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banner_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type BannersSectionEntryUnion = Banner_Entry;

export type Button_Asset_LinkType = HyperLinkInterface & {
    __typename?: 'button_Asset_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Category_LinkType = HyperLinkInterface & {
    __typename?: 'button_Category_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Custom_LinkType = HyperLinkInterface & {
    __typename?: 'button_Custom_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Email_LinkType = HyperLinkInterface & {
    __typename?: 'button_Email_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Embed_LinkType = HyperLinkInterface & {
    __typename?: 'button_Embed_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Entry_LinkType = HyperLinkInterface & {
    __typename?: 'button_Entry_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Phone_LinkType = HyperLinkInterface & {
    __typename?: 'button_Phone_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Site_LinkType = HyperLinkInterface & {
    __typename?: 'button_Site_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_Url_LinkType = HyperLinkInterface & {
    __typename?: 'button_Url_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type Button_User_LinkType = HyperLinkInterface & {
    __typename?: 'button_User_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type CompaniesSectionEntryUnion = Companies_Entry;

export type Companies_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'companies_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Companies_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Companies_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact2_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'contact2_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        email?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        messenger?: Maybe<Scalars['Boolean']>;
        myTexel?: Maybe<Scalars['Boolean']>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        phone?: Maybe<Scalars['Boolean']>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        shop?: Maybe<Scalars['Boolean']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        whatsapp?: Maybe<Scalars['Boolean']>;
    };

export type Contact2_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Contact2_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact2_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ContactMatrix_MatrixField = Contact2_Entry;

export type ContactSectionEntryUnion = Contact_Entry;

export type Contact_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'contact_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        button: Array<Maybe<HyperLinkInterface>>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        contactMatrix: Array<Maybe<ContactMatrix_MatrixField>>;
        contentMatrix: Array<Maybe<ContentMatrix_MatrixField>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        heroImage: Array<Maybe<AssetInterface>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        textBody?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Contact_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Contact_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryContactMatrixArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    email?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    messenger?: InputMaybe<Scalars['Boolean']>;
    myTexel?: InputMaybe<Scalars['Boolean']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    phone?: InputMaybe<Scalars['Boolean']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    shop?: InputMaybe<Scalars['Boolean']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    whatsapp?: InputMaybe<Scalars['Boolean']>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryContentMatrixArgs = {
    accommodationsQuery?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    activitiesQuery?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    autoplay?: InputMaybe<Scalars['Boolean']>;
    banner?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    companiesQuery?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    embed?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    form?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    images?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    pins?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    relationType?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    text?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tilesChildren?: InputMaybe<Scalars['Boolean']>;
    tilesEntries?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tilesSiblings?: InputMaybe<Scalars['Boolean']>;
    tip?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    travelbaseEntityType?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    travelbaseSlugs?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    videoUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    webcamUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_GlobalSet = ElementInterface &
    GlobalSetInterface & {
        __typename?: 'contact_GlobalSet';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        contactEmailText?: Maybe<Scalars['String']>;
        contactMessengerText?: Maybe<Scalars['String']>;
        contactMyTexelText?: Maybe<Scalars['String']>;
        contactPhoneText?: Maybe<Scalars['String']>;
        contactShopText?: Maybe<Scalars['String']>;
        contactWhatsappText?: Maybe<Scalars['String']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the global set. */
        handle: Scalars['String'];
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The name of the global set. */
        name: Scalars['String'];
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Contact_GlobalSet_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_MatrixField =
    | SectionAccommodations_Entry
    | SectionAccordion_Entry
    | SectionAction_Entry
    | SectionActivities_Entry
    | SectionBanner_Entry
    | SectionButton_Entry
    | SectionCompanies_Entry
    | SectionEmbed_Entry
    | SectionForms_Entry
    | SectionHeader_Entry
    | SectionHierarchicalTiles_Entry
    | SectionImages_Entry
    | SectionMap_Entry
    | SectionText_Entry
    | SectionTiles_Entry
    | SectionTip_Entry
    | SectionTravelbaseTiles_Entry
    | SectionVideo_Entry
    | SectionWebcam_Entry;

export type DataVerwijderenDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'dataVerwijderenDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type DataVerwijderenDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type DataVerwijderenDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DataVerwijderenDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DataVerwijderenDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type DataVerwijderenEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'dataVerwijderenEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type DataVerwijderenEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type DataVerwijderenEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DataVerwijderenEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DataVerwijderenEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type DataVerwijderenNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'dataVerwijderenNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type DataVerwijderenNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type DataVerwijderenNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DataVerwijderenNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DataVerwijderenNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type DriveInCinema2024Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'driveInCinema2024NL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type DriveInCinema2024Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type DriveInCinema2024Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DriveInCinema2024Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DriveInCinema2024Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type DriveInCinemaNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'driveInCinemaNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type DriveInCinemaNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type DriveInCinemaNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DriveInCinemaNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type DriveInCinemaNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Durperrondjenl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'durperrondjenl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Durperrondjenl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Durperrondjenl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Durperrondjenl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Durperrondjenl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Eierlandseduinennl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'eierlandseduinennl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Eierlandseduinennl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Eierlandseduinennl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Eierlandseduinennl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Eierlandseduinennl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Evenementenkalender2024De_Form = ElementInterface &
    FormInterface & {
        __typename?: 'evenementenkalender2024DE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Evenementenkalender2024De_Form_CountArgs = {
    field: Scalars['String'];
};

export type Evenementenkalender2024De_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Evenementenkalender2024De_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Evenementenkalender2024De_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Evenementenkalender2024En_Form = ElementInterface &
    FormInterface & {
        __typename?: 'evenementenkalender2024EN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Evenementenkalender2024En_Form_CountArgs = {
    field: Scalars['String'];
};

export type Evenementenkalender2024En_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Evenementenkalender2024En_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Evenementenkalender2024En_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type EvenementenkalenderDownloadNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'evenementenkalenderDownloadNl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nieuwsbrief?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type EvenementenkalenderDownloadNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type EvenementenkalenderDownloadNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type EvenementenkalenderDownloadNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type EvenementenkalenderDownloadNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type EvenementenkalenderNLoud_Form = ElementInterface &
    FormInterface & {
        __typename?: 'evenementenkalenderNLoud_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type EvenementenkalenderNLoud_Form_CountArgs = {
    field: Scalars['String'];
};

export type EvenementenkalenderNLoud_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type EvenementenkalenderNLoud_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type EvenementenkalenderNLoud_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FietsroutethijsserouteDe2_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fietsroutethijsserouteDE2_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type FietsroutethijsserouteDe2_Form_CountArgs = {
    field: Scalars['String'];
};

export type FietsroutethijsserouteDe2_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FietsroutethijsserouteDe2_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FietsroutethijsserouteDe2_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FietsroutethijsserouteNl2_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fietsroutethijsserouteNL2_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        wilJeOnzeNieuwsbriefOokOntvangen?: Maybe<Scalars['String']>;
    };

export type FietsroutethijsserouteNl2_Form_CountArgs = {
    field: Scalars['String'];
};

export type FietsroutethijsserouteNl2_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FietsroutethijsserouteNl2_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FietsroutethijsserouteNl2_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fietsroutethijsseroutedu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fietsroutethijsseroutedu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Fietsroutethijsseroutedu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fietsroutethijsseroutedu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fietsroutethijsseroutedu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fietsroutethijsseroutedu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fietsroutethijsseroutenl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fietsroutethijsseroutenl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        wilJeOnzeNieuwsbriefOokOntvangen?: Maybe<Scalars['String']>;
    };

export type Fietsroutethijsseroutenl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fietsroutethijsseroutenl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fietsroutethijsseroutenl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fietsroutethijsseroutenl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fleeceplaidde_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fleeceplaidde_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Fleeceplaidde_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fleeceplaidde_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaidde_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaidde_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaidde_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fleeceplaideng_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fleeceplaideng_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Fleeceplaideng_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fleeceplaideng_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaideng_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaideng_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaideng_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fleeceplaidnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fleeceplaidnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Fleeceplaidnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fleeceplaidnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaidnl_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaidnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fleeceplaidnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FooterCategory_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'footerCategory_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type FooterCategory_Entry_CountArgs = {
    field: Scalars['String'];
};

export type FooterCategory_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterCategory_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'footerLink_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        menuLink: Array<Maybe<HyperLinkInterface>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type FooterLink_Entry_CountArgs = {
    field: Scalars['String'];
};

export type FooterLink_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterLink_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type FooterSectionEntryUnion = FooterCategory_Entry | FooterLink_Entry;

export type FormulierBuienradar_Form = ElementInterface &
    FormInterface & {
        __typename?: 'formulierBuienradar_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type FormulierBuienradar_Form_CountArgs = {
    field: Scalars['String'];
};

export type FormulierBuienradar_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FormulierBuienradar_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FormulierBuienradar_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotoVanDeMaandD_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotoVanDeMaandD_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichting?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadHierJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotoVanDeMaandD_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotoVanDeMaandD_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotoVanDeMaandD_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotoVanDeMaandD_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotoVanDeMaandD_FormUploadHierJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotoVanDeMaandEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotoVanDeMaandEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichting?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadHierJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotoVanDeMaandEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotoVanDeMaandEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotoVanDeMaandEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotoVanDeMaandEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotoVanDeMaandEn_FormUploadHierJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotoVanDeMaandNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotoVanDeMaandNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichting?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadHierJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotoVanDeMaandNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotoVanDeMaandNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotoVanDeMaandNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotoVanDeMaandNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotoVanDeMaandNl_FormUploadHierJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdTexelseLuchtenDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdTexelseLuchtenDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdTexelseLuchtenDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdTexelseLuchtenDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdTexelseLuchtenDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdTexelseLuchtenDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdTexelseLuchtenDe_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdTexelseLuchtenEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdTexelseLuchtenEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdTexelseLuchtenEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdTexelseLuchtenEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdTexelseLuchtenEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdTexelseLuchtenEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdTexelseLuchtenEn_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdTexelseLuchtenNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdTexelseLuchtenNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkeLocatieIsDeFotoGenomen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadJeFoto: Array<Maybe<AssetInterface>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdTexelseLuchtenNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdTexelseLuchtenNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdTexelseLuchtenNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdTexelseLuchtenNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FotowedstrijdTexelseLuchtenNl_FormUploadJeFotoArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdlammetjesEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdlammetjesEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        naamVanDeFotolocatie?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type FotowedstrijdlammetjesEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type FotowedstrijdlammetjesEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdlammetjesEn_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FotowedstrijdlammetjesEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type FotowedstrijdlammetjesEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fotowedstrijdlammetjesdu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdlammetjesdu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        naamVanDeFotolocatie?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Fotowedstrijdlammetjesdu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fotowedstrijdlammetjesdu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdlammetjesdu_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Fotowedstrijdlammetjesdu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdlammetjesdu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fotowedstrijdlammetjesnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdlammetjesnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        naamVanDeFotolocatie?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Fotowedstrijdlammetjesnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fotowedstrijdlammetjesnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdlammetjesnl_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Fotowedstrijdlammetjesnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdlammetjesnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fotowedstrijdnatuur_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdnatuur_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        naamVanDeFotolocatie?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
        wilJeOnzeNieuwsbriefOntvangen?: Maybe<Scalars['String']>;
    };

export type Fotowedstrijdnatuur_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fotowedstrijdnatuur_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdnatuur_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Fotowedstrijdnatuur_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdnatuur_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Fotowedstrijdnatuurdu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'fotowedstrijdnatuurdu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        naamVanDeFotolocatie?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Fotowedstrijdnatuurdu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Fotowedstrijdnatuurdu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdnatuurdu_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Fotowedstrijdnatuurdu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Fotowedstrijdnatuurdu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type GewinnspielTexelblues2023_Form = ElementInterface &
    FormInterface & {
        __typename?: 'gewinnspielTexelblues2023_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nachname1?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        sindSieMitDenBedingungenEindverstanden?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname1?: Maybe<Scalars['String']>;
    };

export type GewinnspielTexelblues2023_Form_CountArgs = {
    field: Scalars['String'];
};

export type GewinnspielTexelblues2023_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type GewinnspielTexelblues2023_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type GewinnspielTexelblues2023_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Grotegrazersnl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'grotegrazersnl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Grotegrazersnl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Grotegrazersnl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Grotegrazersnl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Grotegrazersnl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type HomepageSectionEntryUnion = Homepage_Entry;

export type Homepage_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'homepage_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        action: Array<Maybe<EntryInterface>>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        button: Array<Maybe<HyperLinkInterface>>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        header?: Maybe<Scalars['String']>;
        heroImage: Array<Maybe<AssetInterface>>;
        homeCurrent: Array<Maybe<EntryInterface>>;
        homeHighlights: Array<Maybe<EntryInterface>>;
        homeHighlightsTitle?: Maybe<Scalars['String']>;
        homeVideoDescription?: Maybe<Scalars['String']>;
        homeVideoEnabled?: Maybe<Scalars['Boolean']>;
        homeVideoTitle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        video?: Maybe<Scalars['String']>;
    };

export type Homepage_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Homepage_EntryActionArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Homepage_EntryHomeCurrentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryHomeHighlightsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Kikkertnl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kikkertnl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kikkertnl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kikkertnl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kikkertnl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kikkertnl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kinderroutenl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kinderroutenl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kinderroutenl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kinderroutenl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kinderroutenl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kinderroutenl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type KlachtformulierDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'klachtformulierDu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        buchungsnummerVonVvvTexel?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        meineBeschwerdeFeedback?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        sollenwirdieBeschwerdemitdemVermieterbesprechen?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vornamen?: Maybe<Scalars['String']>;
    };

export type KlachtformulierDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type KlachtformulierDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type KlachtformulierDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type KlachtformulierDu_FormSollenwirdieBeschwerdemitdemVermieterbesprechenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type KlachtformulierDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Klachtformuliernl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'klachtformuliernl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        boekingsnummerVanVvvTexel?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mijnKlachtFeedback?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        wiltUDatWijDeKlachtMetDeVerhuurderBespreken?: Maybe<Scalars['String']>;
    };

export type Klachtformuliernl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Klachtformuliernl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Klachtformuliernl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Klachtformuliernl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Klachtformuliernl_FormWiltUDatWijDeKlachtMetDeVerhuurderBesprekenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschaapjesdu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatschaapjesdu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatschaapjesdu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatschaapjesdu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschaapjesdu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschaapjesdu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatschaapjesen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatschaapjesen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatschaapjesen_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatschaapjesen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschaapjesen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschaapjesen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatschapennl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatschapennl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatschapennl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatschapennl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschapennl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatschapennl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatstranddu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatstranddu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatstranddu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatstranddu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatstranddu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatstranddu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatstranden_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatstranden_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatstranden_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatstranden_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatstranden_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatstranden_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatstrandnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatstrandnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatstrandnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatstrandnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatstrandnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatstrandnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatvuurtorendu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatvuurtorendu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatvuurtorendu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatvuurtorendu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatvuurtorendu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatvuurtorendu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatvuurtorenen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatvuurtorenen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatvuurtorenen_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatvuurtorenen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatvuurtorenen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatvuurtorenen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatvuurtorennl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatvuurtorennl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatvuurtorennl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatvuurtorennl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatvuurtorennl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatvuurtorennl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatzeehonddu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatzeehonddu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatzeehonddu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatzeehonddu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatzeehonddu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatzeehonddu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatzeehonden_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatzeehonden_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatzeehonden_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatzeehonden_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatzeehonden_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatzeehonden_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Kleurplaatzeehondnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'kleurplaatzeehondnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Kleurplaatzeehondnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Kleurplaatzeehondnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatzeehondnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Kleurplaatzeehondnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Library_Asset = AssetInterface &
    ElementInterface & {
        __typename?: 'library_Asset';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Alternative text for the asset. */
        alt?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        assetRights?: Maybe<Scalars['String']>;
        assetSource?: Maybe<Scalars['String']>;
        assetTags: Array<Maybe<TagInterface>>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the asset file was last modified. */
        dateModified?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The file extension for the asset file. */
        extension: Scalars['String'];
        /** The filename of the asset file. */
        filename: Scalars['String'];
        /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
        focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
        /** The ID of the folder that the asset belongs to. */
        folderId: Scalars['Int'];
        /** Returns the file’s format. */
        format?: Maybe<Scalars['String']>;
        /** Whether a user-defined focal point is set on the asset. */
        hasFocalPoint: Scalars['Boolean'];
        /** The height in pixels or null if it’s not an image. */
        height?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** An `<img>` tag based on this asset. */
        img?: Maybe<Scalars['String']>;
        /** The file kind. */
        kind: Scalars['String'];
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The file’s MIME type, if it can be determined. */
        mimeType?: Maybe<Scalars['String']>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<AssetInterface>;
        /** The asset’s path in the volume. */
        path: Scalars['String'];
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<AssetInterface>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The file size in bytes. */
        size?: Maybe<Scalars['String']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
        srcset?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
        url?: Maybe<Scalars['String']>;
        /** The ID of the volume that the asset belongs to. */
        volumeId?: Maybe<Scalars['Int']>;
        /** The width in pixels or null if it’s not an image. */
        width?: Maybe<Scalars['Int']>;
    };

export type Library_Asset_CountArgs = {
    field: Scalars['String'];
};

export type Library_AssetAssetRightsArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Library_AssetAssetTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_AssetFormatArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Library_AssetHeightArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Library_AssetNextArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_AssetPrevArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_AssetSrcsetArgs = {
    sizes: Array<Scalars['String']>;
};

export type Library_AssetUrlArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Library_AssetWidthArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Location_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'location_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        locationAddress?: Maybe<Scalars['String']>;
        locationExtra?: Maybe<Scalars['String']>;
        locationLatLon?: Maybe<Ether_Map>;
        locationPlace?: Maybe<Scalars['String']>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Location_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Location_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryLocationPlaceArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Location_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Meeuwenkolonienl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'meeuwenkolonienl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Meeuwenkolonienl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Meeuwenkolonienl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Meeuwenkolonienl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Meeuwenkolonienl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type MenuLink_Asset_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Asset_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Category_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Category_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Custom_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Custom_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Email_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Email_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Embed_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Embed_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Entry_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Entry_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Phone_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Phone_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Site_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Site_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_Url_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_Url_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuLink_User_LinkType = HyperLinkInterface & {
    __typename?: 'menuLink_User_LinkType';
    /** The `aria-label` attribute for the link. */
    ariaLabel?: Maybe<Scalars['String']>;
    /** The `class` attribute for the link. */
    classes?: Maybe<Scalars['String']>;
    /** The custom attributes for the link. */
    customAttributes?: Maybe<Scalars['Array']>;
    /** The element (if provided) for the link. */
    element?: Maybe<ElementInterface>;
    /** Whether the chosen link value is an element. */
    isElement?: Maybe<Scalars['Boolean']>;
    /** Whether a link has been set for the field. */
    isEmpty?: Maybe<Scalars['Boolean']>;
    /** The HTML output for a `<a>` element. */
    link?: Maybe<Scalars['String']>;
    /** The text for the link. */
    linkText?: Maybe<Scalars['String']>;
    /** The uri for the link (if an element-based link). */
    linkUri?: Maybe<Scalars['String']>;
    /** The url for the link. */
    linkUrl?: Maybe<Scalars['String']>;
    /** The raw link data. */
    linkValue?: Maybe<Scalars['String']>;
    /** Whether the link should open in a new window. */
    newWindow?: Maybe<Scalars['Boolean']>;
    /** The `target` attribute for the link. */
    target?: Maybe<Scalars['String']>;
    /** The text for the link. */
    text?: Maybe<Scalars['String']>;
    /** The `title` attribute for the link. */
    title?: Maybe<Scalars['String']>;
    /** The link type. */
    type?: Maybe<Scalars['String']>;
    /** The url for the link. */
    url?: Maybe<Scalars['String']>;
    /** The url prefix for the link. */
    urlPrefix?: Maybe<Scalars['String']>;
    /** The url suffix for the link. */
    urlSuffix?: Maybe<Scalars['String']>;
};

export type MenuSectionEntryUnion = Menu_Entry;

export type Menu_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'menu_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        menuLink: Array<Maybe<HyperLinkInterface>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Menu_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Menu_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden2_Form = ElementInterface &
    FormInterface & {
        __typename?: 'mindervaliden2_Form';
        Kenmerken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        accommdatienummers?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadresVerhuurder?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naamVerhuurder1?: Maybe<Scalars['String']>;
        opmerkingen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Mindervaliden2_FormKenmerkenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden2_Form_CountArgs = {
    field: Scalars['String'];
};

export type Mindervaliden2_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden2_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden2_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Mindervaliden_Form = ElementInterface &
    FormInterface & {
        __typename?: 'mindervaliden_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        accommdatienummers?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        basiskenmerken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadresVerhuurder?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        extraKenmerken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naamVerhuurder1?: Maybe<Scalars['String']>;
        opmerkingen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Mindervaliden_Form_CountArgs = {
    field: Scalars['String'];
};

export type Mindervaliden_FormBasiskenmerkenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden_FormExtraKenmerkenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Mindervaliden_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Muyslufterde2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'muyslufterde2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Muyslufterde2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Muyslufterde2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Muyslufterde2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Muyslufterde2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Muyslufternl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'muyslufternl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Muyslufternl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Muyslufternl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Muyslufternl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Muyslufternl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type MyTexelSectionEntryUnion = MyTexel_Entry;

export type MyTexel_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'myTexel_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type MyTexel_Entry_CountArgs = {
    field: Scalars['String'];
};

export type MyTexel_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsSectionEntryUnion = News_Entry;

export type News_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'news_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        heroImage: Array<Maybe<AssetInterface>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        textBody?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type News_Entry_CountArgs = {
    field: Scalars['String'];
};

export type News_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Nieuwsbriefaanmeldendu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'nieuwsbriefaanmeldendu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Nieuwsbriefaanmeldendu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Nieuwsbriefaanmeldendu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Nieuwsbriefaanmeldendu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Nieuwsbriefaanmeldendu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Nieuwsbriefaanmeldenen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'nieuwsbriefaanmeldenen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Nieuwsbriefaanmeldenen_Form_CountArgs = {
    field: Scalars['String'];
};

export type Nieuwsbriefaanmeldenen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Nieuwsbriefaanmeldenen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Nieuwsbriefaanmeldenen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Nieuwsbriefaanmeldennl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'nieuwsbriefaanmeldennl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Nieuwsbriefaanmeldennl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Nieuwsbriefaanmeldennl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Nieuwsbriefaanmeldennl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Nieuwsbriefaanmeldennl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Notification_GlobalSet = ElementInterface &
    GlobalSetInterface & {
        __typename?: 'notification_GlobalSet';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        active?: Maybe<Scalars['Boolean']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        button: Array<Maybe<HyperLinkInterface>>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        description?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the global set. */
        handle: Scalars['String'];
        header?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The name of the global set. */
        name: Scalars['String'];
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Notification_GlobalSet_CountArgs = {
    field: Scalars['String'];
};

export type Page_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'page_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        contentMatrix: Array<Maybe<ContentMatrix_MatrixField>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        heroImage: Array<Maybe<AssetInterface>>;
        heroImageVisible?: Maybe<Scalars['Boolean']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        legacyId?: Maybe<Scalars['String']>;
        legacyUrl?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMenuHidden?: Maybe<Scalars['Boolean']>;
        settingsMenuTitle?: Maybe<Scalars['String']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsNotSearchable?: Maybe<Scalars['Boolean']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Page_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Page_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryContentMatrixArgs = {
    accommodationsQuery?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    activitiesQuery?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    autoplay?: InputMaybe<Scalars['Boolean']>;
    banner?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    companiesQuery?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    embed?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    form?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    images?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    pins?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    relationType?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    text?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tilesChildren?: InputMaybe<Scalars['Boolean']>;
    tilesEntries?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tilesSiblings?: InputMaybe<Scalars['Boolean']>;
    tip?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    travelbaseEntityType?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    travelbaseSlugs?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    videoUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    webcamUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Page_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Page_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PagesSectionEntryUnion = Page_Entry;

export type PersaanvraagEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'persaanvraagEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naamMedium?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        watIsJeVerzoekOfVraag?: Maybe<Scalars['String']>;
    };

export type PersaanvraagEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type PersaanvraagEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type PersaanvraagEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type PersaanvraagEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Persverzoek_Form = ElementInterface &
    FormInterface & {
        __typename?: 'persverzoek_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naamMedium?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        watIsJeVerzoekOfVraag?: Maybe<Scalars['String']>;
    };

export type Persverzoek_Form_CountArgs = {
    field: Scalars['String'];
};

export type Persverzoek_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Persverzoek_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Persverzoek_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumber = {
    __typename?: 'phoneNumber_PhoneNumber';
    carrierName?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    extension?: Maybe<Scalars['String']>;
    format?: Maybe<Scalars['String']>;
    formatForCountry?: Maybe<Scalars['String']>;
    formatForMobileDialing?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    region?: Maybe<Scalars['String']>;
    regionCode?: Maybe<Scalars['String']>;
    timezones?: Maybe<Array<Maybe<Scalars['String']>>>;
    type?: Maybe<Scalars['Int']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberCarrierNameArgs = {
    locale?: InputMaybe<Scalars['String']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberDescriptionArgs = {
    locale?: InputMaybe<Scalars['String']>;
    region?: InputMaybe<Scalars['String']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberFormatArgs = {
    format?: InputMaybe<Scalars['String']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberFormatForCountryArgs = {
    region?: InputMaybe<Scalars['String']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberFormatForMobileDialingArgs = {
    format?: InputMaybe<Scalars['Boolean']>;
    region?: InputMaybe<Scalars['String']>;
};

export type Pins_TableRow = {
    __typename?: 'pins_TableRow';
    col1?: Maybe<Scalars['Number']>;
    col2?: Maybe<Scalars['Number']>;
    col3?: Maybe<Scalars['String']>;
    col4?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Number']>;
    longitude?: Maybe<Scalars['Number']>;
    symbol?: Maybe<Scalars['String']>;
};

export type Presseanfrage1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'presseanfrage1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naamMedium?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        watIsJeVerzoekOfVraag?: Maybe<Scalars['String']>;
    };

export type Presseanfrage1_Form_CountArgs = {
    field: Scalars['String'];
};

export type Presseanfrage1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Presseanfrage1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Presseanfrage1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type SearchSectionEntryUnion = Search_Entry;

export type SearchTags_Tag = ElementInterface &
    TagInterface & {
        __typename?: 'searchTags_Tag';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the group that contains the tag. */
        groupHandle: Scalars['String'];
        /** The ID of the group that contains the tag. */
        groupId: Scalars['Int'];
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        tagsSynonyms: Array<Maybe<TagInterface>>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type SearchTags_Tag_CountArgs = {
    field: Scalars['String'];
};

export type SearchTags_TagTagsSynonymsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Search_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'search_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMenuHidden?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsNotSearchable?: Maybe<Scalars['Boolean']>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Search_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Search_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionAccommodations_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        accommodationsQuery?: Maybe<Scalars['String']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionAccommodations_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionAccommodations_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccommodations_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionAccordion_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        header?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        text?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionAccordion_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionAccordion_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAccordion_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionAction_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        action: Array<Maybe<EntryInterface>>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionAction_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionAction_EntryActionArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionAction_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionActivities_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        activitiesQuery?: Maybe<Scalars['String']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionActivities_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionActivities_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionActivities_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionBanner_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        banner: Array<Maybe<EntryInterface>>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionBanner_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionBanner_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryBannerArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionBanner_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionButton_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        button: Array<Maybe<HyperLinkInterface>>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionButton_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionButton_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionButton_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionCompanies_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        companiesQuery?: Maybe<Scalars['String']>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionCompanies_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionCompanies_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionCompanies_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionEmbed_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        embed?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionEmbed_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionEmbed_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionEmbed_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionForms_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        form?: Maybe<Array<Maybe<FormInterface>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionForms_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionForms_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryFormArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SectionForms_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionForms_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionHeader_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        header?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionHeader_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionHeader_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeader_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionHierarchicalTiles_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        relationType?: Maybe<Scalars['String']>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionHierarchicalTiles_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionHierarchicalTiles_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryRelationTypeArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHierarchicalTiles_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionImages_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        images: Array<Maybe<AssetInterface>>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionImages_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionImages_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryImagesArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SectionImages_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionImages_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionMap_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        pins?: Maybe<Array<Maybe<Pins_TableRow>>>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionMap_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionMap_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionMap_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionText_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        text?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionText_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionText_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionText_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionTiles_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        tilesChildren?: Maybe<Scalars['Boolean']>;
        tilesEntries: Array<Maybe<EntryInterface>>;
        tilesSiblings?: Maybe<Scalars['Boolean']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionTiles_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionTiles_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTiles_EntryTilesEntriesArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionTip_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        tip: Array<Maybe<EntryInterface>>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionTip_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionTip_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTip_EntryTipArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionTravelbaseTiles_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        travelbaseEntityType?: Maybe<Scalars['String']>;
        travelbaseSlugs?: Maybe<Array<Maybe<TravelbaseSlugs_TableRow>>>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type SectionTravelbaseTiles_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionTravelbaseTiles_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionTravelbaseTiles_EntryTravelbaseEntityTypeArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionVideo_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        autoplay?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        videoUrl?: Maybe<Scalars['String']>;
    };

export type SectionVideo_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionVideo_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionVideo_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'sectionWebcam_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        webcamUrl?: Maybe<Scalars['String']>;
    };

export type SectionWebcam_Entry_CountArgs = {
    field: Scalars['String'];
};

export type SectionWebcam_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type SectionWebcam_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'sloffen1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Sloffen1_Form_CountArgs = {
    field: Scalars['String'];
};

export type Sloffen1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen1_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Sloffen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'sloffen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Sloffen_Form_CountArgs = {
    field: Scalars['String'];
};

export type Sloffen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Sloffen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Sommelpoodjenl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'sommelpoodjenl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Sommelpoodjenl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Sommelpoodjenl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Sommelpoodjenl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Sommelpoodjenl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type StandaardFormulierNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'standaardFormulierNl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        geboortedatum?: Maybe<Scalars['DateTime']>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeVaakBenJeAlOpTexelGeweest?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opmerkingen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        telefoonnummer?: Maybe<Scalars['Number']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
        watIsHetJuisteAntwoord?: Maybe<Scalars['String']>;
        watZijnDeNamenVanDeBoten?: Maybe<Array<Maybe<Scalars['String']>>>;
    };

export type StandaardFormulierNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type StandaardFormulierNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type StandaardFormulierNl_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type StandaardFormulierNl_FormHoeVaakBenJeAlOpTexelGeweestArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type StandaardFormulierNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type StandaardFormulierNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type StandaardFormulierNl_FormWatIsHetJuisteAntwoordArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type StandaardFormulierNl_FormWatZijnDeNamenVanDeBotenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Strandvlaktesduinvalleiennl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'strandvlaktesduinvalleiennl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Strandvlaktesduinvalleiennl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Strandvlaktesduinvalleiennl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Strandvlaktesduinvalleiennl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Strandvlaktesduinvalleiennl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Synonyms_Tag = ElementInterface &
    TagInterface & {
        __typename?: 'synonyms_Tag';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the group that contains the tag. */
        groupHandle: Scalars['String'];
        /** The ID of the group that contains the tag. */
        groupId: Scalars['Int'];
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Synonyms_Tag_CountArgs = {
    field: Scalars['String'];
};

export type Test1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'test1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Test1_Form_CountArgs = {
    field: Scalars['String'];
};

export type Test1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Test1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Test1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Test_Form = ElementInterface &
    FormInterface & {
        __typename?: 'test_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Test_Form_CountArgs = {
    field: Scalars['String'];
};

export type Test_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Test_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Test_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type TestformulierNaUpdateCraft_Form = ElementInterface &
    FormInterface & {
        __typename?: 'testformulierNaUpdateCraft_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type TestformulierNaUpdateCraft_Form_CountArgs = {
    field: Scalars['String'];
};

export type TestformulierNaUpdateCraft_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TestformulierNaUpdateCraft_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TestformulierNaUpdateCraft_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Testformulier_Form = ElementInterface &
    FormInterface & {
        __typename?: 'testformulier_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMail1?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Testformulier_Form_CountArgs = {
    field: Scalars['String'];
};

export type Testformulier_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Testformulier_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Testformulier_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type TexelagendaIi_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelagendaII_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hebJeEenVraagOverTexelagenda?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type TexelagendaIi_Form_CountArgs = {
    field: Scalars['String'];
};

export type TexelagendaIi_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelagendaIi_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelagendaIi_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Texelfotoinsturen1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelfotoinsturen1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        social?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichtingBijDeFoto?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Texelfotoinsturen1_Form_CountArgs = {
    field: Scalars['String'];
};

export type Texelfotoinsturen1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturen1_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Texelfotoinsturen1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturen1_FormSocialArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturen1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Texelfotoinsturen_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelfotoinsturen_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        social?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichtingBijDeFoto?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Texelfotoinsturen_Form_CountArgs = {
    field: Scalars['String'];
};

export type Texelfotoinsturen_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturen_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Texelfotoinsturen_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturen_FormSocialArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturen_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Texelfotoinsturendu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelfotoinsturendu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        fotoUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<NameType>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        toelichtingBijDeFoto?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Texelfotoinsturendu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Texelfotoinsturendu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturendu_FormFotoUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Texelfotoinsturendu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelfotoinsturendu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type TexelgidsNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelgidsNl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nieuwsbrief?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type TexelgidsNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type TexelgidsNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelgidsNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type TexelgidsNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Texelpuzzelde_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelpuzzelde_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Texelpuzzelde_Form_CountArgs = {
    field: Scalars['String'];
};

export type Texelpuzzelde_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelpuzzelde_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Texelpuzzelde_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelpuzzelde_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Texelpuzzelnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'texelpuzzelnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type Texelpuzzelnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Texelpuzzelnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelpuzzelnl_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Texelpuzzelnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Texelpuzzelnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type ThankYouSectionEntryUnion = ThankYou_Entry;

export type ThankYou_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'thankYou_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags: Array<Maybe<TagInterface>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type ThankYou_Entry_CountArgs = {
    field: Scalars['String'];
};

export type ThankYou_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Tip_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'tip_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        tipAvatar: Array<Maybe<AssetInterface>>;
        tipName?: Maybe<Scalars['String']>;
        tipRole?: Maybe<Scalars['String']>;
        tipText?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Tip_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Tip_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tip_EntryTipAvatarArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TipsSectionEntryUnion = Tip_Entry;

export type TravelbaseSlugs_TableRow = {
    __typename?: 'travelbaseSlugs_TableRow';
    col1?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type VeldnamenNl2024_Form = ElementInterface &
    FormInterface & {
        __typename?: 'veldnamenNL2024_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type VeldnamenNl2024_Form_CountArgs = {
    field: Scalars['String'];
};

export type VeldnamenNl2024_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type VeldnamenNl2024_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type VeldnamenNl2024_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type VerjaardagWijzigenNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'verjaardagWijzigenNl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mijnGeboortedatum?: Maybe<Scalars['DateTime']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type VerjaardagWijzigenNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type VerjaardagWijzigenNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type VerjaardagWijzigenNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type VerjaardagWijzigenNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Voetsporenvocnl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'voetsporenvocnl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Voetsporenvocnl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Voetsporenvocnl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Voetsporenvocnl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Voetsporenvocnl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type VoorbeeldformulierMetAlleVelden_Form = ElementInterface &
    FormInterface & {
        __typename?: 'voorbeeldformulierMetAlleVelden_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        akkoordMetVoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        checkboxjes?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        dropdown?: Maybe<Scalars['String']>;
        eMailadres?: Maybe<Scalars['String']>;
        eenRegelTekst?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikKomOpTexelAan?: Maybe<Scalars['DateTime']>;
        insturen?: Maybe<Scalars['String']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        keuzeRadioButtons?: Maybe<Scalars['String']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        meerdereRegelsTekst?: Maybe<Scalars['String']>;
        mijnAdresIs?: Maybe<AddressType>;
        naamDubbeleVelden?: Maybe<NameType>;
        naamEnkel?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        nummerKiezen?: Maybe<Scalars['Number']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        pdfUploaden: Array<Maybe<AssetInterface>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        telefoonnummer?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type VoorbeeldformulierMetAlleVelden_Form_CountArgs = {
    field: Scalars['String'];
};

export type VoorbeeldformulierMetAlleVelden_FormCheckboxjesArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type VoorbeeldformulierMetAlleVelden_FormDropdownArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type VoorbeeldformulierMetAlleVelden_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type VoorbeeldformulierMetAlleVelden_FormKeuzeRadioButtonsArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type VoorbeeldformulierMetAlleVelden_FormPdfUploadenArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VoorbeeldformulierMetAlleVelden_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type VoorbeeldformulierMetAlleVelden_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type VoucherLocations_MatrixField = Location_Entry;

export type VoucherPhoneNumber_PhoneNumberQueryArgument = {
    /** The number */
    number?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** The region */
    region?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Voucher_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'voucher_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors: Array<EntryInterface>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children: Array<EntryInterface>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants: Array<EntryInterface>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled for the site. */
        enabledForSite?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The handle of the field that contains the entry. */
        fieldHandle?: Maybe<Scalars['String']>;
        /** The ID of the field that contains the entry. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized: Array<EntryInterface>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The ID of the entry’s owner elementt. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The entry’s position within the field that contains it. */
        sortOrder?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle: Scalars['String'];
        /** The ID of the entry type that contains the entry. */
        typeId: Scalars['Int'];
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        voucherCategory?: Maybe<Scalars['String']>;
        voucherDescription?: Maybe<Scalars['String']>;
        voucherLinkUrl?: Maybe<Scalars['String']>;
        voucherLocations: Array<Maybe<VoucherLocations_MatrixField>>;
        voucherPhoneNumber?: Maybe<PhoneNumber_PhoneNumber>;
        voucherPhotos: Array<Maybe<AssetInterface>>;
        voucherProvider?: Maybe<Scalars['String']>;
        voucherTerms?: Maybe<Scalars['String']>;
        voucherValidationImage: Array<Maybe<AssetInterface>>;
        voucherVisibility?: Maybe<Scalars['String']>;
    };

export type Voucher_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Voucher_EntryAncestorsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryChildrenArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryDescendantsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryParentArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<VoucherPhoneNumber_PhoneNumberQueryArgument>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryVoucherCategoryArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryVoucherLocationsArgs = {
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    field?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    locationAddress?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    locationExtra?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    locationLatLon?: InputMaybe<Ether_MapQuery>;
    locationPlace?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    primaryOwnerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Voucher_EntryVoucherPhotosArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Voucher_EntryVoucherValidationImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    language?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    notRelatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetRelationCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryRelationCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryRelationCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagRelationCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserRelationCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Voucher_EntryVoucherVisibilityArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type VouchersSectionEntryUnion = Voucher_Entry;

export type WandelrouteLangsDeMeeuwenkolonieDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelrouteLangsDeMeeuwenkolonieDu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type WandelrouteLangsDeMeeuwenkolonieDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type WandelrouteLangsDeMeeuwenkolonieDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelrouteLangsDeMeeuwenkolonieDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelrouteLangsDeMeeuwenkolonieDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Wandelroutedemuydu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutedemuydu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Wandelroutedemuydu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Wandelroutedemuydu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutedemuydu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutedemuydu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Wandelroutedemuynl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutedemuynl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Wandelroutedemuynl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Wandelroutedemuynl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutedemuynl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutedemuynl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WandelroutedenhoornNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutedenhoornNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type WandelroutedenhoornNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WandelroutedenhoornNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutedenhoornNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutedenhoornNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Wandelroutehogebergde2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutehogebergde2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Wandelroutehogebergde2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Wandelroutehogebergde2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergde2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergde2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Wandelroutehogebergnl1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutehogebergnl1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Wandelroutehogebergnl1_Form_CountArgs = {
    field: Scalars['String'];
};

export type Wandelroutehogebergnl1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergnl1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergnl1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Wandelroutehogebergnl2022_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutehogebergnl2022_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Wandelroutehogebergnl2022_Form_CountArgs = {
    field: Scalars['String'];
};

export type Wandelroutehogebergnl2022_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergnl2022_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergnl2022_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Wandelroutehogebergnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutehogebergnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
    };

export type Wandelroutehogebergnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Wandelroutehogebergnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Wandelroutehogebergnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WandelroutesInDeSporenVanDeVocDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutesInDeSporenVanDeVocDu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type WandelroutesInDeSporenVanDeVocDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type WandelroutesInDeSporenVanDeVocDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutesInDeSporenVanDeVocDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutesInDeSporenVanDeVocDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WandelroutesLangsGroteGrazersDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutesLangsGroteGrazersDu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type WandelroutesLangsGroteGrazersDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type WandelroutesLangsGroteGrazersDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutesLangsGroteGrazersDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutesLangsGroteGrazersDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WandelroutesStrandvlaktesEnDuinvalleienDu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mochtenSieAuchUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type WandelroutesStrandvlaktesEnDuinvalleienDu_Form_CountArgs = {
    field: Scalars['String'];
};

export type WandelroutesStrandvlaktesEnDuinvalleienDu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutesStrandvlaktesEnDuinvalleienDu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WandelroutesStrandvlaktesEnDuinvalleienDu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WebshopVvvTexel_Form = ElementInterface &
    FormInterface & {
        __typename?: 'webshopVvvTexel_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hebJeNogVragenOfAdviezenMBTDitPlan?: Maybe<Scalars['String']>;
        hoeveelVerschillendeProducten?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mogenWeJouInDeLoopVanDeZomerContacten?: Maybe<Array<Maybe<Scalars['String']>>>;
        naam?: Maybe<Scalars['String']>;
        naamWebshop?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        watVindJeVanHetIdeeVanEenWebshopVanVvvTexel?: Maybe<Scalars['String']>;
    };

export type WebshopVvvTexel_Form_CountArgs = {
    field: Scalars['String'];
};

export type WebshopVvvTexel_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WebshopVvvTexel_FormHoeveelVerschillendeProductenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WebshopVvvTexel_FormMogenWeJouInDeLoopVanDeZomerContactenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WebshopVvvTexel_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WebshopVvvTexel_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinEenVerblijfMetDeTexelAppDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winEenVerblijfMetDeTexelAppDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        jaIkGaAkkoordMetDeActievoorwaarden?: Maybe<Scalars['String']>;
        jeAchternaam?: Maybe<Scalars['String']>;
        jeEMailadres?: Maybe<Scalars['String']>;
        jeVoornaam?: Maybe<Scalars['String']>;
        jouwAntwoordOpDePrijsvraag?: Maybe<Scalars['String']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinEenVerblijfMetDeTexelAppDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinEenVerblijfMetDeTexelAppDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinEenVerblijfMetDeTexelAppDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinEenVerblijfMetDeTexelAppDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinEenVerblijfMetDeTexelAppNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winEenVerblijfMetDeTexelAppNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        jaIkGaAkkoordMetDeActievoorwaarden?: Maybe<Scalars['String']>;
        jeAchternaam?: Maybe<Scalars['String']>;
        jeEMailadres?: Maybe<Scalars['String']>;
        jeVoornaam?: Maybe<Scalars['String']>;
        jouwAntwoordOpDePrijsvraag?: Maybe<Scalars['String']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinEenVerblijfMetDeTexelAppNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinEenVerblijfMetDeTexelAppNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinEenVerblijfMetDeTexelAppNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinEenVerblijfMetDeTexelAppNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieBEpers_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieBEpers_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeveelkilometerstrand?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nieuwsbrief?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieBEpers_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieBEpers_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBEpers_FormHoeveelkilometerstrandArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBEpers_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBEpers_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieBroadway2024De_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieBroadway2024DE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        actionbedingungen?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadresse?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type WinactieBroadway2024De_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieBroadway2024De_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBroadway2024De_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBroadway2024De_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieBroadway2024En_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieBroadway2024EN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        doYouAgreeToTheTermsAndConditions?: Maybe<Scalars['String']>;
        emailAddress?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        lastname?: Maybe<Scalars['String']>;
        name1?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieBroadway2024En_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieBroadway2024En_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBroadway2024En_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBroadway2024En_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieBroadway2024Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieBroadway2024NL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieBroadway2024Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieBroadway2024Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBroadway2024Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBroadway2024Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieBute_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieBute_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieBute_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieBute_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBute_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieBute_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFb14SterrenBewa_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFB14SterrenBEWA_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeLangDuurtDeOvertochtVanDenHelderNaarTexel?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFb14SterrenBewa_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFb14SterrenBewa_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenBewa_FormHoeLangDuurtDeOvertochtVanDenHelderNaarTexelArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenBewa_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenBewa_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFb14SterrenDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFB14SterrenDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeLangDuurtDeOvertochtVanDenHelderNaarTexel?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFb14SterrenDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFb14SterrenDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenDe_FormHoeLangDuurtDeOvertochtVanDenHelderNaarTexelArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFb14SterrenEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFB14SterrenEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeLangDuurtDeOvertochtVanDenHelderNaarTexel?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFb14SterrenEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFb14SterrenEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenEn_FormHoeLangDuurtDeOvertochtVanDenHelderNaarTexelArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFb14SterrenNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFB14SterrenNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeLangDuurtDeOvertochtVanDenHelderNaarTexel?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFb14SterrenNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFb14SterrenNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenNl_FormHoeLangDuurtDeOvertochtVanDenHelderNaarTexelArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFb14SterrenNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFbVideolead2025De_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFBVideolead2025DE_Form';
        HoeveelKMstrand?: Maybe<Scalars['String']>;
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFbVideolead2025De_FormHoeveelKMstrandArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025De_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFbVideolead2025De_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025De_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025De_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFbVideolead2025En_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFBVideolead2025EN_Form';
        HoeveelKMstrand?: Maybe<Scalars['String']>;
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFbVideolead2025En_FormHoeveelKMstrandArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025En_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFbVideolead2025En_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025En_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025En_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFbVideolead2025Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFBVideolead2025NL_Form';
        HoeveelKMstrand?: Maybe<Scalars['String']>;
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFbVideolead2025Nl_FormHoeveelKMstrandArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFbVideolead2025Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideolead2025Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFbVideoleadDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFBVideoleadDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedingungen?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse1?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
        wieGrossIstDerLeuchtturmVonTexel?: Maybe<Scalars['String']>;
    };

export type WinactieFbVideoleadDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFbVideoleadDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFbVideoleadDe_FormWieGrossIstDerLeuchtturmVonTexelArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadEn1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFBVideoleadEN1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        conditions?: Maybe<Scalars['String']>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        emailAddress?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        firstName?: Maybe<Scalars['String']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        howTallIsTheTexelLighthouse?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        lastName?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieFbVideoleadEn1_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFbVideoleadEn1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadEn1_FormHowTallIsTheTexelLighthouseArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadEn1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadEn1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieFbVideoleadNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieFBVideoleadNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        hoeLangIsDeVuurtorenVanTexel1?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieFbVideoleadNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieFbVideoleadNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadNl_FormHoeLangIsDeVuurtorenVanTexel1Args = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieFbVideoleadNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieKomTotRustOpTexelDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieKomTotRustOpTexelDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieKomTotRustOpTexelDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieKomTotRustOpTexelDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKomTotRustOpTexelDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKomTotRustOpTexelDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieKomTotRustOpTexelEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieKomTotRustOpTexelEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieKomTotRustOpTexelEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieKomTotRustOpTexelEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKomTotRustOpTexelEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKomTotRustOpTexelEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieKomTotRustOpTexelNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieKomTotRustOpTexelNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieKomTotRustOpTexelNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieKomTotRustOpTexelNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKomTotRustOpTexelNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKomTotRustOpTexelNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieKoopplein_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieKoopplein_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieKoopplein_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieKoopplein_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKoopplein_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKoopplein_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieKunstZomerDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieKunstZomerDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieKunstZomerDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieKunstZomerDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKunstZomerDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKunstZomerDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieKunstZomer_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieKunstZomer_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieKunstZomer_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieKunstZomer_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKunstZomer_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieKunstZomer_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieLekkerLokaalDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieLekkerLokaalDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        bedingungen?: Maybe<Scalars['String']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailAdresse1?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        nachname?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        vorname?: Maybe<Scalars['String']>;
    };

export type WinactieLekkerLokaalDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieLekkerLokaalDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieLekkerLokaalDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieLekkerLokaalDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieLekkerLokaalEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieLekkerLokaalEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        conditions?: Maybe<Scalars['String']>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        emailAddress?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        firstName?: Maybe<Scalars['String']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        lastName?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieLekkerLokaalEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieLekkerLokaalEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieLekkerLokaalEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieLekkerLokaalEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieLekkerLokaalNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieLekkerLokaalNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieLekkerLokaalNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieLekkerLokaalNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieLekkerLokaalNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieLekkerLokaalNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieParachutesprongDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieParachutesprongDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieParachutesprongDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieParachutesprongDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieParachutesprongDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieParachutesprongDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieParachutesprongEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieParachutesprongEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieParachutesprongEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieParachutesprongEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieParachutesprongEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieParachutesprongEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieParachutesprongNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieParachutesprongNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieParachutesprongNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieParachutesprongNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieParachutesprongNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieParachutesprongNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieRede_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieRede_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieRede_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieRede_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRede_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRede_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieRondritTexelDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieRondritTexelDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        aktionbedingungen?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieRondritTexelDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieRondritTexelDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRondritTexelDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRondritTexelDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieRondritTexelNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieRondritTexelNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieRondritTexelNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieRondritTexelNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRondritTexelNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRondritTexelNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieRussstNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieRussstNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieRussstNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieRussstNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRussstNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieRussstNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieScheurkalender1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieScheurkalender1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieScheurkalender1_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieScheurkalender1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieScheurkalender1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieScheurkalender1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieScheurkalender_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieScheurkalender_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieScheurkalender_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieScheurkalender_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieScheurkalender_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieScheurkalender_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelCulinair2024De_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelCulinair2024DE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelCulinair2024De_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelCulinair2024De_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinair2024De_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinair2024De_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelCulinairDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelCulinairDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelCulinairDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelCulinairDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelCulinairNl2_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelCulinairNL2_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelCulinairNl2_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelCulinairNl2_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairNl2_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairNl2_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelCulinairNl24_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelCulinairNL24_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelCulinairNl24_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelCulinairNl24_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairNl24_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairNl24_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelCulinairNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelCulinairNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelCulinairNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelCulinairNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelCulinairNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelHalveMarathon2023_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelHalveMarathon2023_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelHalveMarathon2023_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelHalveMarathon2023_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon2023_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon2023_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelHalveMarathon2024_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelHalveMarathon2024_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelHalveMarathon2024_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelHalveMarathon2024_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon2024_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon2024_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelHalveMarathon20241_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelHalveMarathon20241_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelHalveMarathon20241_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelHalveMarathon20241_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon20241_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon20241_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelHalveMarathon20242_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelHalveMarathon20242_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelHalveMarathon20242_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelHalveMarathon20242_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon20242_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon20242_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelHalveMarathon_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelHalveMarathon_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikBenAkkoordMetDeVoorwaarden?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelHalveMarathon_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelHalveMarathon_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon_FormIkBenAkkoordMetDeVoorwaardenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelHalveMarathon_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelanapantoffelsNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelanapantoffelsNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        inWelkeMaat?: Maybe<Scalars['String']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkePrijsWilJeKansMaken1?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieTexelanapantoffelsNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelanapantoffelsNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelanapantoffelsNl_FormInWelkeMaatArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelanapantoffelsNl_FormOpWelkePrijsWilJeKansMaken1Args = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelanapantoffelsNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelanapantoffelsNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelblues2023Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelblues2023NL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieTexelblues2023Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelblues2023Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2023Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2023Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelblues2023_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelblues2023_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieTexelblues2023_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelblues2023_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2023_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2023_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelblues2024De_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelblues2024DE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelblues2024De_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelblues2024De_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2024De_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2024De_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelblues2024Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelblues2024NL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieTexelblues2024Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelblues2024Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2024Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelblues2024Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelbues_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelbues_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikWilKansMakenOp?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mijnGegevens?: Maybe<Scalars['String']>;
        onzeNieuwsbriefOntvangen?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieTexelbues_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelbues_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelbues_FormIkWilKansMakenOpArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelbues_FormOnzeNieuwsbriefOntvangenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelbues_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelbues_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelshopDe1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelshopDE1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelshopDe1_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelshopDe1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelshopDe1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelshopDe1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelshopNl1_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelshopNL1_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelshopNl1_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelshopNl1_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelshopNl1_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelshopNl1_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieTexelshopNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieTexelshopNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieTexelshopNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieTexelshopNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelshopNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieTexelshopNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieThreesAnnaDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieThreesAnnaDE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieThreesAnnaDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieThreesAnnaDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieThreesAnnaDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieThreesAnnaDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieThreesAnnaEn_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieThreesAnnaEN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieThreesAnnaEn_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieThreesAnnaEn_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieThreesAnnaEn_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieThreesAnnaEn_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieThreesAnnaNl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieThreesAnnaNL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type WinactieThreesAnnaNl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieThreesAnnaNl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieThreesAnnaNl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieThreesAnnaNl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieWaves2023Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieWaves2023NL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkePrijsWilJeKansMaken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieWaves2023Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieWaves2023Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2023Nl_FormOpWelkePrijsWilJeKansMakenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2023Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2023Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieWaves2024De_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieWaves2024DE_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        aktionsbedingungen?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkePrijsWilJeKansMaken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieWaves2024De_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieWaves2024De_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024De_FormOpWelkePrijsWilJeKansMakenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024De_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024De_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieWaves2024En_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieWaves2024EN_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actionConditions?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkePrijsWilJeKansMaken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieWaves2024En_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieWaves2024En_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024En_FormOpWelkePrijsWilJeKansMakenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024En_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024En_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieWaves2024Nl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieWaves2024NL_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        naam?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        opWelkePrijsWilJeKansMaken?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type WinactieWaves2024Nl_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieWaves2024Nl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024Nl_FormOpWelkePrijsWilJeKansMakenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024Nl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWaves2024Nl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type WinactieWebshopDe_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactieWebshopDe_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam?: Maybe<Scalars['String']>;
        actievoorwaarden?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam?: Maybe<Scalars['String']>;
    };

export type WinactieWebshopDe_Form_CountArgs = {
    field: Scalars['String'];
};

export type WinactieWebshopDe_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWebshopDe_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type WinactieWebshopDe_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Winactie_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactie_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        newsletter_optin?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Winactie_Form_CountArgs = {
    field: Scalars['String'];
};

export type Winactie_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Winactie_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Winactie_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Winactiefacebookbdu_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactiefacebookbdu_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        ichMochteAmGewinnspielTeilnehmenMehrfachnennungenMoglich?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mijnGegevens?: Maybe<Scalars['String']>;
        mochtenSieUnserenNewsletterErhalten?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Winactiefacebookbdu_Form_CountArgs = {
    field: Scalars['String'];
};

export type Winactiefacebookbdu_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefacebookbdu_FormIchMochteAmGewinnspielTeilnehmenMehrfachnennungenMoglichArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefacebookbdu_FormMochtenSieUnserenNewsletterErhaltenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefacebookbdu_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefacebookbdu_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type Winactiefbnl_Form = ElementInterface &
    FormInterface & {
        __typename?: 'winactiefbnl_Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        achternaam1?: Maybe<Scalars['String']>;
        /** Whether the element is archived. */
        archived?: Maybe<Scalars['Boolean']>;
        /** A list of captcha values (name and value) to assist with spam protection. */
        captchas?: Maybe<Array<Maybe<FormieCaptchaType>>>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        /** A CSRF token (name and value). */
        csrfToken?: Maybe<FormieCsrfTokenType>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        formFields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        ikWilKansMakenOp?: Maybe<Array<Maybe<Scalars['String']>>>;
        /** Whether the form is considered available according to user checks, scheduling and more. */
        isAvailable?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        mijnGegevens?: Maybe<Scalars['String']>;
        onzeNieuwsbriefOntvangen?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['Int']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The handle of the site the element is associated with. */
        siteHandle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s endpoint for sending submissions to, if using POST requests. */
        submissionEndpoint?: Maybe<Scalars['String']>;
        /** The form’s GQL mutation name for submissions to use. */
        submissionMutationName?: Maybe<Scalars['String']>;
        /** The form’s CSS for rendering. */
        templateCss?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The form’s JS for rendering and functionality. */
        templateJs?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        voornaam1?: Maybe<Scalars['String']>;
        voorwaarden?: Maybe<Scalars['String']>;
    };

export type Winactiefbnl_Form_CountArgs = {
    field: Scalars['String'];
};

export type Winactiefbnl_FormFormFieldsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefbnl_FormIkWilKansMakenOpArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefbnl_FormOnzeNieuwsbriefOntvangenArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefbnl_FormRowsArgs = {
    includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type Winactiefbnl_FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type ActionFragment = {
    title?: string | null;
    description?: string | null;
    button: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'page_Entry'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelagendaII_Form'
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl2022_Form'
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadDE_Form'
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieScheurkalender_Form'
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaDE_Form'
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
} & { __typename?: 'action_Entry' };

type Card_YxNr1EikEj6mIMknWwFnoWf8AxEkEpqgWeEqPpfasU_Fragment = { title?: string | null; uri?: string | null } & {
    __typename?:
        | 'action_Entry'
        | 'banner_Entry'
        | 'contact2_Entry'
        | 'footerCategory_Entry'
        | 'footerLink_Entry'
        | 'location_Entry'
        | 'menu_Entry'
        | 'search_Entry'
        | 'sectionAccommodations_Entry'
        | 'sectionAccordion_Entry'
        | 'sectionAction_Entry'
        | 'sectionActivities_Entry'
        | 'sectionBanner_Entry'
        | 'sectionButton_Entry'
        | 'sectionCompanies_Entry'
        | 'sectionEmbed_Entry'
        | 'sectionForms_Entry'
        | 'sectionHeader_Entry'
        | 'sectionHierarchicalTiles_Entry'
        | 'sectionImages_Entry';
};

type Card_D0mLTffftI9cCgtp0w75rdMuif5OAo9tVWriArwGxk_Fragment = { title?: string | null; uri?: string | null } & {
    __typename?:
        | 'sectionMap_Entry'
        | 'sectionText_Entry'
        | 'sectionTiles_Entry'
        | 'sectionTip_Entry'
        | 'sectionTravelbaseTiles_Entry'
        | 'sectionVideo_Entry'
        | 'sectionWebcam_Entry'
        | 'tip_Entry'
        | 'voucher_Entry';
};

type Card_F10wOmYwyThYg8cBxwkz1kaNdIs7GuivHoB55GmWh8_Fragment = {
    title?: string | null;
    uri?: string | null;
    settingsMetaDescription?: string | null;
} & { __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry' };

type Card_Article_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    postDate?: any | null;
    settingsMetaDescription?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'article_Entry' };

type Card_Contact_Entry_Homepage_Entry_News_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    settingsMetaDescription?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' };

type Card_Page_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    id?: string | null;
    settingsMetaDescription?: string | null;
    settingsNotSearchable?: boolean | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'page_Entry' };

export type CardFragment =
    | Card_YxNr1EikEj6mIMknWwFnoWf8AxEkEpqgWeEqPpfasU_Fragment
    | Card_D0mLTffftI9cCgtp0w75rdMuif5OAo9tVWriArwGxk_Fragment
    | Card_F10wOmYwyThYg8cBxwkz1kaNdIs7GuivHoB55GmWh8_Fragment
    | Card_Article_Entry_Fragment
    | Card_Contact_Entry_Homepage_Entry_News_Entry_Fragment
    | Card_Page_Entry_Fragment;

export type CardImageFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension: string;
    url?: string | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type PageCardFragment = {
    id?: string | null;
    settingsMetaDescription?: string | null;
    settingsNotSearchable?: boolean | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'page_Entry' };

export type ArticleCardFragment = {
    postDate?: any | null;
    settingsMetaDescription?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'article_Entry' };

export type NewsCardFragment = {
    settingsMetaDescription?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'news_Entry' };

export type HomeCardFragment = {
    settingsMetaDescription?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'homepage_Entry' };

export type ContactCardFragment = {
    settingsMetaDescription?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'contact_Entry' };

export type AgendaCardFragment = { settingsMetaDescription?: string | null } & { __typename?: 'agenda_Entry' };

export type ThankYouCardFragment = { settingsMetaDescription?: string | null } & { __typename?: 'thankYou_Entry' };

export type CompanyCardFragment = { settingsMetaDescription?: string | null } & { __typename?: 'companies_Entry' };

export type MyTexelCardFragment = { settingsMetaDescription?: string | null } & { __typename?: 'myTexel_Entry' };

type ContentMatrix_SectionAccommodations_Entry_Fragment = { accommodationsQuery?: string | null } & {
    __typename?: 'sectionAccommodations_Entry';
};

type ContentMatrix_SectionAccordion_Entry_Fragment = { header?: string | null; text?: string | null } & {
    __typename?: 'sectionAccordion_Entry';
};

type ContentMatrix_SectionAction_Entry_Fragment = { __typename?: 'sectionAction_Entry' };

type ContentMatrix_SectionActivities_Entry_Fragment = { activitiesQuery?: string | null } & {
    __typename?: 'sectionActivities_Entry';
};

type ContentMatrix_SectionBanner_Entry_Fragment = {
    banner: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              heroImage: Array<({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null>;
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'banner_Entry' })
        | null
    >;
} & { __typename?: 'sectionBanner_Entry' };

type ContentMatrix_SectionButton_Entry_Fragment = {
    button: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'page_Entry'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelagendaII_Form'
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl2022_Form'
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadDE_Form'
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieScheurkalender_Form'
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaDE_Form'
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
} & { __typename?: 'sectionButton_Entry' };

type ContentMatrix_SectionCompanies_Entry_Fragment = { companiesQuery?: string | null } & {
    __typename?: 'sectionCompanies_Entry';
};

type ContentMatrix_SectionEmbed_Entry_Fragment = { embed?: string | null } & { __typename?: 'sectionEmbed_Entry' };

type ContentMatrix_SectionForms_Entry_Fragment = { id?: string | null } & { __typename?: 'sectionForms_Entry' };

type ContentMatrix_SectionHeader_Entry_Fragment = { header?: string | null } & { __typename?: 'sectionHeader_Entry' };

type ContentMatrix_SectionHierarchicalTiles_Entry_Fragment = { relationType?: string | null } & {
    __typename?: 'sectionHierarchicalTiles_Entry';
};

type ContentMatrix_SectionImages_Entry_Fragment = {
    gallery: Array<
        | ({
              assetSource?: string | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename?: 'sectionImages_Entry' };

type ContentMatrix_SectionMap_Entry_Fragment = {
    pins?: Array<
        | ({ latitude?: any | null; longitude?: any | null; symbol?: string | null; label?: string | null } & {
              __typename?: 'pins_TableRow';
          })
        | null
    > | null;
} & { __typename?: 'sectionMap_Entry' };

type ContentMatrix_SectionText_Entry_Fragment = { text?: string | null } & { __typename?: 'sectionText_Entry' };

type ContentMatrix_SectionTiles_Entry_Fragment = {
    tilesChildren?: boolean | null;
    tilesSiblings?: boolean | null;
    tilesEntries: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'banner_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'page_Entry' })
        | null
    >;
} & { __typename?: 'sectionTiles_Entry' };

type ContentMatrix_SectionTip_Entry_Fragment = {
    tip: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          }
        | {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              tipText?: string | null;
              tipName?: string | null;
              tipRole?: string | null;
              tipAvatar: Array<
                  | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                        __typename?: 'library_Asset';
                    })
                  | null
              >;
          } & { __typename?: 'tip_Entry' })
        | null
    >;
} & { __typename?: 'sectionTip_Entry' };

type ContentMatrix_SectionTravelbaseTiles_Entry_Fragment = {
    travelbaseEntityType?: string | null;
    travelbaseSlugs?: Array<({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null> | null;
} & { __typename?: 'sectionTravelbaseTiles_Entry' };

type ContentMatrix_SectionVideo_Entry_Fragment = { videoUrl?: string | null; autoplay?: boolean | null } & {
    __typename?: 'sectionVideo_Entry';
};

type ContentMatrix_SectionWebcam_Entry_Fragment = { webcamUrl?: string | null } & {
    __typename?: 'sectionWebcam_Entry';
};

export type ContentMatrixFragment =
    | ContentMatrix_SectionAccommodations_Entry_Fragment
    | ContentMatrix_SectionAccordion_Entry_Fragment
    | ContentMatrix_SectionAction_Entry_Fragment
    | ContentMatrix_SectionActivities_Entry_Fragment
    | ContentMatrix_SectionBanner_Entry_Fragment
    | ContentMatrix_SectionButton_Entry_Fragment
    | ContentMatrix_SectionCompanies_Entry_Fragment
    | ContentMatrix_SectionEmbed_Entry_Fragment
    | ContentMatrix_SectionForms_Entry_Fragment
    | ContentMatrix_SectionHeader_Entry_Fragment
    | ContentMatrix_SectionHierarchicalTiles_Entry_Fragment
    | ContentMatrix_SectionImages_Entry_Fragment
    | ContentMatrix_SectionMap_Entry_Fragment
    | ContentMatrix_SectionText_Entry_Fragment
    | ContentMatrix_SectionTiles_Entry_Fragment
    | ContentMatrix_SectionTip_Entry_Fragment
    | ContentMatrix_SectionTravelbaseTiles_Entry_Fragment
    | ContentMatrix_SectionVideo_Entry_Fragment
    | ContentMatrix_SectionWebcam_Entry_Fragment;

export type TravelbaseTileFragment = {
    travelbaseEntityType?: string | null;
    travelbaseSlugs?: Array<({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null> | null;
} & { __typename?: 'sectionTravelbaseTiles_Entry' };

type FieldRenderer_Field_Address_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    fields?: Array<
        | ({
              label?: string | null;
              enabled?: boolean | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              required?: boolean | null;
              placeholder?: string | null;
              handle?: string | null;
          } & {
              __typename?:
                  | 'Field_Address'
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Agree'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_Checkboxes'
                  | 'Field_Date'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              required?: boolean | null;
              placeholder?: string | null;
              handle?: string | null;
          } & {
              __typename?:
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber'
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Dropdown'
                  | 'Field_Email'
                  | 'Field_Entries'
                  | 'Field_FileUpload'
                  | 'Field_Group'
                  | 'Field_Heading'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_MultiLineText'
                  | 'Field_Name'
                  | 'Field_NameFirst'
                  | 'Field_NameLast';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              required?: boolean | null;
              placeholder?: string | null;
              handle?: string | null;
          } & {
              __typename?:
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Newsletter'
                  | 'Field_Number'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Phone'
                  | 'Field_Radio'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_SingleLineText'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags'
                  | 'Field_Users';
          })
        | null
    > | null;
} & { __typename?: 'Field_Address' };

type FieldRenderer_Qtw2bHdVjdv4lAagUh9l6Gbfz60LzSiHxVtJblpfZ64_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_Address1'
        | 'Field_Address2'
        | 'Field_Address3'
        | 'Field_AddressAutoComplete'
        | 'Field_AddressCity'
        | 'Field_AddressCountry'
        | 'Field_AddressState'
        | 'Field_AddressZip'
        | 'Field_Calculations'
        | 'Field_Categories'
        | 'Field_DateAmPmDropdown'
        | 'Field_DateAmPmNumber'
        | 'Field_DateDayDropdown'
        | 'Field_DateDayNumber'
        | 'Field_DateHourDropdown'
        | 'Field_DateHourNumber'
        | 'Field_DateMinuteDropdown'
        | 'Field_DateMinuteNumber'
        | 'Field_DateMonthDropdown'
        | 'Field_DateMonthNumber';
};

type FieldRenderer_V63Pp5q7trg0Awl1cUfvg0ZkuaPef12nhniL5pxjo_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_DateSecondDropdown'
        | 'Field_DateSecondNumber'
        | 'Field_DateYearDropdown'
        | 'Field_DateYearNumber'
        | 'Field_Entries'
        | 'Field_Group'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_NameFirst'
        | 'Field_NameLast'
        | 'Field_NameMiddle'
        | 'Field_NamePrefix'
        | 'Field_Password'
        | 'Field_Payment'
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section'
        | 'Field_Signature'
        | 'Field_Summary'
        | 'Field_Table';
};

type FieldRenderer_Field_Tags_Field_Users_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & { __typename?: 'Field_Tags' | 'Field_Users' };

type FieldRenderer_Field_Agree_Field_Newsletter_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
} & { __typename?: 'Field_Agree' | 'Field_Newsletter' };

type FieldRenderer_Field_Checkboxes_Field_Radio_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    options?: Array<
        ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
    > | null;
} & { __typename?: 'Field_Checkboxes' | 'Field_Radio' };

type FieldRenderer_Field_Date_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    displayType?: string | null;
    defaultDate?: any | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Date' };

type FieldRenderer_Field_Dropdown_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    options?: Array<
        | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
              __typename?: 'FieldOption';
          })
        | null
    > | null;
} & { __typename?: 'Field_Dropdown' };

type FieldRenderer_Field_Email_Field_Phone_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Email' | 'Field_Phone' };

type FieldRenderer_Field_FileUpload_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    allowedKinds?: Array<string | null> | null;
    limitFiles?: string | null;
    sizeLimit?: string | null;
    sizeMinLimit?: string | null;
} & { __typename?: 'Field_FileUpload' };

type FieldRenderer_Field_Heading_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    headingSize?: string | null;
} & { __typename?: 'Field_Heading' };

type FieldRenderer_Field_MultiLineText_Field_SingleLineText_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    limit?: boolean | null;
    maxType?: string | null;
    max?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' };

type FieldRenderer_Field_Name_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    fields?: Array<
        | ({
              label?: string | null;
              enabled?: boolean | null;
              required?: boolean | null;
              handle?: string | null;
              placeholder?: string | null;
          } & {
              __typename?:
                  | 'Field_Address'
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Agree'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_Checkboxes'
                  | 'Field_Date'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              required?: boolean | null;
              handle?: string | null;
              placeholder?: string | null;
          } & {
              __typename?:
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber'
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Dropdown'
                  | 'Field_Email'
                  | 'Field_Entries'
                  | 'Field_FileUpload'
                  | 'Field_Group'
                  | 'Field_Heading'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_MultiLineText'
                  | 'Field_Name'
                  | 'Field_NameFirst'
                  | 'Field_NameLast';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              required?: boolean | null;
              handle?: string | null;
              placeholder?: string | null;
          } & {
              __typename?:
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Newsletter'
                  | 'Field_Number'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Phone'
                  | 'Field_Radio'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_SingleLineText'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags'
                  | 'Field_Users';
          })
        | null
    > | null;
} & { __typename?: 'Field_Name' };

type FieldRenderer_Field_Number_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    defaultValue?: string | null;
    min?: number | null;
    max?: number | null;
} & { __typename?: 'Field_Number' };

export type FieldRendererFragment =
    | FieldRenderer_Field_Address_Fragment
    | FieldRenderer_Qtw2bHdVjdv4lAagUh9l6Gbfz60LzSiHxVtJblpfZ64_Fragment
    | FieldRenderer_V63Pp5q7trg0Awl1cUfvg0ZkuaPef12nhniL5pxjo_Fragment
    | FieldRenderer_Field_Tags_Field_Users_Fragment
    | FieldRenderer_Field_Agree_Field_Newsletter_Fragment
    | FieldRenderer_Field_Checkboxes_Field_Radio_Fragment
    | FieldRenderer_Field_Date_Fragment
    | FieldRenderer_Field_Dropdown_Fragment
    | FieldRenderer_Field_Email_Field_Phone_Fragment
    | FieldRenderer_Field_FileUpload_Fragment
    | FieldRenderer_Field_Heading_Fragment
    | FieldRenderer_Field_MultiLineText_Field_SingleLineText_Fragment
    | FieldRenderer_Field_Name_Fragment
    | FieldRenderer_Field_Number_Fragment;

type Field_Hzc3Bi1iTLaZ5awc86dWgXpcLjlsOnvFh8m0ls1WJcc_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_Address'
        | 'Field_Address1'
        | 'Field_Address2'
        | 'Field_Address3'
        | 'Field_AddressAutoComplete'
        | 'Field_AddressCity'
        | 'Field_AddressCountry'
        | 'Field_AddressState'
        | 'Field_AddressZip'
        | 'Field_Agree'
        | 'Field_Calculations'
        | 'Field_Categories'
        | 'Field_Checkboxes'
        | 'Field_Date'
        | 'Field_DateAmPmDropdown'
        | 'Field_DateAmPmNumber'
        | 'Field_DateDayDropdown'
        | 'Field_DateDayNumber'
        | 'Field_DateHourDropdown'
        | 'Field_DateHourNumber';
};

type Field_7RakHbpFfOkNmzAv2o5Uby3iG6QFb7mufC2FmKa9Oy_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_DateMinuteDropdown'
        | 'Field_DateMinuteNumber'
        | 'Field_DateMonthDropdown'
        | 'Field_DateMonthNumber'
        | 'Field_DateSecondDropdown'
        | 'Field_DateSecondNumber'
        | 'Field_DateYearDropdown'
        | 'Field_DateYearNumber'
        | 'Field_Dropdown'
        | 'Field_Email'
        | 'Field_Entries'
        | 'Field_FileUpload'
        | 'Field_Group'
        | 'Field_Heading'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_MultiLineText'
        | 'Field_Name'
        | 'Field_NameFirst'
        | 'Field_NameLast';
};

type Field_Jnu3oeabsAuCrp1Xu5zcodmig9D4b1n2e1Msg5UgqA_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    label?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_NameMiddle'
        | 'Field_NamePrefix'
        | 'Field_Newsletter'
        | 'Field_Number'
        | 'Field_Password'
        | 'Field_Payment'
        | 'Field_Phone'
        | 'Field_Radio'
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section'
        | 'Field_Signature'
        | 'Field_SingleLineText'
        | 'Field_Summary'
        | 'Field_Table'
        | 'Field_Tags'
        | 'Field_Users';
};

export type FieldFragment =
    | Field_Hzc3Bi1iTLaZ5awc86dWgXpcLjlsOnvFh8m0ls1WJcc_Fragment
    | Field_7RakHbpFfOkNmzAv2o5Uby3iG6QFb7mufC2FmKa9Oy_Fragment
    | Field_Jnu3oeabsAuCrp1Xu5zcodmig9D4b1n2e1Msg5UgqA_Fragment;

export type FooterItemsFragment = {
    title?: string | null;
    children: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              menuLink: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename: 'footerLink_Entry' })
    >;
} & { __typename?: 'footerCategory_Entry' };

export type FooterSubmenuFragment = {
    title?: string | null;
    menuLink: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'page_Entry'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelagendaII_Form'
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl2022_Form'
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadDE_Form'
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieScheurkalender_Form'
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaDE_Form'
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
} & { __typename: 'footerLink_Entry' };

type Form_Oadns3IhxLaCuCp78BVgbYFyifg6uqhlFnb0uLbHw_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'FotowedstrijdHondDE_Form'
        | 'FotowedstrijdHondEN_Form'
        | 'FotowedstrijdHondNL_Form'
        | 'FotowedstrijdPerSeizoenDE_Form'
        | 'FotowedstrijdPerSeizoenEN_Form'
        | 'FotowedstrijdPerSeizoen_Form'
        | 'StadswandelingDenBurgNL_Form'
        | 'StadswandelingVeldnamenEN_Form'
        | 'TexelAgendaEvent_Form'
        | 'TexelReisefuhrerherunterladenFamilie_Form'
        | 'TexelReisefuhrerherunterladen_Form'
        | 'WinactieMeetandGreetMaksWAVESFestival_Form'
        | 'WinactieTexelblues2024EN_Form'
        | 'aanmeldenTexelApp2_Form'
        | 'aanmeldenTexelApp3_Form'
        | 'aanmeldenTexelApp_Form'
        | 'aanmeldenTopLedenVoorFotos_Form'
        | 'aanmeldenVvv125Jaar_Form'
        | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
        | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form';
};

type Form_RHdqCh7O0tsBIwqHwZn1Dyrh7gS2i3U1weUslClL0_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
        | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
        | 'aanvraagWandelrouteDurperrondjeDU_Form'
        | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
        | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
        | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
        | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
        | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
        | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
        | 'aanvraagWandelrouteStadswandelingNl_Form'
        | 'aanvraagWandelrouteveldnamenrouteDU_Form'
        | 'aanvraagwerkstukpakket_Form'
        | 'activiteitaanmelden_Form'
        | 'adventskalender2_Form'
        | 'adventskalender4_Form'
        | 'adventskalender5_Form'
        | 'adventskalender9_Form'
        | 'adventskalender14_Form'
        | 'adventskalender15_Form'
        | 'adventskalender16_Form';
};

type Form_3q9exkli9szF3p6est0HxvWwm1VVlPyswdFiaIrW3o_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'adventskalender18_Form'
        | 'adventskalender19_Form'
        | 'adventskalender20_Form'
        | 'adventskalender21_Form'
        | 'adventskalender22_Form'
        | 'adventskalender23_Form'
        | 'adventskalender24_Form'
        | 'avontuurlijkezuidennl2022_Form'
        | 'dataVerwijderenDE_Form'
        | 'dataVerwijderenEN_Form'
        | 'dataVerwijderenNL_Form'
        | 'driveInCinema2024NL_Form'
        | 'driveInCinemaNL_Form'
        | 'durperrondjenl2022_Form'
        | 'eierlandseduinennl2022_Form'
        | 'evenementenkalender2024DE_Form'
        | 'evenementenkalender2024EN_Form'
        | 'evenementenkalenderDownloadNl_Form'
        | 'evenementenkalenderNLoud_Form'
        | 'fietsroutethijsserouteDE2_Form';
};

type Form_OQjDoNmCm2eHgCrPm26aMp6qfKGx5Tiec4Qq48mc1E_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'fietsroutethijsserouteNL2_Form'
        | 'fietsroutethijsseroutedu_Form'
        | 'fietsroutethijsseroutenl_Form'
        | 'fleeceplaidde_Form'
        | 'fleeceplaideng_Form'
        | 'fleeceplaidnl_Form'
        | 'formulierBuienradar_Form'
        | 'fotoVanDeMaandD_Form'
        | 'fotoVanDeMaandEN_Form'
        | 'fotoVanDeMaandNL_Form'
        | 'fotowedstrijdTexelseLuchtenDE_Form'
        | 'fotowedstrijdTexelseLuchtenEN_Form'
        | 'fotowedstrijdTexelseLuchtenNL_Form'
        | 'fotowedstrijdlammetjesEN_Form'
        | 'fotowedstrijdlammetjesdu_Form'
        | 'fotowedstrijdlammetjesnl_Form'
        | 'fotowedstrijdnatuur_Form'
        | 'fotowedstrijdnatuurdu_Form'
        | 'gewinnspielTexelblues2023_Form'
        | 'grotegrazersnl2022_Form';
};

type Form_WvB172rSoEwwxEuGUmxuY1D6nZq1dhHIbHaUeXl9V2w_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'kikkertnl2022_Form'
        | 'kinderroutenl2022_Form'
        | 'klachtformulierDu_Form'
        | 'klachtformuliernl_Form'
        | 'kleurplaatschaapjesdu_Form'
        | 'kleurplaatschaapjesen_Form'
        | 'kleurplaatschapennl_Form'
        | 'kleurplaatstranddu_Form'
        | 'kleurplaatstranden_Form'
        | 'kleurplaatstrandnl_Form'
        | 'kleurplaatvuurtorendu_Form'
        | 'kleurplaatvuurtorenen_Form'
        | 'kleurplaatvuurtorennl_Form'
        | 'kleurplaatzeehonddu_Form'
        | 'kleurplaatzeehonden_Form'
        | 'kleurplaatzeehondnl_Form'
        | 'meeuwenkolonienl2022_Form'
        | 'mindervaliden2_Form'
        | 'mindervaliden_Form'
        | 'muyslufterde2022_Form';
};

type Form_3z6IWmZ9czluqWevqwoqcHmMEwmqCgvnPs7FFiZ4EzE_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'muyslufternl2022_Form'
        | 'nieuwsbriefaanmeldendu_Form'
        | 'nieuwsbriefaanmeldenen_Form'
        | 'nieuwsbriefaanmeldennl_Form'
        | 'persaanvraagEN_Form'
        | 'persverzoek_Form'
        | 'presseanfrage1_Form'
        | 'sloffen1_Form'
        | 'sloffen_Form'
        | 'sommelpoodjenl2022_Form'
        | 'standaardFormulierNl_Form'
        | 'strandvlaktesduinvalleiennl2022_Form'
        | 'test1_Form'
        | 'test_Form'
        | 'testformulierNaUpdateCraft_Form'
        | 'testformulier_Form'
        | 'texelagendaII_Form'
        | 'texelfotoinsturen1_Form'
        | 'texelfotoinsturen_Form'
        | 'texelfotoinsturendu_Form';
};

type Form_CmaKdjRyrXZeUs4Zd1SaDOgHo5BXdiTv8Vns9PjPw4_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'texelgidsNl_Form'
        | 'texelpuzzelde_Form'
        | 'texelpuzzelnl_Form'
        | 'veldnamenNL2024_Form'
        | 'verjaardagWijzigenNl_Form'
        | 'voetsporenvocnl2022_Form'
        | 'voorbeeldformulierMetAlleVelden_Form'
        | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
        | 'wandelroutedemuydu_Form'
        | 'wandelroutedemuynl_Form'
        | 'wandelroutedenhoornNL_Form'
        | 'wandelroutehogebergde2022_Form'
        | 'wandelroutehogebergnl1_Form'
        | 'wandelroutehogebergnl2022_Form'
        | 'wandelroutehogebergnl_Form'
        | 'wandelroutesInDeSporenVanDeVocDu_Form'
        | 'wandelroutesLangsGroteGrazersDu_Form'
        | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
        | 'webshopVvvTexel_Form'
        | 'winEenVerblijfMetDeTexelAppDE_Form';
};

type Form_1lvJkBixnkgcSEz1L5zMmGJtKHjkWz8GiGObR7rinfU_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'winEenVerblijfMetDeTexelAppNL_Form'
        | 'winactieBEpers_Form'
        | 'winactieBroadway2024DE_Form'
        | 'winactieBroadway2024EN_Form'
        | 'winactieBroadway2024NL_Form'
        | 'winactieBute_Form'
        | 'winactieFB14SterrenBEWA_Form'
        | 'winactieFB14SterrenDE_Form'
        | 'winactieFB14SterrenEN_Form'
        | 'winactieFB14SterrenNL_Form'
        | 'winactieFBVideolead2025DE_Form'
        | 'winactieFBVideolead2025EN_Form'
        | 'winactieFBVideolead2025NL_Form'
        | 'winactieFBVideoleadDE_Form'
        | 'winactieFBVideoleadEN1_Form'
        | 'winactieFBVideoleadNL_Form'
        | 'winactieKomTotRustOpTexelDE_Form'
        | 'winactieKomTotRustOpTexelEN_Form'
        | 'winactieKomTotRustOpTexelNL_Form'
        | 'winactieKoopplein_Form';
};

type Form_VvlmwjmZvnbnoq7XjgW41Ubxs8Ky22Fh9ERvFius_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'winactieKunstZomerDE_Form'
        | 'winactieKunstZomer_Form'
        | 'winactieLekkerLokaalDE_Form'
        | 'winactieLekkerLokaalEN_Form'
        | 'winactieLekkerLokaalNL_Form'
        | 'winactieParachutesprongDE_Form'
        | 'winactieParachutesprongEN_Form'
        | 'winactieParachutesprongNL_Form'
        | 'winactieRede_Form'
        | 'winactieRondritTexelDE_Form'
        | 'winactieRondritTexelNL_Form'
        | 'winactieRussstNL_Form'
        | 'winactieScheurkalender1_Form'
        | 'winactieScheurkalender_Form'
        | 'winactieTexelCulinair2024DE_Form'
        | 'winactieTexelCulinairDE_Form'
        | 'winactieTexelCulinairNL2_Form'
        | 'winactieTexelCulinairNL24_Form'
        | 'winactieTexelCulinairNL_Form'
        | 'winactieTexelHalveMarathon2023_Form';
};

type Form_Sivq8sDqE4yK54KNgBr0aAhOnzXxjkFlaAa2H5kxo_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & {
    __typename?:
        | 'winactieTexelHalveMarathon2024_Form'
        | 'winactieTexelHalveMarathon20241_Form'
        | 'winactieTexelHalveMarathon20242_Form'
        | 'winactieTexelHalveMarathon_Form'
        | 'winactieTexelanapantoffelsNL_Form'
        | 'winactieTexelblues2023NL_Form'
        | 'winactieTexelblues2023_Form'
        | 'winactieTexelblues2024DE_Form'
        | 'winactieTexelblues2024NL_Form'
        | 'winactieTexelbues_Form'
        | 'winactieTexelshopDE1_Form'
        | 'winactieTexelshopNL1_Form'
        | 'winactieTexelshopNL_Form'
        | 'winactieThreesAnnaDE_Form'
        | 'winactieThreesAnnaEN_Form'
        | 'winactieThreesAnnaNL_Form'
        | 'winactieWaves2023NL_Form'
        | 'winactieWaves2024DE_Form'
        | 'winactieWaves2024EN_Form'
        | 'winactieWaves2024NL_Form';
};

type Form_RDfUqarqjDzWtxqy2woso1k8atBsTrPe5Io762kIrD0_Fragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              redirectUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    formFields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  required?: boolean | null;
                                  placeholder?: string | null;
                                  handle?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber'
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_NameFirst'
                            | 'Field_NameLast'
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_Summary'
                            | 'Field_Table';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & { __typename?: 'Field_Tags' | 'Field_Users' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'FieldOption';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_Phone' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        maxType?: string | null;
                        max?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Address'
                                      | 'Field_Address1'
                                      | 'Field_Address2'
                                      | 'Field_Address3'
                                      | 'Field_AddressAutoComplete'
                                      | 'Field_AddressCity'
                                      | 'Field_AddressCountry'
                                      | 'Field_AddressState'
                                      | 'Field_AddressZip'
                                      | 'Field_Agree'
                                      | 'Field_Calculations'
                                      | 'Field_Categories'
                                      | 'Field_Checkboxes'
                                      | 'Field_Date'
                                      | 'Field_DateAmPmDropdown'
                                      | 'Field_DateAmPmNumber'
                                      | 'Field_DateDayDropdown'
                                      | 'Field_DateDayNumber'
                                      | 'Field_DateHourDropdown'
                                      | 'Field_DateHourNumber';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_DateMinuteDropdown'
                                      | 'Field_DateMinuteNumber'
                                      | 'Field_DateMonthDropdown'
                                      | 'Field_DateMonthNumber'
                                      | 'Field_DateSecondDropdown'
                                      | 'Field_DateSecondNumber'
                                      | 'Field_DateYearDropdown'
                                      | 'Field_DateYearNumber'
                                      | 'Field_Dropdown'
                                      | 'Field_Email'
                                      | 'Field_Entries'
                                      | 'Field_FileUpload'
                                      | 'Field_Group'
                                      | 'Field_Heading'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MultiLineText'
                                      | 'Field_Name'
                                      | 'Field_NameFirst'
                                      | 'Field_NameLast';
                              })
                            | ({
                                  label?: string | null;
                                  enabled?: boolean | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  placeholder?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_NameMiddle'
                                      | 'Field_NamePrefix'
                                      | 'Field_Newsletter'
                                      | 'Field_Number'
                                      | 'Field_Password'
                                      | 'Field_Payment'
                                      | 'Field_Phone'
                                      | 'Field_Radio'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Signature'
                                      | 'Field_SingleLineText'
                                      | 'Field_Summary'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        label?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: number | null;
                        max?: number | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & { __typename?: 'winactieWebshopDe_Form' | 'winactie_Form' | 'winactiefacebookbdu_Form' | 'winactiefbnl_Form' };

export type FormFragment =
    | Form_Oadns3IhxLaCuCp78BVgbYFyifg6uqhlFnb0uLbHw_Fragment
    | Form_RHdqCh7O0tsBIwqHwZn1Dyrh7gS2i3U1weUslClL0_Fragment
    | Form_3q9exkli9szF3p6est0HxvWwm1VVlPyswdFiaIrW3o_Fragment
    | Form_OQjDoNmCm2eHgCrPm26aMp6qfKGx5Tiec4Qq48mc1E_Fragment
    | Form_WvB172rSoEwwxEuGUmxuY1D6nZq1dhHIbHaUeXl9V2w_Fragment
    | Form_3z6IWmZ9czluqWevqwoqcHmMEwmqCgvnPs7FFiZ4EzE_Fragment
    | Form_CmaKdjRyrXZeUs4Zd1SaDOgHo5BXdiTv8Vns9PjPw4_Fragment
    | Form_1lvJkBixnkgcSEz1L5zMmGJtKHjkWz8GiGObR7rinfU_Fragment
    | Form_VvlmwjmZvnbnoq7XjgW41Ubxs8Ky22Fh9ERvFius_Fragment
    | Form_Sivq8sDqE4yK54KNgBr0aAhOnzXxjkFlaAa2H5kxo_Fragment
    | Form_RDfUqarqjDzWtxqy2woso1k8atBsTrPe5Io762kIrD0_Fragment;

type Highlight_ApEYrF9Ro7sItKx111UgVfk1Qx0TGu5mMsTrEkAlw_Fragment = { title?: string | null; uri?: string | null } & {
    __typename:
        | 'action_Entry'
        | 'agenda_Entry'
        | 'banner_Entry'
        | 'companies_Entry'
        | 'contact2_Entry'
        | 'contact_Entry'
        | 'footerCategory_Entry'
        | 'footerLink_Entry'
        | 'homepage_Entry'
        | 'location_Entry'
        | 'menu_Entry'
        | 'myTexel_Entry'
        | 'news_Entry'
        | 'search_Entry'
        | 'sectionAccommodations_Entry'
        | 'sectionAccordion_Entry'
        | 'sectionAction_Entry'
        | 'sectionActivities_Entry'
        | 'sectionBanner_Entry'
        | 'sectionButton_Entry';
};

type Highlight_FGPfXwfx3M4S2VeiYcvGnxOCu7QkiwoNwlh7UsPam_Fragment = { title?: string | null; uri?: string | null } & {
    __typename:
        | 'sectionCompanies_Entry'
        | 'sectionEmbed_Entry'
        | 'sectionForms_Entry'
        | 'sectionHeader_Entry'
        | 'sectionHierarchicalTiles_Entry'
        | 'sectionImages_Entry'
        | 'sectionMap_Entry'
        | 'sectionText_Entry'
        | 'sectionTiles_Entry'
        | 'sectionTip_Entry'
        | 'sectionTravelbaseTiles_Entry'
        | 'sectionVideo_Entry'
        | 'sectionWebcam_Entry'
        | 'thankYou_Entry'
        | 'tip_Entry'
        | 'voucher_Entry';
};

type Highlight_Article_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    newsHeroImage: Array<
        | ({
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              placeholderUrl?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename: 'article_Entry' };

type Highlight_Page_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    heroImage: Array<
        | ({
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              placeholderUrl?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
} & { __typename: 'page_Entry' };

export type HighlightFragment =
    | Highlight_ApEYrF9Ro7sItKx111UgVfk1Qx0TGu5mMsTrEkAlw_Fragment
    | Highlight_FGPfXwfx3M4S2VeiYcvGnxOCu7QkiwoNwlh7UsPam_Fragment
    | Highlight_Article_Entry_Fragment
    | Highlight_Page_Entry_Fragment;

export type LinkFieldFragment = {
    text?: string | null;
    target?: string | null;
    url?: string | null;
    type?: string | null;
    element?:
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'Address'
                  | 'Element'
                  | 'FotowedstrijdHondDE_Form'
                  | 'FotowedstrijdHondEN_Form'
                  | 'FotowedstrijdHondNL_Form'
                  | 'FotowedstrijdPerSeizoenDE_Form'
                  | 'FotowedstrijdPerSeizoenEN_Form'
                  | 'FotowedstrijdPerSeizoen_Form'
                  | 'StadswandelingDenBurgNL_Form'
                  | 'StadswandelingVeldnamenEN_Form'
                  | 'TexelAgendaEvent_Form'
                  | 'TexelReisefuhrerherunterladenFamilie_Form'
                  | 'TexelReisefuhrerherunterladen_Form'
                  | 'User'
                  | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                  | 'WinactieTexelblues2024EN_Form'
                  | 'aanmeldenTexelApp2_Form'
                  | 'aanmeldenTexelApp3_Form'
                  | 'aanmeldenTexelApp_Form'
                  | 'aanmeldenTopLedenVoorFotos_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'aanmeldenVvv125Jaar_Form'
                  | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                  | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                  | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                  | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                  | 'aanvraagWandelrouteDurperrondjeDU_Form'
                  | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                  | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                  | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                  | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                  | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                  | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                  | 'aanvraagWandelrouteStadswandelingNl_Form'
                  | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                  | 'aanvraagwerkstukpakket_Form'
                  | 'action_Entry'
                  | 'activiteitaanmelden_Form'
                  | 'adventskalender2_Form'
                  | 'adventskalender4_Form'
                  | 'adventskalender5_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'adventskalender9_Form'
                  | 'adventskalender14_Form'
                  | 'adventskalender15_Form'
                  | 'adventskalender16_Form'
                  | 'adventskalender18_Form'
                  | 'adventskalender19_Form'
                  | 'adventskalender20_Form'
                  | 'adventskalender21_Form'
                  | 'adventskalender22_Form'
                  | 'adventskalender23_Form'
                  | 'adventskalender24_Form'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'assetTags_Tag'
                  | 'avontuurlijkezuidennl2022_Form'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'contact_GlobalSet';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'dataVerwijderenDE_Form'
                  | 'dataVerwijderenEN_Form'
                  | 'dataVerwijderenNL_Form'
                  | 'driveInCinema2024NL_Form'
                  | 'driveInCinemaNL_Form'
                  | 'durperrondjenl2022_Form'
                  | 'eierlandseduinennl2022_Form'
                  | 'evenementenkalender2024DE_Form'
                  | 'evenementenkalender2024EN_Form'
                  | 'evenementenkalenderDownloadNl_Form'
                  | 'evenementenkalenderNLoud_Form'
                  | 'fietsroutethijsserouteDE2_Form'
                  | 'fietsroutethijsserouteNL2_Form'
                  | 'fietsroutethijsseroutedu_Form'
                  | 'fietsroutethijsseroutenl_Form'
                  | 'fleeceplaidde_Form'
                  | 'fleeceplaideng_Form'
                  | 'fleeceplaidnl_Form'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'formulierBuienradar_Form'
                  | 'fotoVanDeMaandD_Form'
                  | 'fotoVanDeMaandEN_Form'
                  | 'fotoVanDeMaandNL_Form'
                  | 'fotowedstrijdTexelseLuchtenDE_Form'
                  | 'fotowedstrijdTexelseLuchtenEN_Form'
                  | 'fotowedstrijdTexelseLuchtenNL_Form'
                  | 'fotowedstrijdlammetjesEN_Form'
                  | 'fotowedstrijdlammetjesdu_Form'
                  | 'fotowedstrijdlammetjesnl_Form'
                  | 'fotowedstrijdnatuur_Form'
                  | 'fotowedstrijdnatuurdu_Form'
                  | 'gewinnspielTexelblues2023_Form'
                  | 'grotegrazersnl2022_Form'
                  | 'homepage_Entry'
                  | 'kikkertnl2022_Form'
                  | 'kinderroutenl2022_Form'
                  | 'klachtformulierDu_Form'
                  | 'klachtformuliernl_Form'
                  | 'kleurplaatschaapjesdu_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'kleurplaatschaapjesen_Form'
                  | 'kleurplaatschapennl_Form'
                  | 'kleurplaatstranddu_Form'
                  | 'kleurplaatstranden_Form'
                  | 'kleurplaatstrandnl_Form'
                  | 'kleurplaatvuurtorendu_Form'
                  | 'kleurplaatvuurtorenen_Form'
                  | 'kleurplaatvuurtorennl_Form'
                  | 'kleurplaatzeehonddu_Form'
                  | 'kleurplaatzeehonden_Form'
                  | 'kleurplaatzeehondnl_Form'
                  | 'library_Asset'
                  | 'location_Entry'
                  | 'meeuwenkolonienl2022_Form'
                  | 'menu_Entry'
                  | 'mindervaliden2_Form'
                  | 'mindervaliden_Form'
                  | 'muyslufterde2022_Form'
                  | 'muyslufternl2022_Form'
                  | 'myTexel_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'news_Entry'
                  | 'nieuwsbriefaanmeldendu_Form'
                  | 'nieuwsbriefaanmeldenen_Form'
                  | 'nieuwsbriefaanmeldennl_Form'
                  | 'notification_GlobalSet'
                  | 'page_Entry'
                  | 'persaanvraagEN_Form'
                  | 'persverzoek_Form'
                  | 'presseanfrage1_Form'
                  | 'searchTags_Tag'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'sloffen1_Form'
                  | 'sloffen_Form'
                  | 'sommelpoodjenl2022_Form'
                  | 'standaardFormulierNl_Form'
                  | 'strandvlaktesduinvalleiennl2022_Form'
                  | 'synonyms_Tag'
                  | 'test1_Form'
                  | 'test_Form'
                  | 'testformulierNaUpdateCraft_Form'
                  | 'testformulier_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'texelagendaII_Form'
                  | 'texelfotoinsturen1_Form'
                  | 'texelfotoinsturen_Form'
                  | 'texelfotoinsturendu_Form'
                  | 'texelgidsNl_Form'
                  | 'texelpuzzelde_Form'
                  | 'texelpuzzelnl_Form'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'veldnamenNL2024_Form'
                  | 'verjaardagWijzigenNl_Form'
                  | 'voetsporenvocnl2022_Form'
                  | 'voorbeeldformulierMetAlleVelden_Form'
                  | 'voucher_Entry'
                  | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                  | 'wandelroutedemuydu_Form'
                  | 'wandelroutedemuynl_Form'
                  | 'wandelroutedenhoornNL_Form'
                  | 'wandelroutehogebergde2022_Form'
                  | 'wandelroutehogebergnl1_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'wandelroutehogebergnl2022_Form'
                  | 'wandelroutehogebergnl_Form'
                  | 'wandelroutesInDeSporenVanDeVocDu_Form'
                  | 'wandelroutesLangsGroteGrazersDu_Form'
                  | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                  | 'webshopVvvTexel_Form'
                  | 'winEenVerblijfMetDeTexelAppDE_Form'
                  | 'winEenVerblijfMetDeTexelAppNL_Form'
                  | 'winactieBEpers_Form'
                  | 'winactieBroadway2024DE_Form'
                  | 'winactieBroadway2024EN_Form'
                  | 'winactieBroadway2024NL_Form'
                  | 'winactieBute_Form'
                  | 'winactieFB14SterrenBEWA_Form'
                  | 'winactieFB14SterrenDE_Form'
                  | 'winactieFB14SterrenEN_Form'
                  | 'winactieFB14SterrenNL_Form'
                  | 'winactieFBVideolead2025DE_Form'
                  | 'winactieFBVideolead2025EN_Form'
                  | 'winactieFBVideolead2025NL_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'winactieFBVideoleadDE_Form'
                  | 'winactieFBVideoleadEN1_Form'
                  | 'winactieFBVideoleadNL_Form'
                  | 'winactieKomTotRustOpTexelDE_Form'
                  | 'winactieKomTotRustOpTexelEN_Form'
                  | 'winactieKomTotRustOpTexelNL_Form'
                  | 'winactieKoopplein_Form'
                  | 'winactieKunstZomerDE_Form'
                  | 'winactieKunstZomer_Form'
                  | 'winactieLekkerLokaalDE_Form'
                  | 'winactieLekkerLokaalEN_Form'
                  | 'winactieLekkerLokaalNL_Form'
                  | 'winactieParachutesprongDE_Form'
                  | 'winactieParachutesprongEN_Form'
                  | 'winactieParachutesprongNL_Form'
                  | 'winactieRede_Form'
                  | 'winactieRondritTexelDE_Form'
                  | 'winactieRondritTexelNL_Form'
                  | 'winactieRussstNL_Form'
                  | 'winactieScheurkalender1_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'winactieScheurkalender_Form'
                  | 'winactieTexelCulinair2024DE_Form'
                  | 'winactieTexelCulinairDE_Form'
                  | 'winactieTexelCulinairNL2_Form'
                  | 'winactieTexelCulinairNL24_Form'
                  | 'winactieTexelCulinairNL_Form'
                  | 'winactieTexelHalveMarathon2023_Form'
                  | 'winactieTexelHalveMarathon2024_Form'
                  | 'winactieTexelHalveMarathon20241_Form'
                  | 'winactieTexelHalveMarathon20242_Form'
                  | 'winactieTexelHalveMarathon_Form'
                  | 'winactieTexelanapantoffelsNL_Form'
                  | 'winactieTexelblues2023NL_Form'
                  | 'winactieTexelblues2023_Form'
                  | 'winactieTexelblues2024DE_Form'
                  | 'winactieTexelblues2024NL_Form'
                  | 'winactieTexelbues_Form'
                  | 'winactieTexelshopDE1_Form'
                  | 'winactieTexelshopNL1_Form'
                  | 'winactieTexelshopNL_Form';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'winactieThreesAnnaDE_Form'
                  | 'winactieThreesAnnaEN_Form'
                  | 'winactieThreesAnnaNL_Form'
                  | 'winactieWaves2023NL_Form'
                  | 'winactieWaves2024DE_Form'
                  | 'winactieWaves2024EN_Form'
                  | 'winactieWaves2024NL_Form'
                  | 'winactieWebshopDe_Form'
                  | 'winactie_Form'
                  | 'winactiefacebookbdu_Form'
                  | 'winactiefbnl_Form';
          })
        | null;
} & {
    __typename?:
        | 'button_Asset_LinkType'
        | 'button_Category_LinkType'
        | 'button_Custom_LinkType'
        | 'button_Email_LinkType'
        | 'button_Embed_LinkType'
        | 'button_Entry_LinkType'
        | 'button_Phone_LinkType'
        | 'button_Site_LinkType'
        | 'button_Url_LinkType'
        | 'button_User_LinkType'
        | 'menuLink_Asset_LinkType'
        | 'menuLink_Category_LinkType'
        | 'menuLink_Custom_LinkType'
        | 'menuLink_Email_LinkType'
        | 'menuLink_Embed_LinkType'
        | 'menuLink_Entry_LinkType'
        | 'menuLink_Phone_LinkType'
        | 'menuLink_Site_LinkType'
        | 'menuLink_Url_LinkType'
        | 'menuLink_User_LinkType';
};

export type MenuItemsFragment = {
    id?: string | null;
    title?: string | null;
    menuLink: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form'
                            | 'texelagendaII_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form'
                            | 'wandelroutehogebergnl2022_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form'
                            | 'winactieFBVideoleadDE_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form'
                            | 'winactieScheurkalender_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form'
                            | 'winactieThreesAnnaDE_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        slug?: string | null;
                        id?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            }
                                                          | {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & { __typename?: 'page_Entry' })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
} & { __typename?: 'menu_Entry' };

export type MenuItemPageRecursiveFragment = {
    children: Array<
        | ({
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
          } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          })
        | ({
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
          } & {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({
              slug?: string | null;
              id?: string | null;
              title?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'page_Entry' })
    >;
} & { __typename?: 'page_Entry' };

export type MenuItemPageFieldsFragment = {
    slug?: string | null;
    id?: string | null;
    title?: string | null;
    uri?: string | null;
    settingsMenuHidden?: boolean | null;
    settingsMenuTitle?: string | null;
    parent?:
        | ({ uri?: string | null; id?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ uri?: string | null; id?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null;
} & { __typename?: 'page_Entry' };

export type NotificationFragment = {
    header?: string | null;
    active?: boolean | null;
    enabled?: boolean | null;
    description?: string | null;
    button: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'page_Entry'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelagendaII_Form'
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl2022_Form'
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadDE_Form'
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieScheurkalender_Form'
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaDE_Form'
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
} & { __typename?: 'notification_GlobalSet' };

export type PhotosCarouselGalleryFragment = {
    assetSource?: string | null;
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension: string;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type ReadMoreQueryVariables = Exact<{
    parentId?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['Int']['input']>;
    q?: InputMaybe<Scalars['String']['input']>;
    notId?: InputMaybe<
        Array<InputMaybe<Scalars['QueryArgument']['input']>> | InputMaybe<Scalars['QueryArgument']['input']>
    >;
    lang?: InputMaybe<Scalars['String']['input']>;
}>;

export type ReadMoreQuery = {
    siblings?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              newsHeroImage: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              heroImage: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename: 'page_Entry' })
        | null
    > | null;
    ancestors?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              newsHeroImage: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              heroImage: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename: 'page_Entry' })
        | null
    > | null;
    search?: Array<
        | ({
              postDate?: string | null;
              title?: string | null;
              type?: string | null;
              uri?: string | null;
              image?:
                  | ({
                        extension?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        title?: string | null;
                        url?: string | null;
                    } & { __typename?: 'SearchImage' })
                  | null;
          } & { __typename?: 'SearchType' })
        | null
    > | null;
} & { __typename?: 'Query' };

export type RentalUnitsQueryVariables = Exact<{
    slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RentalUnitsQuery = {
    rentalUnits: Array<
        {
            id: string;
            name: string;
            slug: string;
            type: RentalUnitTypeEnum;
            amountBedrooms?: number | null;
            maxOccupancy: number;
            tagline: string;
            accommodation: { name: string; hasPublicPage: boolean; place: string } & { __typename?: 'Accommodation' };
            reviewStats?: ({ average: number } & { __typename?: 'ReviewStats' }) | null;
            webpListImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
            listImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
        } & { __typename?: 'RentalUnit' }
    >;
} & { __typename?: 'Query' };

export type RentalUnitFragment = {
    id: string;
    name: string;
    slug: string;
    type: RentalUnitTypeEnum;
    amountBedrooms?: number | null;
    maxOccupancy: number;
    tagline: string;
    accommodation: { name: string; hasPublicPage: boolean; place: string } & { __typename?: 'Accommodation' };
    reviewStats?: ({ average: number } & { __typename?: 'ReviewStats' }) | null;
    webpListImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
    listImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
} & { __typename?: 'RentalUnit' };

export type ListImageFragment = { placeholder?: string | null; srcSet: string; src: string } & {
    __typename?: 'ImageTransform';
};

export type SearchFieldQueryVariables = Exact<{
    locale: Scalars['String']['input'];
    q: Scalars['String']['input'];
}>;

export type SearchFieldQuery = {
    globalSearch?:
        | ({
              totalHits?: number | null;
              hits?: Array<
                  | ({
                        postDate?: string | null;
                        title?: string | null;
                        type?: string | null;
                        uri?: string | null;
                        image?:
                            | ({
                                  extension?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                              } & { __typename?: 'SearchImage' })
                            | null;
                    } & { __typename?: 'SearchType' })
                  | null
              > | null;
          } & { __typename?: 'SearchResult' })
        | null;
} & { __typename?: 'Query' };

export type UpcomingReservationsQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingReservationsQuery = {
    viewer?:
        | ({
              id?: string | null;
              orders: Array<
                  {
                      id?: string | null;
                      number?: string | null;
                      createdDate?: string | null;
                      paymentOptions?:
                          | ({ fullPaymentDueDate?: string | null; totalAmountDue: number } & {
                                __typename?: 'PaymentOptions';
                            })
                          | null;
                      orderItems: Array<
                          | ({
                                downloadVoucherUrl?: string | null;
                                status: BookingStatus;
                                requiresApproval: boolean;
                                amountAdults: number;
                                amountPets: number;
                                amountChildren: number;
                                amountBabies: number;
                                amountYouths: number;
                                arrivalDate: string;
                                departureDate: string;
                                handleDepositPayment: boolean;
                                deposit: number;
                                paymentPrice: number;
                                rentalUnit?:
                                    | ({
                                          id: string;
                                          code: string;
                                          name: string;
                                          slug: string;
                                          type: RentalUnitTypeEnum;
                                          maxOccupancy: number;
                                          amountBedrooms?: number | null;
                                          accommodation: {
                                              id: string;
                                              hasPublicPage: boolean;
                                              name: string;
                                              place: string;
                                              checkInEndTime: string;
                                              checkInStartTime: string;
                                              checkOutTime: string;
                                              type: AccommodationTypeEnum;
                                          } & { __typename?: 'Accommodation' };
                                          jpegThumbnail: {
                                              transform?:
                                                  | ({
                                                        placeholder?: string | null;
                                                        srcSet: string;
                                                        src: string;
                                                        ratio: number;
                                                    } & { __typename?: 'ImageTransform' })
                                                  | null;
                                          } & { __typename?: 'GenericImage' };
                                          webpThumbnail: {
                                              transform?:
                                                  | ({
                                                        placeholder?: string | null;
                                                        srcSet: string;
                                                        src: string;
                                                        ratio: number;
                                                    } & { __typename?: 'ImageTransform' })
                                                  | null;
                                          } & { __typename?: 'GenericImage' };
                                      } & { __typename?: 'RentalUnit' })
                                    | null;
                            } & { __typename?: 'Booking' })
                          | { __typename?: 'Ticket' | 'UpsellPurchase' }
                      >;
                  } & { __typename?: 'Order' }
              >;
          } & { __typename?: 'Customer' })
        | null;
} & { __typename?: 'Query' };

export type MyOrderFragment = {
    id?: string | null;
    number?: string | null;
    createdDate?: string | null;
    paymentOptions?:
        | ({ fullPaymentDueDate?: string | null; totalAmountDue: number } & { __typename?: 'PaymentOptions' })
        | null;
    orderItems: Array<
        | ({
              downloadVoucherUrl?: string | null;
              status: BookingStatus;
              requiresApproval: boolean;
              amountAdults: number;
              amountPets: number;
              amountChildren: number;
              amountBabies: number;
              amountYouths: number;
              arrivalDate: string;
              departureDate: string;
              handleDepositPayment: boolean;
              deposit: number;
              paymentPrice: number;
              rentalUnit?:
                  | ({
                        id: string;
                        code: string;
                        name: string;
                        slug: string;
                        type: RentalUnitTypeEnum;
                        maxOccupancy: number;
                        amountBedrooms?: number | null;
                        accommodation: {
                            id: string;
                            hasPublicPage: boolean;
                            name: string;
                            place: string;
                            checkInEndTime: string;
                            checkInStartTime: string;
                            checkOutTime: string;
                            type: AccommodationTypeEnum;
                        } & { __typename?: 'Accommodation' };
                        jpegThumbnail: {
                            transform?:
                                | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                                      __typename?: 'ImageTransform';
                                  })
                                | null;
                        } & { __typename?: 'GenericImage' };
                        webpThumbnail: {
                            transform?:
                                | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                                      __typename?: 'ImageTransform';
                                  })
                                | null;
                        } & { __typename?: 'GenericImage' };
                    } & { __typename?: 'RentalUnit' })
                  | null;
          } & { __typename?: 'Booking' })
        | { __typename?: 'Ticket' | 'UpsellPurchase' }
    >;
} & { __typename?: 'Order' };

type OrderItem_Booking_Fragment = {
    downloadVoucherUrl?: string | null;
    status: BookingStatus;
    requiresApproval: boolean;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    handleDepositPayment: boolean;
    deposit: number;
    paymentPrice: number;
    rentalUnit?:
        | ({
              id: string;
              code: string;
              name: string;
              slug: string;
              type: RentalUnitTypeEnum;
              maxOccupancy: number;
              amountBedrooms?: number | null;
              accommodation: {
                  id: string;
                  hasPublicPage: boolean;
                  name: string;
                  place: string;
                  checkInEndTime: string;
                  checkInStartTime: string;
                  checkOutTime: string;
                  type: AccommodationTypeEnum;
              } & { __typename?: 'Accommodation' };
              jpegThumbnail: {
                  transform?:
                      | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                            __typename?: 'ImageTransform';
                        })
                      | null;
              } & { __typename?: 'GenericImage' };
              webpThumbnail: {
                  transform?:
                      | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                            __typename?: 'ImageTransform';
                        })
                      | null;
              } & { __typename?: 'GenericImage' };
          } & { __typename?: 'RentalUnit' })
        | null;
} & { __typename?: 'Booking' };

type OrderItem_Ticket_UpsellPurchase_Fragment = { __typename?: 'Ticket' | 'UpsellPurchase' };

export type OrderItemFragment = OrderItem_Booking_Fragment | OrderItem_Ticket_UpsellPurchase_Fragment;

export type TransformFragment = { placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
    __typename?: 'ImageTransform';
};

export type BannerFragment = {
    title?: string | null;
    heroImage: Array<({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null>;
    button: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'page_Entry'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelagendaII_Form'
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl2022_Form'
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadDE_Form'
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieScheurkalender_Form'
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaDE_Form'
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
} & { __typename?: 'banner_Entry' };

export type PinsFragment = {
    latitude?: any | null;
    longitude?: any | null;
    symbol?: string | null;
    label?: string | null;
} & { __typename?: 'pins_TableRow' };

export type TilesBlockFragment = {
    tilesChildren?: boolean | null;
    tilesSiblings?: boolean | null;
    tilesEntries: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'banner_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'page_Entry' })
        | null
    >;
} & { __typename?: 'sectionTiles_Entry' };

export type HierarchicalTilesBlockFragment = { relationType?: string | null } & {
    __typename?: 'sectionHierarchicalTiles_Entry';
};

export type TipFragment = {
    title?: string | null;
    tipText?: string | null;
    tipName?: string | null;
    tipRole?: string | null;
    tipAvatar: Array<
        | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
              __typename?: 'library_Asset';
          })
        | null
    >;
} & { __typename?: 'tip_Entry' };

export type RentalUnitsBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RentalUnitsBySlugQuery = {
    rentalUnits: Array<
        {
            id: string;
            name: string;
            slug: string;
            tagline: string;
            webpListImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
            listImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
        } & { __typename?: 'RentalUnit' }
    >;
} & { __typename?: 'Query' };

export type CompaniesBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CompaniesBySlugQuery = {
    companiesBySlug: Array<
        {
            id: string;
            name: string;
            slug: string;
            description?: string | null;
            webpListImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
            listImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
        } & { __typename?: 'Company' }
    >;
} & { __typename?: 'Query' };

export type ActivitiesBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ActivitiesBySlugQuery = {
    activitiesBySlug: Array<
        {
            id: string;
            name: string;
            slug: string;
            description?: string | null;
            webpListImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
            listImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
        } & { __typename?: 'Activity' }
    >;
} & { __typename?: 'Query' };

export type AccommodationsBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AccommodationsBySlugQuery = {
    accommodationsBySlug: Array<
        {
            id: string;
            name: string;
            slug: string;
            descriptionGeneral?: string | null;
            webpListImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
            mainImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
        } & { __typename?: 'Accommodation' }
    >;
} & { __typename?: 'Query' };

export type RentalUnitsBySlugFragment = {
    id: string;
    name: string;
    slug: string;
    tagline: string;
    webpListImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
    listImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
} & { __typename?: 'RentalUnit' };

export type AccommodationsBySlugFragment = {
    id: string;
    name: string;
    slug: string;
    descriptionGeneral?: string | null;
    webpListImage?:
        | ({
              transform?:
                  | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                  | null;
          } & { __typename?: 'GenericImage' })
        | null;
    mainImage?:
        | ({
              transform?:
                  | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                  | null;
          } & { __typename?: 'GenericImage' })
        | null;
} & { __typename?: 'Accommodation' };

export type AddressFieldFragment = {
    fields?: Array<
        | ({
              label?: string | null;
              enabled?: boolean | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              required?: boolean | null;
              placeholder?: string | null;
              handle?: string | null;
          } & {
              __typename?:
                  | 'Field_Address'
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Agree'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_Checkboxes'
                  | 'Field_Date'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              required?: boolean | null;
              placeholder?: string | null;
              handle?: string | null;
          } & {
              __typename?:
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber'
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Dropdown'
                  | 'Field_Email'
                  | 'Field_Entries'
                  | 'Field_FileUpload'
                  | 'Field_Group'
                  | 'Field_Heading'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_MultiLineText'
                  | 'Field_Name'
                  | 'Field_NameFirst'
                  | 'Field_NameLast';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              required?: boolean | null;
              placeholder?: string | null;
              handle?: string | null;
          } & {
              __typename?:
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Newsletter'
                  | 'Field_Number'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Phone'
                  | 'Field_Radio'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_SingleLineText'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags'
                  | 'Field_Users';
          })
        | null
    > | null;
} & { __typename?: 'Field_Address' };

type AgreeField_Gddqf4W8YMaIo3b5qir3BvikJpG9V4CnBaxCg88_Fragment = {
    __typename?:
        | 'Field_Address'
        | 'Field_Address1'
        | 'Field_Address2'
        | 'Field_Address3'
        | 'Field_AddressAutoComplete'
        | 'Field_AddressCity'
        | 'Field_AddressCountry'
        | 'Field_AddressState'
        | 'Field_AddressZip'
        | 'Field_Calculations'
        | 'Field_Categories'
        | 'Field_Checkboxes'
        | 'Field_Date'
        | 'Field_DateAmPmDropdown'
        | 'Field_DateAmPmNumber'
        | 'Field_DateDayDropdown'
        | 'Field_DateDayNumber'
        | 'Field_DateHourDropdown'
        | 'Field_DateHourNumber'
        | 'Field_DateMinuteDropdown';
};

type AgreeField_9ScLjubCmqcXuc5IuCIh4SeiTyApSqhV2muLmia4vYy_Fragment = {
    __typename?:
        | 'Field_DateMinuteNumber'
        | 'Field_DateMonthDropdown'
        | 'Field_DateMonthNumber'
        | 'Field_DateSecondDropdown'
        | 'Field_DateSecondNumber'
        | 'Field_DateYearDropdown'
        | 'Field_DateYearNumber'
        | 'Field_Dropdown'
        | 'Field_Email'
        | 'Field_Entries'
        | 'Field_FileUpload'
        | 'Field_Group'
        | 'Field_Heading'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_MultiLineText'
        | 'Field_Name'
        | 'Field_NameFirst'
        | 'Field_NameLast'
        | 'Field_NameMiddle';
};

type AgreeField_3q49Ir60k7ft7hkarRidNjOsPi3n1dNizcpcmeoULss_Fragment = {
    __typename?:
        | 'Field_NamePrefix'
        | 'Field_Number'
        | 'Field_Password'
        | 'Field_Payment'
        | 'Field_Phone'
        | 'Field_Radio'
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section'
        | 'Field_Signature'
        | 'Field_SingleLineText'
        | 'Field_Summary'
        | 'Field_Table'
        | 'Field_Tags'
        | 'Field_Users';
};

type AgreeField_Field_Agree_Field_Newsletter_Fragment = {
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
    required?: boolean | null;
} & { __typename?: 'Field_Agree' | 'Field_Newsletter' };

export type AgreeFieldFragment =
    | AgreeField_Gddqf4W8YMaIo3b5qir3BvikJpG9V4CnBaxCg88_Fragment
    | AgreeField_9ScLjubCmqcXuc5IuCIh4SeiTyApSqhV2muLmia4vYy_Fragment
    | AgreeField_3q49Ir60k7ft7hkarRidNjOsPi3n1dNizcpcmeoULss_Fragment
    | AgreeField_Field_Agree_Field_Newsletter_Fragment;

export type BaseAgreeFieldFragment = {
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
    required?: boolean | null;
} & { __typename?: 'Field_Agree' };

export type NewsletterAgreeFieldFragment = {
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
    required?: boolean | null;
} & { __typename?: 'Field_Newsletter' };

export type CheckboxesFieldFragment = {
    options?: Array<
        ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
    > | null;
} & { __typename?: 'Field_Checkboxes' };

export type DateFieldFragment = {
    displayType?: string | null;
    defaultDate?: any | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Date' };

export type DropdownFieldFragment = {
    options?: Array<
        | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
              __typename?: 'FieldOption';
          })
        | null
    > | null;
} & { __typename?: 'Field_Dropdown' };

export type FileFieldFragment = {
    allowedKinds?: Array<string | null> | null;
    limitFiles?: string | null;
    sizeLimit?: string | null;
    sizeMinLimit?: string | null;
} & { __typename?: 'Field_FileUpload' };

export type GroupFieldFragment = {
    fields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        required?: boolean | null;
                        placeholder?: string | null;
                        handle?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber'
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Entries'
                  | 'Field_Group'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_NameFirst'
                  | 'Field_NameLast'
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_Summary'
                  | 'Field_Table';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & { __typename?: 'Field_Tags' | 'Field_Users' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'FieldOption';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_Phone' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              maxType?: string | null;
              max?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Address'
                            | 'Field_Address1'
                            | 'Field_Address2'
                            | 'Field_Address3'
                            | 'Field_AddressAutoComplete'
                            | 'Field_AddressCity'
                            | 'Field_AddressCountry'
                            | 'Field_AddressState'
                            | 'Field_AddressZip'
                            | 'Field_Agree'
                            | 'Field_Calculations'
                            | 'Field_Categories'
                            | 'Field_Checkboxes'
                            | 'Field_Date'
                            | 'Field_DateAmPmDropdown'
                            | 'Field_DateAmPmNumber'
                            | 'Field_DateDayDropdown'
                            | 'Field_DateDayNumber'
                            | 'Field_DateHourDropdown'
                            | 'Field_DateHourNumber';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_DateMinuteDropdown'
                            | 'Field_DateMinuteNumber'
                            | 'Field_DateMonthDropdown'
                            | 'Field_DateMonthNumber'
                            | 'Field_DateSecondDropdown'
                            | 'Field_DateSecondNumber'
                            | 'Field_DateYearDropdown'
                            | 'Field_DateYearNumber'
                            | 'Field_Dropdown'
                            | 'Field_Email'
                            | 'Field_Entries'
                            | 'Field_FileUpload'
                            | 'Field_Group'
                            | 'Field_Heading'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MultiLineText'
                            | 'Field_Name'
                            | 'Field_NameFirst'
                            | 'Field_NameLast';
                    })
                  | ({
                        label?: string | null;
                        enabled?: boolean | null;
                        required?: boolean | null;
                        handle?: string | null;
                        placeholder?: string | null;
                    } & {
                        __typename?:
                            | 'Field_NameMiddle'
                            | 'Field_NamePrefix'
                            | 'Field_Newsletter'
                            | 'Field_Number'
                            | 'Field_Password'
                            | 'Field_Payment'
                            | 'Field_Phone'
                            | 'Field_Radio'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Signature'
                            | 'Field_SingleLineText'
                            | 'Field_Summary'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              label?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: number | null;
              max?: number | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & { __typename?: 'Field_Group' };

export type HeadingFieldFragment = { headingSize?: string | null } & { __typename?: 'Field_Heading' };

export type NameFieldFragment = {
    fields?: Array<
        | ({
              label?: string | null;
              enabled?: boolean | null;
              required?: boolean | null;
              handle?: string | null;
              placeholder?: string | null;
          } & {
              __typename?:
                  | 'Field_Address'
                  | 'Field_Address1'
                  | 'Field_Address2'
                  | 'Field_Address3'
                  | 'Field_AddressAutoComplete'
                  | 'Field_AddressCity'
                  | 'Field_AddressCountry'
                  | 'Field_AddressState'
                  | 'Field_AddressZip'
                  | 'Field_Agree'
                  | 'Field_Calculations'
                  | 'Field_Categories'
                  | 'Field_Checkboxes'
                  | 'Field_Date'
                  | 'Field_DateAmPmDropdown'
                  | 'Field_DateAmPmNumber'
                  | 'Field_DateDayDropdown'
                  | 'Field_DateDayNumber'
                  | 'Field_DateHourDropdown'
                  | 'Field_DateHourNumber';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              required?: boolean | null;
              handle?: string | null;
              placeholder?: string | null;
          } & {
              __typename?:
                  | 'Field_DateMinuteDropdown'
                  | 'Field_DateMinuteNumber'
                  | 'Field_DateMonthDropdown'
                  | 'Field_DateMonthNumber'
                  | 'Field_DateSecondDropdown'
                  | 'Field_DateSecondNumber'
                  | 'Field_DateYearDropdown'
                  | 'Field_DateYearNumber'
                  | 'Field_Dropdown'
                  | 'Field_Email'
                  | 'Field_Entries'
                  | 'Field_FileUpload'
                  | 'Field_Group'
                  | 'Field_Heading'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_MultiLineText'
                  | 'Field_Name'
                  | 'Field_NameFirst'
                  | 'Field_NameLast';
          })
        | ({
              label?: string | null;
              enabled?: boolean | null;
              required?: boolean | null;
              handle?: string | null;
              placeholder?: string | null;
          } & {
              __typename?:
                  | 'Field_NameMiddle'
                  | 'Field_NamePrefix'
                  | 'Field_Newsletter'
                  | 'Field_Number'
                  | 'Field_Password'
                  | 'Field_Payment'
                  | 'Field_Phone'
                  | 'Field_Radio'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Signature'
                  | 'Field_SingleLineText'
                  | 'Field_Summary'
                  | 'Field_Table'
                  | 'Field_Tags'
                  | 'Field_Users';
          })
        | null
    > | null;
} & { __typename?: 'Field_Name' };

export type NumberFieldFragment = { defaultValue?: string | null; min?: number | null; max?: number | null } & {
    __typename?: 'Field_Number';
};

export type RadioFieldFragment = {
    options?: Array<
        ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'FieldOption' }) | null
    > | null;
} & { __typename?: 'Field_Radio' };

type TextField_Hzc3Bi1iTLaZ5awc86dWgXpcLjlsOnvFh8m0ls1WJcc_Fragment = {
    __typename?:
        | 'Field_Address'
        | 'Field_Address1'
        | 'Field_Address2'
        | 'Field_Address3'
        | 'Field_AddressAutoComplete'
        | 'Field_AddressCity'
        | 'Field_AddressCountry'
        | 'Field_AddressState'
        | 'Field_AddressZip'
        | 'Field_Agree'
        | 'Field_Calculations'
        | 'Field_Categories'
        | 'Field_Checkboxes'
        | 'Field_Date'
        | 'Field_DateAmPmDropdown'
        | 'Field_DateAmPmNumber'
        | 'Field_DateDayDropdown'
        | 'Field_DateDayNumber'
        | 'Field_DateHourDropdown'
        | 'Field_DateHourNumber';
};

type TextField_XejVssnahmgMucsLkrbQv6KkiG0NmKbWznB7dsua4_Fragment = {
    __typename?:
        | 'Field_DateMinuteDropdown'
        | 'Field_DateMinuteNumber'
        | 'Field_DateMonthDropdown'
        | 'Field_DateMonthNumber'
        | 'Field_DateSecondDropdown'
        | 'Field_DateSecondNumber'
        | 'Field_DateYearDropdown'
        | 'Field_DateYearNumber'
        | 'Field_Dropdown'
        | 'Field_Entries'
        | 'Field_FileUpload'
        | 'Field_Group'
        | 'Field_Heading'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_Name'
        | 'Field_NameFirst'
        | 'Field_NameLast'
        | 'Field_NameMiddle'
        | 'Field_NamePrefix';
};

type TextField_P4gVhg0QcC6dnNs8rmuJmJ498WjZp9DvU2n3hSd7B8_Fragment = {
    __typename?:
        | 'Field_Newsletter'
        | 'Field_Number'
        | 'Field_Password'
        | 'Field_Payment'
        | 'Field_Radio'
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section'
        | 'Field_Signature'
        | 'Field_Summary'
        | 'Field_Table'
        | 'Field_Tags'
        | 'Field_Users';
};

type TextField_Field_Email_Field_Phone_Fragment = { defaultValue?: string | null } & {
    __typename?: 'Field_Email' | 'Field_Phone';
};

type TextField_Field_MultiLineText_Field_SingleLineText_Fragment = {
    limit?: boolean | null;
    maxType?: string | null;
    max?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_MultiLineText' | 'Field_SingleLineText' };

export type TextFieldFragment =
    | TextField_Hzc3Bi1iTLaZ5awc86dWgXpcLjlsOnvFh8m0ls1WJcc_Fragment
    | TextField_XejVssnahmgMucsLkrbQv6KkiG0NmKbWznB7dsua4_Fragment
    | TextField_P4gVhg0QcC6dnNs8rmuJmJ498WjZp9DvU2n3hSd7B8_Fragment
    | TextField_Field_Email_Field_Phone_Fragment
    | TextField_Field_MultiLineText_Field_SingleLineText_Fragment;

export type SingleTextFieldFragment = {
    limit?: boolean | null;
    maxType?: string | null;
    max?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_SingleLineText' };

export type MultipleTextFieldFragment = {
    limit?: boolean | null;
    maxType?: string | null;
    max?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_MultiLineText' };

export type EmailTextFieldFragment = { defaultValue?: string | null } & { __typename?: 'Field_Email' };

export type PhoneTextFieldFragment = { defaultValue?: string | null } & { __typename?: 'Field_Phone' };

export type ArticleQueryVariables = Exact<{
    slug?: InputMaybe<Scalars['String']['input']>;
    lang?: InputMaybe<Scalars['String']['input']>;
    notSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type ArticleQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              postDate?: any | null;
              title?: string | null;
              textBody?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              id?: string | null;
              newsHeroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
              gallery: Array<
                  | ({
                        assetSource?: string | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
              parent?:
                  | ({ id?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ id?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | null;
              settingsSearchTags: Array<
                  | ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' })
                  | null
              >;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'article_Entry' })
        | null;
    related?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'banner_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'page_Entry' })
        | null
    > | null;
} & { __typename?: 'Query' };

export type ArticlePathsQueryVariables = Exact<{ [key: string]: never }>;

export type ArticlePathsQuery = {
    entries?: Array<
        | ({ slug?: string | null; language?: string | null } & {
              __typename:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ slug?: string | null; language?: string | null } & {
              __typename:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'Query' };

export type ArticleFragment = {
    postDate?: any | null;
    title?: string | null;
    textBody?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    id?: string | null;
    newsHeroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
    gallery: Array<
        | ({
              assetSource?: string | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
    parent?:
        | ({ id?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ id?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null;
    settingsSearchTags: Array<
        ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' }) | null
    >;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'article_Entry' };

export type ContactQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContactQuery = {
    globalSet?:
        | ({
              contactEmailText?: string | null;
              contactMessengerText?: string | null;
              contactMyTexelText?: string | null;
              contactPhoneText?: string | null;
              contactShopText?: string | null;
              contactWhatsappText?: string | null;
          } & { __typename?: 'contact_GlobalSet' })
        | { __typename?: 'notification_GlobalSet' }
        | null;
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              textBody?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
              contactMatrix: Array<
                  | ({
                        whatsapp?: boolean | null;
                        phone?: boolean | null;
                        email?: boolean | null;
                        messenger?: boolean | null;
                        myTexel?: boolean | null;
                        shop?: boolean | null;
                    } & { __typename?: 'contact2_Entry' })
                  | null
              >;
              contentMatrix: Array<
                  | ({ accommodationsQuery?: string | null } & { __typename?: 'sectionAccommodations_Entry' })
                  | ({ header?: string | null; text?: string | null } & { __typename?: 'sectionAccordion_Entry' })
                  | { __typename?: 'sectionAction_Entry' }
                  | ({ activitiesQuery?: string | null } & { __typename?: 'sectionActivities_Entry' })
                  | ({
                        banner: Array<
                            | {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              }
                            | {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  heroImage: Array<
                                      ({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null
                                  >;
                                  button: Array<
                                      | ({
                                            text?: string | null;
                                            target?: string | null;
                                            url?: string | null;
                                            type?: string | null;
                                            element?:
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'Address'
                                                          | 'Element'
                                                          | 'FotowedstrijdHondDE_Form'
                                                          | 'FotowedstrijdHondEN_Form'
                                                          | 'FotowedstrijdHondNL_Form'
                                                          | 'FotowedstrijdPerSeizoenDE_Form'
                                                          | 'FotowedstrijdPerSeizoenEN_Form'
                                                          | 'FotowedstrijdPerSeizoen_Form'
                                                          | 'StadswandelingDenBurgNL_Form'
                                                          | 'StadswandelingVeldnamenEN_Form'
                                                          | 'TexelAgendaEvent_Form'
                                                          | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                          | 'TexelReisefuhrerherunterladen_Form'
                                                          | 'User'
                                                          | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                          | 'WinactieTexelblues2024EN_Form'
                                                          | 'aanmeldenTexelApp2_Form'
                                                          | 'aanmeldenTexelApp3_Form'
                                                          | 'aanmeldenTexelApp_Form'
                                                          | 'aanmeldenTopLedenVoorFotos_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'aanmeldenVvv125Jaar_Form'
                                                          | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                          | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                          | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                          | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                          | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                          | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                          | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                          | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                          | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                          | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                          | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                          | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                          | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                          | 'aanvraagwerkstukpakket_Form'
                                                          | 'action_Entry'
                                                          | 'activiteitaanmelden_Form'
                                                          | 'adventskalender2_Form'
                                                          | 'adventskalender4_Form'
                                                          | 'adventskalender5_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'adventskalender9_Form'
                                                          | 'adventskalender14_Form'
                                                          | 'adventskalender15_Form'
                                                          | 'adventskalender16_Form'
                                                          | 'adventskalender18_Form'
                                                          | 'adventskalender19_Form'
                                                          | 'adventskalender20_Form'
                                                          | 'adventskalender21_Form'
                                                          | 'adventskalender22_Form'
                                                          | 'adventskalender23_Form'
                                                          | 'adventskalender24_Form'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'assetTags_Tag'
                                                          | 'avontuurlijkezuidennl2022_Form'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'contact_GlobalSet';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'dataVerwijderenDE_Form'
                                                          | 'dataVerwijderenEN_Form'
                                                          | 'dataVerwijderenNL_Form'
                                                          | 'driveInCinema2024NL_Form'
                                                          | 'driveInCinemaNL_Form'
                                                          | 'durperrondjenl2022_Form'
                                                          | 'eierlandseduinennl2022_Form'
                                                          | 'evenementenkalender2024DE_Form'
                                                          | 'evenementenkalender2024EN_Form'
                                                          | 'evenementenkalenderDownloadNl_Form'
                                                          | 'evenementenkalenderNLoud_Form'
                                                          | 'fietsroutethijsserouteDE2_Form'
                                                          | 'fietsroutethijsserouteNL2_Form'
                                                          | 'fietsroutethijsseroutedu_Form'
                                                          | 'fietsroutethijsseroutenl_Form'
                                                          | 'fleeceplaidde_Form'
                                                          | 'fleeceplaideng_Form'
                                                          | 'fleeceplaidnl_Form'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'formulierBuienradar_Form'
                                                          | 'fotoVanDeMaandD_Form'
                                                          | 'fotoVanDeMaandEN_Form'
                                                          | 'fotoVanDeMaandNL_Form'
                                                          | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                          | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                          | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                          | 'fotowedstrijdlammetjesEN_Form'
                                                          | 'fotowedstrijdlammetjesdu_Form'
                                                          | 'fotowedstrijdlammetjesnl_Form'
                                                          | 'fotowedstrijdnatuur_Form'
                                                          | 'fotowedstrijdnatuurdu_Form'
                                                          | 'gewinnspielTexelblues2023_Form'
                                                          | 'grotegrazersnl2022_Form'
                                                          | 'homepage_Entry'
                                                          | 'kikkertnl2022_Form'
                                                          | 'kinderroutenl2022_Form'
                                                          | 'klachtformulierDu_Form'
                                                          | 'klachtformuliernl_Form'
                                                          | 'kleurplaatschaapjesdu_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'kleurplaatschaapjesen_Form'
                                                          | 'kleurplaatschapennl_Form'
                                                          | 'kleurplaatstranddu_Form'
                                                          | 'kleurplaatstranden_Form'
                                                          | 'kleurplaatstrandnl_Form'
                                                          | 'kleurplaatvuurtorendu_Form'
                                                          | 'kleurplaatvuurtorenen_Form'
                                                          | 'kleurplaatvuurtorennl_Form'
                                                          | 'kleurplaatzeehonddu_Form'
                                                          | 'kleurplaatzeehonden_Form'
                                                          | 'kleurplaatzeehondnl_Form'
                                                          | 'library_Asset'
                                                          | 'location_Entry'
                                                          | 'meeuwenkolonienl2022_Form'
                                                          | 'menu_Entry'
                                                          | 'mindervaliden2_Form'
                                                          | 'mindervaliden_Form'
                                                          | 'muyslufterde2022_Form'
                                                          | 'muyslufternl2022_Form'
                                                          | 'myTexel_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'news_Entry'
                                                          | 'nieuwsbriefaanmeldendu_Form'
                                                          | 'nieuwsbriefaanmeldenen_Form'
                                                          | 'nieuwsbriefaanmeldennl_Form'
                                                          | 'notification_GlobalSet'
                                                          | 'page_Entry'
                                                          | 'persaanvraagEN_Form'
                                                          | 'persverzoek_Form'
                                                          | 'presseanfrage1_Form'
                                                          | 'searchTags_Tag'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'sloffen1_Form'
                                                          | 'sloffen_Form'
                                                          | 'sommelpoodjenl2022_Form'
                                                          | 'standaardFormulierNl_Form'
                                                          | 'strandvlaktesduinvalleiennl2022_Form'
                                                          | 'synonyms_Tag'
                                                          | 'test1_Form'
                                                          | 'test_Form'
                                                          | 'testformulierNaUpdateCraft_Form'
                                                          | 'testformulier_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'texelagendaII_Form'
                                                          | 'texelfotoinsturen1_Form'
                                                          | 'texelfotoinsturen_Form'
                                                          | 'texelfotoinsturendu_Form'
                                                          | 'texelgidsNl_Form'
                                                          | 'texelpuzzelde_Form'
                                                          | 'texelpuzzelnl_Form'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'veldnamenNL2024_Form'
                                                          | 'verjaardagWijzigenNl_Form'
                                                          | 'voetsporenvocnl2022_Form'
                                                          | 'voorbeeldformulierMetAlleVelden_Form'
                                                          | 'voucher_Entry'
                                                          | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                          | 'wandelroutedemuydu_Form'
                                                          | 'wandelroutedemuynl_Form'
                                                          | 'wandelroutedenhoornNL_Form'
                                                          | 'wandelroutehogebergde2022_Form'
                                                          | 'wandelroutehogebergnl1_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'wandelroutehogebergnl2022_Form'
                                                          | 'wandelroutehogebergnl_Form'
                                                          | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                          | 'wandelroutesLangsGroteGrazersDu_Form'
                                                          | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                          | 'webshopVvvTexel_Form'
                                                          | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                          | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                          | 'winactieBEpers_Form'
                                                          | 'winactieBroadway2024DE_Form'
                                                          | 'winactieBroadway2024EN_Form'
                                                          | 'winactieBroadway2024NL_Form'
                                                          | 'winactieBute_Form'
                                                          | 'winactieFB14SterrenBEWA_Form'
                                                          | 'winactieFB14SterrenDE_Form'
                                                          | 'winactieFB14SterrenEN_Form'
                                                          | 'winactieFB14SterrenNL_Form'
                                                          | 'winactieFBVideolead2025DE_Form'
                                                          | 'winactieFBVideolead2025EN_Form'
                                                          | 'winactieFBVideolead2025NL_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'winactieFBVideoleadDE_Form'
                                                          | 'winactieFBVideoleadEN1_Form'
                                                          | 'winactieFBVideoleadNL_Form'
                                                          | 'winactieKomTotRustOpTexelDE_Form'
                                                          | 'winactieKomTotRustOpTexelEN_Form'
                                                          | 'winactieKomTotRustOpTexelNL_Form'
                                                          | 'winactieKoopplein_Form'
                                                          | 'winactieKunstZomerDE_Form'
                                                          | 'winactieKunstZomer_Form'
                                                          | 'winactieLekkerLokaalDE_Form'
                                                          | 'winactieLekkerLokaalEN_Form'
                                                          | 'winactieLekkerLokaalNL_Form'
                                                          | 'winactieParachutesprongDE_Form'
                                                          | 'winactieParachutesprongEN_Form'
                                                          | 'winactieParachutesprongNL_Form'
                                                          | 'winactieRede_Form'
                                                          | 'winactieRondritTexelDE_Form'
                                                          | 'winactieRondritTexelNL_Form'
                                                          | 'winactieRussstNL_Form'
                                                          | 'winactieScheurkalender1_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'winactieScheurkalender_Form'
                                                          | 'winactieTexelCulinair2024DE_Form'
                                                          | 'winactieTexelCulinairDE_Form'
                                                          | 'winactieTexelCulinairNL2_Form'
                                                          | 'winactieTexelCulinairNL24_Form'
                                                          | 'winactieTexelCulinairNL_Form'
                                                          | 'winactieTexelHalveMarathon2023_Form'
                                                          | 'winactieTexelHalveMarathon2024_Form'
                                                          | 'winactieTexelHalveMarathon20241_Form'
                                                          | 'winactieTexelHalveMarathon20242_Form'
                                                          | 'winactieTexelHalveMarathon_Form'
                                                          | 'winactieTexelanapantoffelsNL_Form'
                                                          | 'winactieTexelblues2023NL_Form'
                                                          | 'winactieTexelblues2023_Form'
                                                          | 'winactieTexelblues2024DE_Form'
                                                          | 'winactieTexelblues2024NL_Form'
                                                          | 'winactieTexelbues_Form'
                                                          | 'winactieTexelshopDE1_Form'
                                                          | 'winactieTexelshopNL1_Form'
                                                          | 'winactieTexelshopNL_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'winactieThreesAnnaDE_Form'
                                                          | 'winactieThreesAnnaEN_Form'
                                                          | 'winactieThreesAnnaNL_Form'
                                                          | 'winactieWaves2023NL_Form'
                                                          | 'winactieWaves2024DE_Form'
                                                          | 'winactieWaves2024EN_Form'
                                                          | 'winactieWaves2024NL_Form'
                                                          | 'winactieWebshopDe_Form'
                                                          | 'winactie_Form'
                                                          | 'winactiefacebookbdu_Form'
                                                          | 'winactiefbnl_Form';
                                                  })
                                                | null;
                                        } & {
                                            __typename?:
                                                | 'button_Asset_LinkType'
                                                | 'button_Category_LinkType'
                                                | 'button_Custom_LinkType'
                                                | 'button_Email_LinkType'
                                                | 'button_Embed_LinkType'
                                                | 'button_Entry_LinkType'
                                                | 'button_Phone_LinkType'
                                                | 'button_Site_LinkType'
                                                | 'button_Url_LinkType'
                                                | 'button_User_LinkType'
                                                | 'menuLink_Asset_LinkType'
                                                | 'menuLink_Category_LinkType'
                                                | 'menuLink_Custom_LinkType'
                                                | 'menuLink_Email_LinkType'
                                                | 'menuLink_Embed_LinkType'
                                                | 'menuLink_Entry_LinkType'
                                                | 'menuLink_Phone_LinkType'
                                                | 'menuLink_Site_LinkType'
                                                | 'menuLink_Url_LinkType'
                                                | 'menuLink_User_LinkType';
                                        })
                                      | null
                                  >;
                              } & { __typename?: 'banner_Entry' })
                            | null
                        >;
                    } & { __typename?: 'sectionBanner_Entry' })
                  | ({
                        button: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename?: 'sectionButton_Entry' })
                  | ({ companiesQuery?: string | null } & { __typename?: 'sectionCompanies_Entry' })
                  | ({ embed?: string | null } & { __typename?: 'sectionEmbed_Entry' })
                  | ({ id?: string | null } & { __typename?: 'sectionForms_Entry' })
                  | ({ header?: string | null } & { __typename?: 'sectionHeader_Entry' })
                  | ({ relationType?: string | null } & { __typename?: 'sectionHierarchicalTiles_Entry' })
                  | ({
                        gallery: Array<
                            | ({
                                  assetSource?: string | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  width?: number | null;
                                  height?: number | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'sectionImages_Entry' })
                  | ({
                        pins?: Array<
                            | ({
                                  latitude?: any | null;
                                  longitude?: any | null;
                                  symbol?: string | null;
                                  label?: string | null;
                              } & { __typename?: 'pins_TableRow' })
                            | null
                        > | null;
                    } & { __typename?: 'sectionMap_Entry' })
                  | ({ text?: string | null } & { __typename?: 'sectionText_Entry' })
                  | ({
                        tilesChildren?: boolean | null;
                        tilesSiblings?: boolean | null;
                        tilesEntries: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'banner_Entry'
                                      | 'contact2_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'page_Entry' })
                            | null
                        >;
                    } & { __typename?: 'sectionTiles_Entry' })
                  | ({
                        tip: Array<
                            | {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              }
                            | {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  tipText?: string | null;
                                  tipName?: string | null;
                                  tipRole?: string | null;
                                  tipAvatar: Array<
                                      | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                            __typename?: 'library_Asset';
                                        })
                                      | null
                                  >;
                              } & { __typename?: 'tip_Entry' })
                            | null
                        >;
                    } & { __typename?: 'sectionTip_Entry' })
                  | ({
                        travelbaseEntityType?: string | null;
                        travelbaseSlugs?: Array<
                            ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
                        > | null;
                    } & { __typename?: 'sectionTravelbaseTiles_Entry' })
                  | ({ videoUrl?: string | null; autoplay?: boolean | null } & { __typename?: 'sectionVideo_Entry' })
                  | ({ webcamUrl?: string | null } & { __typename?: 'sectionWebcam_Entry' })
                  | null
              >;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'contact_Entry' })
        | null;
} & { __typename?: 'Query' };

export type ContactMeQueryVariables = Exact<{ [key: string]: never }>;

export type ContactMeQuery = { viewer?: ({ id?: string | null } & { __typename?: 'Customer' }) | null } & {
    __typename?: 'Query';
};

export type ContactFragment = {
    title?: string | null;
    textBody?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
    button: Array<
        | ({
              text?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Address'
                            | 'Element'
                            | 'FotowedstrijdHondDE_Form'
                            | 'FotowedstrijdHondEN_Form'
                            | 'FotowedstrijdHondNL_Form'
                            | 'FotowedstrijdPerSeizoenDE_Form'
                            | 'FotowedstrijdPerSeizoenEN_Form'
                            | 'FotowedstrijdPerSeizoen_Form'
                            | 'StadswandelingDenBurgNL_Form'
                            | 'StadswandelingVeldnamenEN_Form'
                            | 'TexelAgendaEvent_Form'
                            | 'TexelReisefuhrerherunterladenFamilie_Form'
                            | 'TexelReisefuhrerherunterladen_Form'
                            | 'User'
                            | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                            | 'WinactieTexelblues2024EN_Form'
                            | 'aanmeldenTexelApp2_Form'
                            | 'aanmeldenTexelApp3_Form'
                            | 'aanmeldenTexelApp_Form'
                            | 'aanmeldenTopLedenVoorFotos_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'aanmeldenVvv125Jaar_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                            | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                            | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                            | 'aanvraagWandelrouteDurperrondjeDU_Form'
                            | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                            | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                            | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                            | 'aanvraagWandelrouteStadswandelingNl_Form'
                            | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                            | 'aanvraagwerkstukpakket_Form'
                            | 'action_Entry'
                            | 'activiteitaanmelden_Form'
                            | 'adventskalender2_Form'
                            | 'adventskalender4_Form'
                            | 'adventskalender5_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'adventskalender9_Form'
                            | 'adventskalender14_Form'
                            | 'adventskalender15_Form'
                            | 'adventskalender16_Form'
                            | 'adventskalender18_Form'
                            | 'adventskalender19_Form'
                            | 'adventskalender20_Form'
                            | 'adventskalender21_Form'
                            | 'adventskalender22_Form'
                            | 'adventskalender23_Form'
                            | 'adventskalender24_Form'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'assetTags_Tag'
                            | 'avontuurlijkezuidennl2022_Form'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'contact_GlobalSet';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'dataVerwijderenDE_Form'
                            | 'dataVerwijderenEN_Form'
                            | 'dataVerwijderenNL_Form'
                            | 'driveInCinema2024NL_Form'
                            | 'driveInCinemaNL_Form'
                            | 'durperrondjenl2022_Form'
                            | 'eierlandseduinennl2022_Form'
                            | 'evenementenkalender2024DE_Form'
                            | 'evenementenkalender2024EN_Form'
                            | 'evenementenkalenderDownloadNl_Form'
                            | 'evenementenkalenderNLoud_Form'
                            | 'fietsroutethijsserouteDE2_Form'
                            | 'fietsroutethijsserouteNL2_Form'
                            | 'fietsroutethijsseroutedu_Form'
                            | 'fietsroutethijsseroutenl_Form'
                            | 'fleeceplaidde_Form'
                            | 'fleeceplaideng_Form'
                            | 'fleeceplaidnl_Form'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'formulierBuienradar_Form'
                            | 'fotoVanDeMaandD_Form'
                            | 'fotoVanDeMaandEN_Form'
                            | 'fotoVanDeMaandNL_Form'
                            | 'fotowedstrijdTexelseLuchtenDE_Form'
                            | 'fotowedstrijdTexelseLuchtenEN_Form'
                            | 'fotowedstrijdTexelseLuchtenNL_Form'
                            | 'fotowedstrijdlammetjesEN_Form'
                            | 'fotowedstrijdlammetjesdu_Form'
                            | 'fotowedstrijdlammetjesnl_Form'
                            | 'fotowedstrijdnatuur_Form'
                            | 'fotowedstrijdnatuurdu_Form'
                            | 'gewinnspielTexelblues2023_Form'
                            | 'grotegrazersnl2022_Form'
                            | 'homepage_Entry'
                            | 'kikkertnl2022_Form'
                            | 'kinderroutenl2022_Form'
                            | 'klachtformulierDu_Form'
                            | 'klachtformuliernl_Form'
                            | 'kleurplaatschaapjesdu_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'kleurplaatschaapjesen_Form'
                            | 'kleurplaatschapennl_Form'
                            | 'kleurplaatstranddu_Form'
                            | 'kleurplaatstranden_Form'
                            | 'kleurplaatstrandnl_Form'
                            | 'kleurplaatvuurtorendu_Form'
                            | 'kleurplaatvuurtorenen_Form'
                            | 'kleurplaatvuurtorennl_Form'
                            | 'kleurplaatzeehonddu_Form'
                            | 'kleurplaatzeehonden_Form'
                            | 'kleurplaatzeehondnl_Form'
                            | 'library_Asset'
                            | 'location_Entry'
                            | 'meeuwenkolonienl2022_Form'
                            | 'menu_Entry'
                            | 'mindervaliden2_Form'
                            | 'mindervaliden_Form'
                            | 'muyslufterde2022_Form'
                            | 'muyslufternl2022_Form'
                            | 'myTexel_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'news_Entry'
                            | 'nieuwsbriefaanmeldendu_Form'
                            | 'nieuwsbriefaanmeldenen_Form'
                            | 'nieuwsbriefaanmeldennl_Form'
                            | 'notification_GlobalSet'
                            | 'page_Entry'
                            | 'persaanvraagEN_Form'
                            | 'persverzoek_Form'
                            | 'presseanfrage1_Form'
                            | 'searchTags_Tag'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'sloffen1_Form'
                            | 'sloffen_Form'
                            | 'sommelpoodjenl2022_Form'
                            | 'standaardFormulierNl_Form'
                            | 'strandvlaktesduinvalleiennl2022_Form'
                            | 'synonyms_Tag'
                            | 'test1_Form'
                            | 'test_Form'
                            | 'testformulierNaUpdateCraft_Form'
                            | 'testformulier_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'texelagendaII_Form'
                            | 'texelfotoinsturen1_Form'
                            | 'texelfotoinsturen_Form'
                            | 'texelfotoinsturendu_Form'
                            | 'texelgidsNl_Form'
                            | 'texelpuzzelde_Form'
                            | 'texelpuzzelnl_Form'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'veldnamenNL2024_Form'
                            | 'verjaardagWijzigenNl_Form'
                            | 'voetsporenvocnl2022_Form'
                            | 'voorbeeldformulierMetAlleVelden_Form'
                            | 'voucher_Entry'
                            | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                            | 'wandelroutedemuydu_Form'
                            | 'wandelroutedemuynl_Form'
                            | 'wandelroutedenhoornNL_Form'
                            | 'wandelroutehogebergde2022_Form'
                            | 'wandelroutehogebergnl1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'wandelroutehogebergnl2022_Form'
                            | 'wandelroutehogebergnl_Form'
                            | 'wandelroutesInDeSporenVanDeVocDu_Form'
                            | 'wandelroutesLangsGroteGrazersDu_Form'
                            | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                            | 'webshopVvvTexel_Form'
                            | 'winEenVerblijfMetDeTexelAppDE_Form'
                            | 'winEenVerblijfMetDeTexelAppNL_Form'
                            | 'winactieBEpers_Form'
                            | 'winactieBroadway2024DE_Form'
                            | 'winactieBroadway2024EN_Form'
                            | 'winactieBroadway2024NL_Form'
                            | 'winactieBute_Form'
                            | 'winactieFB14SterrenBEWA_Form'
                            | 'winactieFB14SterrenDE_Form'
                            | 'winactieFB14SterrenEN_Form'
                            | 'winactieFB14SterrenNL_Form'
                            | 'winactieFBVideolead2025DE_Form'
                            | 'winactieFBVideolead2025EN_Form'
                            | 'winactieFBVideolead2025NL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieFBVideoleadDE_Form'
                            | 'winactieFBVideoleadEN1_Form'
                            | 'winactieFBVideoleadNL_Form'
                            | 'winactieKomTotRustOpTexelDE_Form'
                            | 'winactieKomTotRustOpTexelEN_Form'
                            | 'winactieKomTotRustOpTexelNL_Form'
                            | 'winactieKoopplein_Form'
                            | 'winactieKunstZomerDE_Form'
                            | 'winactieKunstZomer_Form'
                            | 'winactieLekkerLokaalDE_Form'
                            | 'winactieLekkerLokaalEN_Form'
                            | 'winactieLekkerLokaalNL_Form'
                            | 'winactieParachutesprongDE_Form'
                            | 'winactieParachutesprongEN_Form'
                            | 'winactieParachutesprongNL_Form'
                            | 'winactieRede_Form'
                            | 'winactieRondritTexelDE_Form'
                            | 'winactieRondritTexelNL_Form'
                            | 'winactieRussstNL_Form'
                            | 'winactieScheurkalender1_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieScheurkalender_Form'
                            | 'winactieTexelCulinair2024DE_Form'
                            | 'winactieTexelCulinairDE_Form'
                            | 'winactieTexelCulinairNL2_Form'
                            | 'winactieTexelCulinairNL24_Form'
                            | 'winactieTexelCulinairNL_Form'
                            | 'winactieTexelHalveMarathon2023_Form'
                            | 'winactieTexelHalveMarathon2024_Form'
                            | 'winactieTexelHalveMarathon20241_Form'
                            | 'winactieTexelHalveMarathon20242_Form'
                            | 'winactieTexelHalveMarathon_Form'
                            | 'winactieTexelanapantoffelsNL_Form'
                            | 'winactieTexelblues2023NL_Form'
                            | 'winactieTexelblues2023_Form'
                            | 'winactieTexelblues2024DE_Form'
                            | 'winactieTexelblues2024NL_Form'
                            | 'winactieTexelbues_Form'
                            | 'winactieTexelshopDE1_Form'
                            | 'winactieTexelshopNL1_Form'
                            | 'winactieTexelshopNL_Form';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'winactieThreesAnnaDE_Form'
                            | 'winactieThreesAnnaEN_Form'
                            | 'winactieThreesAnnaNL_Form'
                            | 'winactieWaves2023NL_Form'
                            | 'winactieWaves2024DE_Form'
                            | 'winactieWaves2024EN_Form'
                            | 'winactieWaves2024NL_Form'
                            | 'winactieWebshopDe_Form'
                            | 'winactie_Form'
                            | 'winactiefacebookbdu_Form'
                            | 'winactiefbnl_Form';
                    })
                  | null;
          } & {
              __typename?:
                  | 'button_Asset_LinkType'
                  | 'button_Category_LinkType'
                  | 'button_Custom_LinkType'
                  | 'button_Email_LinkType'
                  | 'button_Embed_LinkType'
                  | 'button_Entry_LinkType'
                  | 'button_Phone_LinkType'
                  | 'button_Site_LinkType'
                  | 'button_Url_LinkType'
                  | 'button_User_LinkType'
                  | 'menuLink_Asset_LinkType'
                  | 'menuLink_Category_LinkType'
                  | 'menuLink_Custom_LinkType'
                  | 'menuLink_Email_LinkType'
                  | 'menuLink_Embed_LinkType'
                  | 'menuLink_Entry_LinkType'
                  | 'menuLink_Phone_LinkType'
                  | 'menuLink_Site_LinkType'
                  | 'menuLink_Url_LinkType'
                  | 'menuLink_User_LinkType';
          })
        | null
    >;
    contactMatrix: Array<
        | ({
              whatsapp?: boolean | null;
              phone?: boolean | null;
              email?: boolean | null;
              messenger?: boolean | null;
              myTexel?: boolean | null;
              shop?: boolean | null;
          } & { __typename?: 'contact2_Entry' })
        | null
    >;
    contentMatrix: Array<
        | ({ accommodationsQuery?: string | null } & { __typename?: 'sectionAccommodations_Entry' })
        | ({ header?: string | null; text?: string | null } & { __typename?: 'sectionAccordion_Entry' })
        | { __typename?: 'sectionAction_Entry' }
        | ({ activitiesQuery?: string | null } & { __typename?: 'sectionActivities_Entry' })
        | ({
              banner: Array<
                  | {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        heroImage: Array<({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null>;
                        button: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename?: 'banner_Entry' })
                  | null
              >;
          } & { __typename?: 'sectionBanner_Entry' })
        | ({
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'sectionButton_Entry' })
        | ({ companiesQuery?: string | null } & { __typename?: 'sectionCompanies_Entry' })
        | ({ embed?: string | null } & { __typename?: 'sectionEmbed_Entry' })
        | ({ id?: string | null } & { __typename?: 'sectionForms_Entry' })
        | ({ header?: string | null } & { __typename?: 'sectionHeader_Entry' })
        | ({ relationType?: string | null } & { __typename?: 'sectionHierarchicalTiles_Entry' })
        | ({
              gallery: Array<
                  | ({
                        assetSource?: string | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'sectionImages_Entry' })
        | ({
              pins?: Array<
                  | ({
                        latitude?: any | null;
                        longitude?: any | null;
                        symbol?: string | null;
                        label?: string | null;
                    } & { __typename?: 'pins_TableRow' })
                  | null
              > | null;
          } & { __typename?: 'sectionMap_Entry' })
        | ({ text?: string | null } & { __typename?: 'sectionText_Entry' })
        | ({
              tilesChildren?: boolean | null;
              tilesSiblings?: boolean | null;
              tilesEntries: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'banner_Entry'
                            | 'contact2_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'page_Entry' })
                  | null
              >;
          } & { __typename?: 'sectionTiles_Entry' })
        | ({
              tip: Array<
                  | {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        tipText?: string | null;
                        tipName?: string | null;
                        tipRole?: string | null;
                        tipAvatar: Array<
                            | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                  __typename?: 'library_Asset';
                              })
                            | null
                        >;
                    } & { __typename?: 'tip_Entry' })
                  | null
              >;
          } & { __typename?: 'sectionTip_Entry' })
        | ({
              travelbaseEntityType?: string | null;
              travelbaseSlugs?: Array<
                  ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
              > | null;
          } & { __typename?: 'sectionTravelbaseTiles_Entry' })
        | ({ videoUrl?: string | null; autoplay?: boolean | null } & { __typename?: 'sectionVideo_Entry' })
        | ({ webcamUrl?: string | null } & { __typename?: 'sectionWebcam_Entry' })
        | null
    >;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'contact_Entry' };

export type ContactMatrixFragment = {
    whatsapp?: boolean | null;
    phone?: boolean | null;
    email?: boolean | null;
    messenger?: boolean | null;
    myTexel?: boolean | null;
    shop?: boolean | null;
} & { __typename?: 'contact2_Entry' };

export type ContactGlobalsFragment = {
    contactEmailText?: string | null;
    contactMessengerText?: string | null;
    contactMyTexelText?: string | null;
    contactPhoneText?: string | null;
    contactShopText?: string | null;
    contactWhatsappText?: string | null;
} & { __typename?: 'contact_GlobalSet' };

export type HomeQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']['input']>;
}>;

export type HomeQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              video?: string | null;
              homeVideoEnabled?: boolean | null;
              homeVideoTitle?: string | null;
              homeVideoDescription?: string | null;
              homeHighlightsTitle?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              header?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
              homeHighlights: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename:
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        newsHeroImage: Array<
                            | ({
                                  width?: number | null;
                                  height?: number | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  placeholderUrl?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename: 'article_Entry' })
                  | ({
                        settingsSeoTitle?: string | null;
                        settingsMetaDescription?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        heroImage: Array<
                            | ({
                                  width?: number | null;
                                  height?: number | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  placeholderUrl?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename: 'page_Entry' })
                  | null
              >;
              homeCurrent: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'banner_Entry'
                            | 'contact2_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'page_Entry' })
                  | null
              >;
              action: Array<
                  | ({
                        title?: string | null;
                        description?: string | null;
                        button: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename?: 'action_Entry' })
                  | {
                        __typename?:
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    }
                  | null
              >;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'homepage_Entry' })
        | null;
} & { __typename?: 'Query' };

export type HomeFragment = {
    title?: string | null;
    video?: string | null;
    homeVideoEnabled?: boolean | null;
    homeVideoTitle?: string | null;
    homeVideoDescription?: string | null;
    homeHighlightsTitle?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    header?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
    homeHighlights: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              newsHeroImage: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename: 'article_Entry' })
        | ({
              settingsSeoTitle?: string | null;
              settingsMetaDescription?: string | null;
              title?: string | null;
              uri?: string | null;
              heroImage: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename: 'page_Entry' })
        | null
    >;
    homeCurrent: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'banner_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'page_Entry' })
        | null
    >;
    action: Array<
        | ({
              title?: string | null;
              description?: string | null;
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'action_Entry' })
        | {
              __typename?:
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | null
    >;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'homepage_Entry' };

export type NewsQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type NewsQuery = {
    articleCount: number;
    articles?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'banner_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'page_Entry' })
        | null
    > | null;
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              textBody?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'news_Entry' })
        | null;
} & { __typename?: 'Query' };

export type NewsFragment = {
    title?: string | null;
    textBody?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'news_Entry' };

export type PageQueryVariables = Exact<{
    uri?: InputMaybe<Scalars['String']['input']>;
    lang?: InputMaybe<Scalars['String']['input']>;
    retourUri?: InputMaybe<Scalars['String']['input']>;
}>;

export type PageQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              uri?: string | null;
              title?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImageVisible?: boolean | null;
              root?: number | null;
              id?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
              contentMatrix: Array<
                  | ({ accommodationsQuery?: string | null } & { __typename?: 'sectionAccommodations_Entry' })
                  | ({ header?: string | null; text?: string | null } & { __typename?: 'sectionAccordion_Entry' })
                  | { __typename?: 'sectionAction_Entry' }
                  | ({ activitiesQuery?: string | null } & { __typename?: 'sectionActivities_Entry' })
                  | ({
                        banner: Array<
                            | {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              }
                            | {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  heroImage: Array<
                                      ({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null
                                  >;
                                  button: Array<
                                      | ({
                                            text?: string | null;
                                            target?: string | null;
                                            url?: string | null;
                                            type?: string | null;
                                            element?:
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'Address'
                                                          | 'Element'
                                                          | 'FotowedstrijdHondDE_Form'
                                                          | 'FotowedstrijdHondEN_Form'
                                                          | 'FotowedstrijdHondNL_Form'
                                                          | 'FotowedstrijdPerSeizoenDE_Form'
                                                          | 'FotowedstrijdPerSeizoenEN_Form'
                                                          | 'FotowedstrijdPerSeizoen_Form'
                                                          | 'StadswandelingDenBurgNL_Form'
                                                          | 'StadswandelingVeldnamenEN_Form'
                                                          | 'TexelAgendaEvent_Form'
                                                          | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                          | 'TexelReisefuhrerherunterladen_Form'
                                                          | 'User'
                                                          | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                          | 'WinactieTexelblues2024EN_Form'
                                                          | 'aanmeldenTexelApp2_Form'
                                                          | 'aanmeldenTexelApp3_Form'
                                                          | 'aanmeldenTexelApp_Form'
                                                          | 'aanmeldenTopLedenVoorFotos_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'aanmeldenVvv125Jaar_Form'
                                                          | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                          | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                          | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                          | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                          | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                          | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                          | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                          | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                          | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                          | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                          | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                          | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                          | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                          | 'aanvraagwerkstukpakket_Form'
                                                          | 'action_Entry'
                                                          | 'activiteitaanmelden_Form'
                                                          | 'adventskalender2_Form'
                                                          | 'adventskalender4_Form'
                                                          | 'adventskalender5_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'adventskalender9_Form'
                                                          | 'adventskalender14_Form'
                                                          | 'adventskalender15_Form'
                                                          | 'adventskalender16_Form'
                                                          | 'adventskalender18_Form'
                                                          | 'adventskalender19_Form'
                                                          | 'adventskalender20_Form'
                                                          | 'adventskalender21_Form'
                                                          | 'adventskalender22_Form'
                                                          | 'adventskalender23_Form'
                                                          | 'adventskalender24_Form'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'assetTags_Tag'
                                                          | 'avontuurlijkezuidennl2022_Form'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'contact_GlobalSet';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'dataVerwijderenDE_Form'
                                                          | 'dataVerwijderenEN_Form'
                                                          | 'dataVerwijderenNL_Form'
                                                          | 'driveInCinema2024NL_Form'
                                                          | 'driveInCinemaNL_Form'
                                                          | 'durperrondjenl2022_Form'
                                                          | 'eierlandseduinennl2022_Form'
                                                          | 'evenementenkalender2024DE_Form'
                                                          | 'evenementenkalender2024EN_Form'
                                                          | 'evenementenkalenderDownloadNl_Form'
                                                          | 'evenementenkalenderNLoud_Form'
                                                          | 'fietsroutethijsserouteDE2_Form'
                                                          | 'fietsroutethijsserouteNL2_Form'
                                                          | 'fietsroutethijsseroutedu_Form'
                                                          | 'fietsroutethijsseroutenl_Form'
                                                          | 'fleeceplaidde_Form'
                                                          | 'fleeceplaideng_Form'
                                                          | 'fleeceplaidnl_Form'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'formulierBuienradar_Form'
                                                          | 'fotoVanDeMaandD_Form'
                                                          | 'fotoVanDeMaandEN_Form'
                                                          | 'fotoVanDeMaandNL_Form'
                                                          | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                          | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                          | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                          | 'fotowedstrijdlammetjesEN_Form'
                                                          | 'fotowedstrijdlammetjesdu_Form'
                                                          | 'fotowedstrijdlammetjesnl_Form'
                                                          | 'fotowedstrijdnatuur_Form'
                                                          | 'fotowedstrijdnatuurdu_Form'
                                                          | 'gewinnspielTexelblues2023_Form'
                                                          | 'grotegrazersnl2022_Form'
                                                          | 'homepage_Entry'
                                                          | 'kikkertnl2022_Form'
                                                          | 'kinderroutenl2022_Form'
                                                          | 'klachtformulierDu_Form'
                                                          | 'klachtformuliernl_Form'
                                                          | 'kleurplaatschaapjesdu_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'kleurplaatschaapjesen_Form'
                                                          | 'kleurplaatschapennl_Form'
                                                          | 'kleurplaatstranddu_Form'
                                                          | 'kleurplaatstranden_Form'
                                                          | 'kleurplaatstrandnl_Form'
                                                          | 'kleurplaatvuurtorendu_Form'
                                                          | 'kleurplaatvuurtorenen_Form'
                                                          | 'kleurplaatvuurtorennl_Form'
                                                          | 'kleurplaatzeehonddu_Form'
                                                          | 'kleurplaatzeehonden_Form'
                                                          | 'kleurplaatzeehondnl_Form'
                                                          | 'library_Asset'
                                                          | 'location_Entry'
                                                          | 'meeuwenkolonienl2022_Form'
                                                          | 'menu_Entry'
                                                          | 'mindervaliden2_Form'
                                                          | 'mindervaliden_Form'
                                                          | 'muyslufterde2022_Form'
                                                          | 'muyslufternl2022_Form'
                                                          | 'myTexel_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'news_Entry'
                                                          | 'nieuwsbriefaanmeldendu_Form'
                                                          | 'nieuwsbriefaanmeldenen_Form'
                                                          | 'nieuwsbriefaanmeldennl_Form'
                                                          | 'notification_GlobalSet'
                                                          | 'page_Entry'
                                                          | 'persaanvraagEN_Form'
                                                          | 'persverzoek_Form'
                                                          | 'presseanfrage1_Form'
                                                          | 'searchTags_Tag'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'sloffen1_Form'
                                                          | 'sloffen_Form'
                                                          | 'sommelpoodjenl2022_Form'
                                                          | 'standaardFormulierNl_Form'
                                                          | 'strandvlaktesduinvalleiennl2022_Form'
                                                          | 'synonyms_Tag'
                                                          | 'test1_Form'
                                                          | 'test_Form'
                                                          | 'testformulierNaUpdateCraft_Form'
                                                          | 'testformulier_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'texelagendaII_Form'
                                                          | 'texelfotoinsturen1_Form'
                                                          | 'texelfotoinsturen_Form'
                                                          | 'texelfotoinsturendu_Form'
                                                          | 'texelgidsNl_Form'
                                                          | 'texelpuzzelde_Form'
                                                          | 'texelpuzzelnl_Form'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'veldnamenNL2024_Form'
                                                          | 'verjaardagWijzigenNl_Form'
                                                          | 'voetsporenvocnl2022_Form'
                                                          | 'voorbeeldformulierMetAlleVelden_Form'
                                                          | 'voucher_Entry'
                                                          | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                          | 'wandelroutedemuydu_Form'
                                                          | 'wandelroutedemuynl_Form'
                                                          | 'wandelroutedenhoornNL_Form'
                                                          | 'wandelroutehogebergde2022_Form'
                                                          | 'wandelroutehogebergnl1_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'wandelroutehogebergnl2022_Form'
                                                          | 'wandelroutehogebergnl_Form'
                                                          | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                          | 'wandelroutesLangsGroteGrazersDu_Form'
                                                          | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                          | 'webshopVvvTexel_Form'
                                                          | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                          | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                          | 'winactieBEpers_Form'
                                                          | 'winactieBroadway2024DE_Form'
                                                          | 'winactieBroadway2024EN_Form'
                                                          | 'winactieBroadway2024NL_Form'
                                                          | 'winactieBute_Form'
                                                          | 'winactieFB14SterrenBEWA_Form'
                                                          | 'winactieFB14SterrenDE_Form'
                                                          | 'winactieFB14SterrenEN_Form'
                                                          | 'winactieFB14SterrenNL_Form'
                                                          | 'winactieFBVideolead2025DE_Form'
                                                          | 'winactieFBVideolead2025EN_Form'
                                                          | 'winactieFBVideolead2025NL_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'winactieFBVideoleadDE_Form'
                                                          | 'winactieFBVideoleadEN1_Form'
                                                          | 'winactieFBVideoleadNL_Form'
                                                          | 'winactieKomTotRustOpTexelDE_Form'
                                                          | 'winactieKomTotRustOpTexelEN_Form'
                                                          | 'winactieKomTotRustOpTexelNL_Form'
                                                          | 'winactieKoopplein_Form'
                                                          | 'winactieKunstZomerDE_Form'
                                                          | 'winactieKunstZomer_Form'
                                                          | 'winactieLekkerLokaalDE_Form'
                                                          | 'winactieLekkerLokaalEN_Form'
                                                          | 'winactieLekkerLokaalNL_Form'
                                                          | 'winactieParachutesprongDE_Form'
                                                          | 'winactieParachutesprongEN_Form'
                                                          | 'winactieParachutesprongNL_Form'
                                                          | 'winactieRede_Form'
                                                          | 'winactieRondritTexelDE_Form'
                                                          | 'winactieRondritTexelNL_Form'
                                                          | 'winactieRussstNL_Form'
                                                          | 'winactieScheurkalender1_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'winactieScheurkalender_Form'
                                                          | 'winactieTexelCulinair2024DE_Form'
                                                          | 'winactieTexelCulinairDE_Form'
                                                          | 'winactieTexelCulinairNL2_Form'
                                                          | 'winactieTexelCulinairNL24_Form'
                                                          | 'winactieTexelCulinairNL_Form'
                                                          | 'winactieTexelHalveMarathon2023_Form'
                                                          | 'winactieTexelHalveMarathon2024_Form'
                                                          | 'winactieTexelHalveMarathon20241_Form'
                                                          | 'winactieTexelHalveMarathon20242_Form'
                                                          | 'winactieTexelHalveMarathon_Form'
                                                          | 'winactieTexelanapantoffelsNL_Form'
                                                          | 'winactieTexelblues2023NL_Form'
                                                          | 'winactieTexelblues2023_Form'
                                                          | 'winactieTexelblues2024DE_Form'
                                                          | 'winactieTexelblues2024NL_Form'
                                                          | 'winactieTexelbues_Form'
                                                          | 'winactieTexelshopDE1_Form'
                                                          | 'winactieTexelshopNL1_Form'
                                                          | 'winactieTexelshopNL_Form';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'winactieThreesAnnaDE_Form'
                                                          | 'winactieThreesAnnaEN_Form'
                                                          | 'winactieThreesAnnaNL_Form'
                                                          | 'winactieWaves2023NL_Form'
                                                          | 'winactieWaves2024DE_Form'
                                                          | 'winactieWaves2024EN_Form'
                                                          | 'winactieWaves2024NL_Form'
                                                          | 'winactieWebshopDe_Form'
                                                          | 'winactie_Form'
                                                          | 'winactiefacebookbdu_Form'
                                                          | 'winactiefbnl_Form';
                                                  })
                                                | null;
                                        } & {
                                            __typename?:
                                                | 'button_Asset_LinkType'
                                                | 'button_Category_LinkType'
                                                | 'button_Custom_LinkType'
                                                | 'button_Email_LinkType'
                                                | 'button_Embed_LinkType'
                                                | 'button_Entry_LinkType'
                                                | 'button_Phone_LinkType'
                                                | 'button_Site_LinkType'
                                                | 'button_Url_LinkType'
                                                | 'button_User_LinkType'
                                                | 'menuLink_Asset_LinkType'
                                                | 'menuLink_Category_LinkType'
                                                | 'menuLink_Custom_LinkType'
                                                | 'menuLink_Email_LinkType'
                                                | 'menuLink_Embed_LinkType'
                                                | 'menuLink_Entry_LinkType'
                                                | 'menuLink_Phone_LinkType'
                                                | 'menuLink_Site_LinkType'
                                                | 'menuLink_Url_LinkType'
                                                | 'menuLink_User_LinkType';
                                        })
                                      | null
                                  >;
                              } & { __typename?: 'banner_Entry' })
                            | null
                        >;
                    } & { __typename?: 'sectionBanner_Entry' })
                  | ({
                        button: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename?: 'sectionButton_Entry' })
                  | ({ companiesQuery?: string | null } & { __typename?: 'sectionCompanies_Entry' })
                  | ({ embed?: string | null } & { __typename?: 'sectionEmbed_Entry' })
                  | ({ id?: string | null } & { __typename?: 'sectionForms_Entry' })
                  | ({ header?: string | null } & { __typename?: 'sectionHeader_Entry' })
                  | ({ relationType?: string | null } & { __typename?: 'sectionHierarchicalTiles_Entry' })
                  | ({
                        gallery: Array<
                            | ({
                                  assetSource?: string | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  width?: number | null;
                                  height?: number | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'sectionImages_Entry' })
                  | ({
                        pins?: Array<
                            | ({
                                  latitude?: any | null;
                                  longitude?: any | null;
                                  symbol?: string | null;
                                  label?: string | null;
                              } & { __typename?: 'pins_TableRow' })
                            | null
                        > | null;
                    } & { __typename?: 'sectionMap_Entry' })
                  | ({ text?: string | null } & { __typename?: 'sectionText_Entry' })
                  | ({
                        tilesChildren?: boolean | null;
                        tilesSiblings?: boolean | null;
                        tilesEntries: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'banner_Entry'
                                      | 'contact2_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'page_Entry' })
                            | null
                        >;
                    } & { __typename?: 'sectionTiles_Entry' })
                  | ({
                        tip: Array<
                            | {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              }
                            | {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  tipText?: string | null;
                                  tipName?: string | null;
                                  tipRole?: string | null;
                                  tipAvatar: Array<
                                      | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                            __typename?: 'library_Asset';
                                        })
                                      | null
                                  >;
                              } & { __typename?: 'tip_Entry' })
                            | null
                        >;
                    } & { __typename?: 'sectionTip_Entry' })
                  | ({
                        travelbaseEntityType?: string | null;
                        travelbaseSlugs?: Array<
                            ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
                        > | null;
                    } & { __typename?: 'sectionTravelbaseTiles_Entry' })
                  | ({ videoUrl?: string | null; autoplay?: boolean | null } & { __typename?: 'sectionVideo_Entry' })
                  | ({ webcamUrl?: string | null } & { __typename?: 'sectionWebcam_Entry' })
                  | null
              >;
              children: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'banner_Entry'
                            | 'contact2_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'page_Entry' })
              >;
              parent?:
                  | ({
                        id?: string | null;
                        children: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'banner_Entry'
                                      | 'contact2_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({
                        id?: string | null;
                        children: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'banner_Entry'
                                      | 'contact2_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension: string;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  >;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | null;
              settingsSearchTags: Array<
                  | ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' })
                  | null
              >;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'page_Entry' })
        | null;
    retourResolveRedirect?:
        | ({
              redirectDestUrl?: string | null;
              redirectSrcUrl?: string | null;
              redirectHttpCode?: number | null;
              redirectSrcMatch?: string | null;
              siteId?: number | null;
          } & { __typename?: 'RetourType' })
        | null;
} & { __typename?: 'Query' };

export type PageFragment = {
    uri?: string | null;
    title?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    settingsNotSearchable?: boolean | null;
    heroImageVisible?: boolean | null;
    root?: number | null;
    id?: string | null;
    heroImage: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension: string;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    >;
    contentMatrix: Array<
        | ({ accommodationsQuery?: string | null } & { __typename?: 'sectionAccommodations_Entry' })
        | ({ header?: string | null; text?: string | null } & { __typename?: 'sectionAccordion_Entry' })
        | { __typename?: 'sectionAction_Entry' }
        | ({ activitiesQuery?: string | null } & { __typename?: 'sectionActivities_Entry' })
        | ({
              banner: Array<
                  | {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        heroImage: Array<({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null>;
                        button: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename?: 'banner_Entry' })
                  | null
              >;
          } & { __typename?: 'sectionBanner_Entry' })
        | ({
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'sectionButton_Entry' })
        | ({ companiesQuery?: string | null } & { __typename?: 'sectionCompanies_Entry' })
        | ({ embed?: string | null } & { __typename?: 'sectionEmbed_Entry' })
        | ({ id?: string | null } & { __typename?: 'sectionForms_Entry' })
        | ({ header?: string | null } & { __typename?: 'sectionHeader_Entry' })
        | ({ relationType?: string | null } & { __typename?: 'sectionHierarchicalTiles_Entry' })
        | ({
              gallery: Array<
                  | ({
                        assetSource?: string | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'sectionImages_Entry' })
        | ({
              pins?: Array<
                  | ({
                        latitude?: any | null;
                        longitude?: any | null;
                        symbol?: string | null;
                        label?: string | null;
                    } & { __typename?: 'pins_TableRow' })
                  | null
              > | null;
          } & { __typename?: 'sectionMap_Entry' })
        | ({ text?: string | null } & { __typename?: 'sectionText_Entry' })
        | ({
              tilesChildren?: boolean | null;
              tilesSiblings?: boolean | null;
              tilesEntries: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'banner_Entry'
                            | 'contact2_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'page_Entry' })
                  | null
              >;
          } & { __typename?: 'sectionTiles_Entry' })
        | ({
              tip: Array<
                  | {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        tipText?: string | null;
                        tipName?: string | null;
                        tipRole?: string | null;
                        tipAvatar: Array<
                            | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                  __typename?: 'library_Asset';
                              })
                            | null
                        >;
                    } & { __typename?: 'tip_Entry' })
                  | null
              >;
          } & { __typename?: 'sectionTip_Entry' })
        | ({
              travelbaseEntityType?: string | null;
              travelbaseSlugs?: Array<
                  ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
              > | null;
          } & { __typename?: 'sectionTravelbaseTiles_Entry' })
        | ({ videoUrl?: string | null; autoplay?: boolean | null } & { __typename?: 'sectionVideo_Entry' })
        | ({ webcamUrl?: string | null } & { __typename?: 'sectionWebcam_Entry' })
        | null
    >;
    children: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'banner_Entry'
                  | 'contact2_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension: string;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              >;
          } & { __typename?: 'page_Entry' })
    >;
    parent?:
        | ({
              id?: string | null;
              children: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'banner_Entry'
                            | 'contact2_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'page_Entry' })
              >;
          } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({
              id?: string | null;
              children: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'banner_Entry'
                            | 'contact2_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?: 'agenda_Entry' | 'companies_Entry' | 'myTexel_Entry' | 'thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'contact_Entry' | 'homepage_Entry' | 'news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension: string;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        >;
                    } & { __typename?: 'page_Entry' })
              >;
          } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null;
    settingsSearchTags: Array<
        ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' }) | null
    >;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'page_Entry' };

export type PagePathsQueryVariables = Exact<{ [key: string]: never }>;

export type PagePathsQuery = {
    entries?: Array<
        | ({ uri?: string | null; slug?: string | null; language?: string | null } & {
              __typename:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ uri?: string | null; slug?: string | null; language?: string | null } & {
              __typename:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'Query' };

export type SearchPageQueryVariables = Exact<{
    lang: Scalars['String']['input'];
    q: Scalars['String']['input'];
    types?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type SearchPageQuery = {
    globalSearch?:
        | ({
              totalHits?: number | null;
              typeCounts?: Array<
                  ({ count?: number | null; label?: string | null } & { __typename?: 'Bucket' }) | null
              > | null;
              hits?: Array<
                  | ({
                        postDate?: string | null;
                        title?: string | null;
                        type?: string | null;
                        uri?: string | null;
                        image?:
                            | ({
                                  extension?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                              } & { __typename?: 'SearchImage' })
                            | null;
                    } & { __typename?: 'SearchType' })
                  | null
              > | null;
          } & { __typename?: 'SearchResult' })
        | null;
} & { __typename?: 'Query' };

export type SearchPageAllAvailableFiltersQueryVariables = Exact<{
    lang: Scalars['String']['input'];
    q: Scalars['String']['input'];
}>;

export type SearchPageAllAvailableFiltersQuery = {
    globalSearch?:
        | ({
              totalHits?: number | null;
              typeCounts?: Array<
                  ({ count?: number | null; label?: string | null } & { __typename?: 'Bucket' }) | null
              > | null;
          } & { __typename?: 'SearchResult' })
        | null;
} & { __typename?: 'Query' };

export type SearchQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'search_Entry' })
        | null;
} & { __typename?: 'Query' };

export type SearchFragment = {
    title?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'search_Entry' };

export type SearchHitFragment = {
    postDate?: string | null;
    title?: string | null;
    type?: string | null;
    uri?: string | null;
    image?:
        | ({
              extension?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              title?: string | null;
              url?: string | null;
          } & { __typename?: 'SearchImage' })
        | null;
} & { __typename?: 'SearchType' };

export type HeroTransformFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension: string;
    url?: string | null;
    placeholderUrl?: string | null;
    ogImageUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
    srcsetMobile?: string | null;
    srcsetMobileWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type TileTransformFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension: string;
    url?: string | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type GalleryTransformFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension: string;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type HighlightTransformFragment = {
    width?: number | null;
    height?: number | null;
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension: string;
    url?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
    placeholderUrl?: string | null;
} & { __typename?: 'library_Asset' };

export type AgendaQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']['input']>;
}>;

export type AgendaQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              localized: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
              >;
          } & { __typename?: 'agenda_Entry' })
        | null;
} & { __typename?: 'Query' };

export type AgendaFragment = {
    title?: string | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    localized: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
    >;
} & { __typename?: 'agenda_Entry' };

export type AccoFeedQueryVariables = Exact<{
    amount?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AccoFeedQuery = {
    unitSearch: {
        totalHits: number;
        hits: Array<
            {
                rentalUnit: {
                    id: string;
                    name: string;
                    slug: string;
                    tagline: string;
                    descriptionLayout: string;
                    type: RentalUnitTypeEnum;
                    maxOccupancy: number;
                    livingArea?: number | null;
                    outdoorArea?: number | null;
                    images: Array<
                        { transform?: ({ src: string } & { __typename?: 'ImageTransform' }) | null } & {
                            __typename?: 'AccommodationImage';
                        }
                    >;
                    accommodation: { place: string } & { __typename?: 'Accommodation' };
                } & { __typename?: 'RentalUnit' };
                trips: Array<{ price: number } & { __typename?: 'Trip' }>;
            } & { __typename?: 'UnitSearchHit' }
        >;
    } & { __typename?: 'UnitSearchResult' };
} & { __typename?: 'Query' };

export type SitemapQueryVariables = Exact<{
    locale?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SitemapQuery = {
    entries?: Array<
        | ({ url?: string | null; dateUpdated?: any | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry';
          })
        | ({ url?: string | null; dateUpdated?: any | null } & {
              __typename?:
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({
              url?: string | null;
              dateUpdated?: any | null;
              heroImage: Array<
                  ({ url?: string | null; title?: string | null } & { __typename?: 'library_Asset' }) | null
              >;
          } & { __typename?: 'news_Entry' | 'page_Entry' })
        | null
    > | null;
    travelbaseObjectsSitemap?: Array<
        | ({
              url?: string | null;
              image?: ({ url?: string | null; title?: string | null } & { __typename?: 'SearchImage' }) | null;
          } & { __typename?: 'TravelbaseObjectsSitemapType' })
        | null
    > | null;
} & { __typename?: 'Query' };

export type SqzlyFeedQueryVariables = Exact<{
    amount?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SqzlyFeedQuery = {
    unitSearch: {
        totalHits: number;
        hits: Array<
            {
                rentalUnit: {
                    code: string;
                    name: string;
                    slug: string;
                    type: RentalUnitTypeEnum;
                    listImage: { transform?: ({ src: string } & { __typename?: 'ImageTransform' }) | null } & {
                        __typename?: 'GenericImage';
                    };
                    accommodation: { name: string; id: string } & { __typename?: 'Accommodation' };
                } & { __typename?: 'RentalUnit' };
                trips: Array<{ price: number } & { __typename?: 'Trip' }>;
            } & { __typename?: 'UnitSearchHit' }
        >;
    } & { __typename?: 'UnitSearchResult' };
} & { __typename?: 'Query' };

export type GlobalQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']['input']>;
}>;

export type GlobalQuery = {
    menu?: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              menuLink: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form'
                                      | 'texelagendaII_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form'
                                      | 'wandelroutehogebergnl2022_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form'
                                      | 'winactieFBVideoleadDE_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form'
                                      | 'winactieScheurkalender_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form'
                                      | 'winactieThreesAnnaDE_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  slug?: string | null;
                                  id?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & { __typename?: 'page_Entry' })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'menu_Entry' })
        | null
    > | null;
    breadcrumb?: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              slug?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | null;
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
          } & { __typename?: 'page_Entry' })
        | null
    > | null;
    footer?: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              children: Array<
                  | {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        menuLink: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename: 'footerLink_Entry' })
              >;
          } & { __typename?: 'footerCategory_Entry' })
        | null
    > | null;
    notification?:
        | { __typename?: 'contact_GlobalSet' }
        | ({
              header?: string | null;
              active?: boolean | null;
              enabled?: boolean | null;
              description?: string | null;
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'notification_GlobalSet' })
        | null;
    search?:
        | ({ uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ uri?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null;
} & { __typename?: 'Query' };

export type GlobalQueryFragment = {
    menu?: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              menuLink: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form'
                                      | 'texelagendaII_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form'
                                      | 'wandelroutehogebergnl2022_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form'
                                      | 'winactieFBVideoleadDE_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form'
                                      | 'winactieScheurkalender_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form'
                                      | 'winactieThreesAnnaDE_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  slug?: string | null;
                                  id?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  })
                                                | ({
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                  } & {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children: Array<
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry'
                                                                    | 'sectionBanner_Entry';
                                                            })
                                                          | ({
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                            } & {
                                                                __typename?:
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry'
                                                                              | 'sectionBanner_Entry';
                                                                      }
                                                                    | {
                                                                          __typename?:
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_Entry'
                                                                                        | 'agenda_Entry'
                                                                                        | 'article_Entry'
                                                                                        | 'banner_Entry'
                                                                                        | 'companies_Entry'
                                                                                        | 'contact2_Entry'
                                                                                        | 'contact_Entry'
                                                                                        | 'footerCategory_Entry'
                                                                                        | 'footerLink_Entry'
                                                                                        | 'homepage_Entry'
                                                                                        | 'location_Entry'
                                                                                        | 'menu_Entry'
                                                                                        | 'myTexel_Entry'
                                                                                        | 'news_Entry'
                                                                                        | 'page_Entry'
                                                                                        | 'search_Entry'
                                                                                        | 'sectionAccommodations_Entry'
                                                                                        | 'sectionAccordion_Entry'
                                                                                        | 'sectionAction_Entry'
                                                                                        | 'sectionActivities_Entry';
                                                                                })
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'sectionBanner_Entry'
                                                                                        | 'sectionButton_Entry'
                                                                                        | 'sectionCompanies_Entry'
                                                                                        | 'sectionEmbed_Entry'
                                                                                        | 'sectionForms_Entry'
                                                                                        | 'sectionHeader_Entry'
                                                                                        | 'sectionHierarchicalTiles_Entry'
                                                                                        | 'sectionImages_Entry'
                                                                                        | 'sectionMap_Entry'
                                                                                        | 'sectionText_Entry'
                                                                                        | 'sectionTiles_Entry'
                                                                                        | 'sectionTip_Entry'
                                                                                        | 'sectionTravelbaseTiles_Entry'
                                                                                        | 'sectionVideo_Entry'
                                                                                        | 'sectionWebcam_Entry'
                                                                                        | 'thankYou_Entry'
                                                                                        | 'tip_Entry'
                                                                                        | 'voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'page_Entry' })
                                                                >;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_Entry'
                                                                              | 'agenda_Entry'
                                                                              | 'article_Entry'
                                                                              | 'banner_Entry'
                                                                              | 'companies_Entry'
                                                                              | 'contact2_Entry'
                                                                              | 'contact_Entry'
                                                                              | 'footerCategory_Entry'
                                                                              | 'footerLink_Entry'
                                                                              | 'homepage_Entry'
                                                                              | 'location_Entry'
                                                                              | 'menu_Entry'
                                                                              | 'myTexel_Entry'
                                                                              | 'news_Entry'
                                                                              | 'page_Entry'
                                                                              | 'search_Entry'
                                                                              | 'sectionAccommodations_Entry'
                                                                              | 'sectionAccordion_Entry'
                                                                              | 'sectionAction_Entry'
                                                                              | 'sectionActivities_Entry';
                                                                      })
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'sectionBanner_Entry'
                                                                              | 'sectionButton_Entry'
                                                                              | 'sectionCompanies_Entry'
                                                                              | 'sectionEmbed_Entry'
                                                                              | 'sectionForms_Entry'
                                                                              | 'sectionHeader_Entry'
                                                                              | 'sectionHierarchicalTiles_Entry'
                                                                              | 'sectionImages_Entry'
                                                                              | 'sectionMap_Entry'
                                                                              | 'sectionText_Entry'
                                                                              | 'sectionTiles_Entry'
                                                                              | 'sectionTip_Entry'
                                                                              | 'sectionTravelbaseTiles_Entry'
                                                                              | 'sectionVideo_Entry'
                                                                              | 'sectionWebcam_Entry'
                                                                              | 'thankYou_Entry'
                                                                              | 'tip_Entry'
                                                                              | 'voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'page_Entry' })
                                                      >;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & { __typename?: 'page_Entry' })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'menu_Entry' })
        | null
    > | null;
    breadcrumb?: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              slug?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | null;
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        })
                                      | ({
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                        } & {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry'
                                                          | 'sectionBanner_Entry';
                                                  }
                                                | {
                                                      __typename?:
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_Entry'
                                                                    | 'agenda_Entry'
                                                                    | 'article_Entry'
                                                                    | 'banner_Entry'
                                                                    | 'companies_Entry'
                                                                    | 'contact2_Entry'
                                                                    | 'contact_Entry'
                                                                    | 'footerCategory_Entry'
                                                                    | 'footerLink_Entry'
                                                                    | 'homepage_Entry'
                                                                    | 'location_Entry'
                                                                    | 'menu_Entry'
                                                                    | 'myTexel_Entry'
                                                                    | 'news_Entry'
                                                                    | 'page_Entry'
                                                                    | 'search_Entry'
                                                                    | 'sectionAccommodations_Entry'
                                                                    | 'sectionAccordion_Entry'
                                                                    | 'sectionAction_Entry'
                                                                    | 'sectionActivities_Entry';
                                                            })
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'sectionBanner_Entry'
                                                                    | 'sectionButton_Entry'
                                                                    | 'sectionCompanies_Entry'
                                                                    | 'sectionEmbed_Entry'
                                                                    | 'sectionForms_Entry'
                                                                    | 'sectionHeader_Entry'
                                                                    | 'sectionHierarchicalTiles_Entry'
                                                                    | 'sectionImages_Entry'
                                                                    | 'sectionMap_Entry'
                                                                    | 'sectionText_Entry'
                                                                    | 'sectionTiles_Entry'
                                                                    | 'sectionTip_Entry'
                                                                    | 'sectionTravelbaseTiles_Entry'
                                                                    | 'sectionVideo_Entry'
                                                                    | 'sectionWebcam_Entry'
                                                                    | 'thankYou_Entry'
                                                                    | 'tip_Entry'
                                                                    | 'voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'page_Entry' })
                                            >;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
          } & { __typename?: 'page_Entry' })
        | null
    > | null;
    footer?: Array<
        | {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          }
        | {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          }
        | ({
              title?: string | null;
              children: Array<
                  | {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    }
                  | {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        menuLink: Array<
                            | ({
                                  text?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Address'
                                                | 'Element'
                                                | 'FotowedstrijdHondDE_Form'
                                                | 'FotowedstrijdHondEN_Form'
                                                | 'FotowedstrijdHondNL_Form'
                                                | 'FotowedstrijdPerSeizoenDE_Form'
                                                | 'FotowedstrijdPerSeizoenEN_Form'
                                                | 'FotowedstrijdPerSeizoen_Form'
                                                | 'StadswandelingDenBurgNL_Form'
                                                | 'StadswandelingVeldnamenEN_Form'
                                                | 'TexelAgendaEvent_Form'
                                                | 'TexelReisefuhrerherunterladenFamilie_Form'
                                                | 'TexelReisefuhrerherunterladen_Form'
                                                | 'User'
                                                | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                                | 'WinactieTexelblues2024EN_Form'
                                                | 'aanmeldenTexelApp2_Form'
                                                | 'aanmeldenTexelApp3_Form'
                                                | 'aanmeldenTexelApp_Form'
                                                | 'aanmeldenTopLedenVoorFotos_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'aanmeldenVvv125Jaar_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                                | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                                | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                                | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                                | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                                | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                                | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                                | 'aanvraagWandelrouteStadswandelingNl_Form'
                                                | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                                | 'aanvraagwerkstukpakket_Form'
                                                | 'action_Entry'
                                                | 'activiteitaanmelden_Form'
                                                | 'adventskalender2_Form'
                                                | 'adventskalender4_Form'
                                                | 'adventskalender5_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'adventskalender9_Form'
                                                | 'adventskalender14_Form'
                                                | 'adventskalender15_Form'
                                                | 'adventskalender16_Form'
                                                | 'adventskalender18_Form'
                                                | 'adventskalender19_Form'
                                                | 'adventskalender20_Form'
                                                | 'adventskalender21_Form'
                                                | 'adventskalender22_Form'
                                                | 'adventskalender23_Form'
                                                | 'adventskalender24_Form'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'assetTags_Tag'
                                                | 'avontuurlijkezuidennl2022_Form'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'contact_GlobalSet';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'dataVerwijderenDE_Form'
                                                | 'dataVerwijderenEN_Form'
                                                | 'dataVerwijderenNL_Form'
                                                | 'driveInCinema2024NL_Form'
                                                | 'driveInCinemaNL_Form'
                                                | 'durperrondjenl2022_Form'
                                                | 'eierlandseduinennl2022_Form'
                                                | 'evenementenkalender2024DE_Form'
                                                | 'evenementenkalender2024EN_Form'
                                                | 'evenementenkalenderDownloadNl_Form'
                                                | 'evenementenkalenderNLoud_Form'
                                                | 'fietsroutethijsserouteDE2_Form'
                                                | 'fietsroutethijsserouteNL2_Form'
                                                | 'fietsroutethijsseroutedu_Form'
                                                | 'fietsroutethijsseroutenl_Form'
                                                | 'fleeceplaidde_Form'
                                                | 'fleeceplaideng_Form'
                                                | 'fleeceplaidnl_Form'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'formulierBuienradar_Form'
                                                | 'fotoVanDeMaandD_Form'
                                                | 'fotoVanDeMaandEN_Form'
                                                | 'fotoVanDeMaandNL_Form'
                                                | 'fotowedstrijdTexelseLuchtenDE_Form'
                                                | 'fotowedstrijdTexelseLuchtenEN_Form'
                                                | 'fotowedstrijdTexelseLuchtenNL_Form'
                                                | 'fotowedstrijdlammetjesEN_Form'
                                                | 'fotowedstrijdlammetjesdu_Form'
                                                | 'fotowedstrijdlammetjesnl_Form'
                                                | 'fotowedstrijdnatuur_Form'
                                                | 'fotowedstrijdnatuurdu_Form'
                                                | 'gewinnspielTexelblues2023_Form'
                                                | 'grotegrazersnl2022_Form'
                                                | 'homepage_Entry'
                                                | 'kikkertnl2022_Form'
                                                | 'kinderroutenl2022_Form'
                                                | 'klachtformulierDu_Form'
                                                | 'klachtformuliernl_Form'
                                                | 'kleurplaatschaapjesdu_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'kleurplaatschaapjesen_Form'
                                                | 'kleurplaatschapennl_Form'
                                                | 'kleurplaatstranddu_Form'
                                                | 'kleurplaatstranden_Form'
                                                | 'kleurplaatstrandnl_Form'
                                                | 'kleurplaatvuurtorendu_Form'
                                                | 'kleurplaatvuurtorenen_Form'
                                                | 'kleurplaatvuurtorennl_Form'
                                                | 'kleurplaatzeehonddu_Form'
                                                | 'kleurplaatzeehonden_Form'
                                                | 'kleurplaatzeehondnl_Form'
                                                | 'library_Asset'
                                                | 'location_Entry'
                                                | 'meeuwenkolonienl2022_Form'
                                                | 'menu_Entry'
                                                | 'mindervaliden2_Form'
                                                | 'mindervaliden_Form'
                                                | 'muyslufterde2022_Form'
                                                | 'muyslufternl2022_Form'
                                                | 'myTexel_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'news_Entry'
                                                | 'nieuwsbriefaanmeldendu_Form'
                                                | 'nieuwsbriefaanmeldenen_Form'
                                                | 'nieuwsbriefaanmeldennl_Form'
                                                | 'notification_GlobalSet'
                                                | 'page_Entry'
                                                | 'persaanvraagEN_Form'
                                                | 'persverzoek_Form'
                                                | 'presseanfrage1_Form'
                                                | 'searchTags_Tag'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'sloffen1_Form'
                                                | 'sloffen_Form'
                                                | 'sommelpoodjenl2022_Form'
                                                | 'standaardFormulierNl_Form'
                                                | 'strandvlaktesduinvalleiennl2022_Form'
                                                | 'synonyms_Tag'
                                                | 'test1_Form'
                                                | 'test_Form'
                                                | 'testformulierNaUpdateCraft_Form'
                                                | 'testformulier_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'texelagendaII_Form'
                                                | 'texelfotoinsturen1_Form'
                                                | 'texelfotoinsturen_Form'
                                                | 'texelfotoinsturendu_Form'
                                                | 'texelgidsNl_Form'
                                                | 'texelpuzzelde_Form'
                                                | 'texelpuzzelnl_Form'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'veldnamenNL2024_Form'
                                                | 'verjaardagWijzigenNl_Form'
                                                | 'voetsporenvocnl2022_Form'
                                                | 'voorbeeldformulierMetAlleVelden_Form'
                                                | 'voucher_Entry'
                                                | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                                | 'wandelroutedemuydu_Form'
                                                | 'wandelroutedemuynl_Form'
                                                | 'wandelroutedenhoornNL_Form'
                                                | 'wandelroutehogebergde2022_Form'
                                                | 'wandelroutehogebergnl1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'wandelroutehogebergnl2022_Form'
                                                | 'wandelroutehogebergnl_Form'
                                                | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                                | 'wandelroutesLangsGroteGrazersDu_Form'
                                                | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                                | 'webshopVvvTexel_Form'
                                                | 'winEenVerblijfMetDeTexelAppDE_Form'
                                                | 'winEenVerblijfMetDeTexelAppNL_Form'
                                                | 'winactieBEpers_Form'
                                                | 'winactieBroadway2024DE_Form'
                                                | 'winactieBroadway2024EN_Form'
                                                | 'winactieBroadway2024NL_Form'
                                                | 'winactieBute_Form'
                                                | 'winactieFB14SterrenBEWA_Form'
                                                | 'winactieFB14SterrenDE_Form'
                                                | 'winactieFB14SterrenEN_Form'
                                                | 'winactieFB14SterrenNL_Form'
                                                | 'winactieFBVideolead2025DE_Form'
                                                | 'winactieFBVideolead2025EN_Form'
                                                | 'winactieFBVideolead2025NL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieFBVideoleadDE_Form'
                                                | 'winactieFBVideoleadEN1_Form'
                                                | 'winactieFBVideoleadNL_Form'
                                                | 'winactieKomTotRustOpTexelDE_Form'
                                                | 'winactieKomTotRustOpTexelEN_Form'
                                                | 'winactieKomTotRustOpTexelNL_Form'
                                                | 'winactieKoopplein_Form'
                                                | 'winactieKunstZomerDE_Form'
                                                | 'winactieKunstZomer_Form'
                                                | 'winactieLekkerLokaalDE_Form'
                                                | 'winactieLekkerLokaalEN_Form'
                                                | 'winactieLekkerLokaalNL_Form'
                                                | 'winactieParachutesprongDE_Form'
                                                | 'winactieParachutesprongEN_Form'
                                                | 'winactieParachutesprongNL_Form'
                                                | 'winactieRede_Form'
                                                | 'winactieRondritTexelDE_Form'
                                                | 'winactieRondritTexelNL_Form'
                                                | 'winactieRussstNL_Form'
                                                | 'winactieScheurkalender1_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieScheurkalender_Form'
                                                | 'winactieTexelCulinair2024DE_Form'
                                                | 'winactieTexelCulinairDE_Form'
                                                | 'winactieTexelCulinairNL2_Form'
                                                | 'winactieTexelCulinairNL24_Form'
                                                | 'winactieTexelCulinairNL_Form'
                                                | 'winactieTexelHalveMarathon2023_Form'
                                                | 'winactieTexelHalveMarathon2024_Form'
                                                | 'winactieTexelHalveMarathon20241_Form'
                                                | 'winactieTexelHalveMarathon20242_Form'
                                                | 'winactieTexelHalveMarathon_Form'
                                                | 'winactieTexelanapantoffelsNL_Form'
                                                | 'winactieTexelblues2023NL_Form'
                                                | 'winactieTexelblues2023_Form'
                                                | 'winactieTexelblues2024DE_Form'
                                                | 'winactieTexelblues2024NL_Form'
                                                | 'winactieTexelbues_Form'
                                                | 'winactieTexelshopDE1_Form'
                                                | 'winactieTexelshopNL1_Form'
                                                | 'winactieTexelshopNL_Form';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'winactieThreesAnnaDE_Form'
                                                | 'winactieThreesAnnaEN_Form'
                                                | 'winactieThreesAnnaNL_Form'
                                                | 'winactieWaves2023NL_Form'
                                                | 'winactieWaves2024DE_Form'
                                                | 'winactieWaves2024EN_Form'
                                                | 'winactieWaves2024NL_Form'
                                                | 'winactieWebshopDe_Form'
                                                | 'winactie_Form'
                                                | 'winactiefacebookbdu_Form'
                                                | 'winactiefbnl_Form';
                                        })
                                      | null;
                              } & {
                                  __typename?:
                                      | 'button_Asset_LinkType'
                                      | 'button_Category_LinkType'
                                      | 'button_Custom_LinkType'
                                      | 'button_Email_LinkType'
                                      | 'button_Embed_LinkType'
                                      | 'button_Entry_LinkType'
                                      | 'button_Phone_LinkType'
                                      | 'button_Site_LinkType'
                                      | 'button_Url_LinkType'
                                      | 'button_User_LinkType'
                                      | 'menuLink_Asset_LinkType'
                                      | 'menuLink_Category_LinkType'
                                      | 'menuLink_Custom_LinkType'
                                      | 'menuLink_Email_LinkType'
                                      | 'menuLink_Embed_LinkType'
                                      | 'menuLink_Entry_LinkType'
                                      | 'menuLink_Phone_LinkType'
                                      | 'menuLink_Site_LinkType'
                                      | 'menuLink_Url_LinkType'
                                      | 'menuLink_User_LinkType';
                              })
                            | null
                        >;
                    } & { __typename: 'footerLink_Entry' })
              >;
          } & { __typename?: 'footerCategory_Entry' })
        | null
    > | null;
    notification?:
        | { __typename?: 'contact_GlobalSet' }
        | ({
              header?: string | null;
              active?: boolean | null;
              enabled?: boolean | null;
              description?: string | null;
              button: Array<
                  | ({
                        text?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Address'
                                      | 'Element'
                                      | 'FotowedstrijdHondDE_Form'
                                      | 'FotowedstrijdHondEN_Form'
                                      | 'FotowedstrijdHondNL_Form'
                                      | 'FotowedstrijdPerSeizoenDE_Form'
                                      | 'FotowedstrijdPerSeizoenEN_Form'
                                      | 'FotowedstrijdPerSeizoen_Form'
                                      | 'StadswandelingDenBurgNL_Form'
                                      | 'StadswandelingVeldnamenEN_Form'
                                      | 'TexelAgendaEvent_Form'
                                      | 'TexelReisefuhrerherunterladenFamilie_Form'
                                      | 'TexelReisefuhrerherunterladen_Form'
                                      | 'User'
                                      | 'WinactieMeetandGreetMaksWAVESFestival_Form'
                                      | 'WinactieTexelblues2024EN_Form'
                                      | 'aanmeldenTexelApp2_Form'
                                      | 'aanmeldenTexelApp3_Form'
                                      | 'aanmeldenTexelApp_Form'
                                      | 'aanmeldenTopLedenVoorFotos_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'aanmeldenVvv125Jaar_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerDU_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeerNL_Form'
                                      | 'aanvraagFietsrouteAchterDeRugediekEnMeer_Form'
                                      | 'aanvraagWandelrouteAvontuurlijkeZuidenDU_Form'
                                      | 'aanvraagWandelrouteDurperrondjeDU_Form'
                                      | 'aanvraagWandelrouteEierlandseDuinenDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadDU_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadEN_Form'
                                      | 'aanvraagWandelrouteKrimbosEnHanenpadNL_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelDE_Form'
                                      | 'aanvraagWandelrouteRondomDeKopVanTexelNL_Form'
                                      | 'aanvraagWandelrouteStadswandelingNl_Form'
                                      | 'aanvraagWandelrouteveldnamenrouteDU_Form'
                                      | 'aanvraagwerkstukpakket_Form'
                                      | 'action_Entry'
                                      | 'activiteitaanmelden_Form'
                                      | 'adventskalender2_Form'
                                      | 'adventskalender4_Form'
                                      | 'adventskalender5_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'adventskalender9_Form'
                                      | 'adventskalender14_Form'
                                      | 'adventskalender15_Form'
                                      | 'adventskalender16_Form'
                                      | 'adventskalender18_Form'
                                      | 'adventskalender19_Form'
                                      | 'adventskalender20_Form'
                                      | 'adventskalender21_Form'
                                      | 'adventskalender22_Form'
                                      | 'adventskalender23_Form'
                                      | 'adventskalender24_Form'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'assetTags_Tag'
                                      | 'avontuurlijkezuidennl2022_Form'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'contact_GlobalSet';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'dataVerwijderenDE_Form'
                                      | 'dataVerwijderenEN_Form'
                                      | 'dataVerwijderenNL_Form'
                                      | 'driveInCinema2024NL_Form'
                                      | 'driveInCinemaNL_Form'
                                      | 'durperrondjenl2022_Form'
                                      | 'eierlandseduinennl2022_Form'
                                      | 'evenementenkalender2024DE_Form'
                                      | 'evenementenkalender2024EN_Form'
                                      | 'evenementenkalenderDownloadNl_Form'
                                      | 'evenementenkalenderNLoud_Form'
                                      | 'fietsroutethijsserouteDE2_Form'
                                      | 'fietsroutethijsserouteNL2_Form'
                                      | 'fietsroutethijsseroutedu_Form'
                                      | 'fietsroutethijsseroutenl_Form'
                                      | 'fleeceplaidde_Form'
                                      | 'fleeceplaideng_Form'
                                      | 'fleeceplaidnl_Form'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'formulierBuienradar_Form'
                                      | 'fotoVanDeMaandD_Form'
                                      | 'fotoVanDeMaandEN_Form'
                                      | 'fotoVanDeMaandNL_Form'
                                      | 'fotowedstrijdTexelseLuchtenDE_Form'
                                      | 'fotowedstrijdTexelseLuchtenEN_Form'
                                      | 'fotowedstrijdTexelseLuchtenNL_Form'
                                      | 'fotowedstrijdlammetjesEN_Form'
                                      | 'fotowedstrijdlammetjesdu_Form'
                                      | 'fotowedstrijdlammetjesnl_Form'
                                      | 'fotowedstrijdnatuur_Form'
                                      | 'fotowedstrijdnatuurdu_Form'
                                      | 'gewinnspielTexelblues2023_Form'
                                      | 'grotegrazersnl2022_Form'
                                      | 'homepage_Entry'
                                      | 'kikkertnl2022_Form'
                                      | 'kinderroutenl2022_Form'
                                      | 'klachtformulierDu_Form'
                                      | 'klachtformuliernl_Form'
                                      | 'kleurplaatschaapjesdu_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'kleurplaatschaapjesen_Form'
                                      | 'kleurplaatschapennl_Form'
                                      | 'kleurplaatstranddu_Form'
                                      | 'kleurplaatstranden_Form'
                                      | 'kleurplaatstrandnl_Form'
                                      | 'kleurplaatvuurtorendu_Form'
                                      | 'kleurplaatvuurtorenen_Form'
                                      | 'kleurplaatvuurtorennl_Form'
                                      | 'kleurplaatzeehonddu_Form'
                                      | 'kleurplaatzeehonden_Form'
                                      | 'kleurplaatzeehondnl_Form'
                                      | 'library_Asset'
                                      | 'location_Entry'
                                      | 'meeuwenkolonienl2022_Form'
                                      | 'menu_Entry'
                                      | 'mindervaliden2_Form'
                                      | 'mindervaliden_Form'
                                      | 'muyslufterde2022_Form'
                                      | 'muyslufternl2022_Form'
                                      | 'myTexel_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'news_Entry'
                                      | 'nieuwsbriefaanmeldendu_Form'
                                      | 'nieuwsbriefaanmeldenen_Form'
                                      | 'nieuwsbriefaanmeldennl_Form'
                                      | 'notification_GlobalSet'
                                      | 'page_Entry'
                                      | 'persaanvraagEN_Form'
                                      | 'persverzoek_Form'
                                      | 'presseanfrage1_Form'
                                      | 'searchTags_Tag'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'sloffen1_Form'
                                      | 'sloffen_Form'
                                      | 'sommelpoodjenl2022_Form'
                                      | 'standaardFormulierNl_Form'
                                      | 'strandvlaktesduinvalleiennl2022_Form'
                                      | 'synonyms_Tag'
                                      | 'test1_Form'
                                      | 'test_Form'
                                      | 'testformulierNaUpdateCraft_Form'
                                      | 'testformulier_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'texelagendaII_Form'
                                      | 'texelfotoinsturen1_Form'
                                      | 'texelfotoinsturen_Form'
                                      | 'texelfotoinsturendu_Form'
                                      | 'texelgidsNl_Form'
                                      | 'texelpuzzelde_Form'
                                      | 'texelpuzzelnl_Form'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'veldnamenNL2024_Form'
                                      | 'verjaardagWijzigenNl_Form'
                                      | 'voetsporenvocnl2022_Form'
                                      | 'voorbeeldformulierMetAlleVelden_Form'
                                      | 'voucher_Entry'
                                      | 'wandelrouteLangsDeMeeuwenkolonieDu_Form'
                                      | 'wandelroutedemuydu_Form'
                                      | 'wandelroutedemuynl_Form'
                                      | 'wandelroutedenhoornNL_Form'
                                      | 'wandelroutehogebergde2022_Form'
                                      | 'wandelroutehogebergnl1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'wandelroutehogebergnl2022_Form'
                                      | 'wandelroutehogebergnl_Form'
                                      | 'wandelroutesInDeSporenVanDeVocDu_Form'
                                      | 'wandelroutesLangsGroteGrazersDu_Form'
                                      | 'wandelroutesStrandvlaktesEnDuinvalleienDu_Form'
                                      | 'webshopVvvTexel_Form'
                                      | 'winEenVerblijfMetDeTexelAppDE_Form'
                                      | 'winEenVerblijfMetDeTexelAppNL_Form'
                                      | 'winactieBEpers_Form'
                                      | 'winactieBroadway2024DE_Form'
                                      | 'winactieBroadway2024EN_Form'
                                      | 'winactieBroadway2024NL_Form'
                                      | 'winactieBute_Form'
                                      | 'winactieFB14SterrenBEWA_Form'
                                      | 'winactieFB14SterrenDE_Form'
                                      | 'winactieFB14SterrenEN_Form'
                                      | 'winactieFB14SterrenNL_Form'
                                      | 'winactieFBVideolead2025DE_Form'
                                      | 'winactieFBVideolead2025EN_Form'
                                      | 'winactieFBVideolead2025NL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieFBVideoleadDE_Form'
                                      | 'winactieFBVideoleadEN1_Form'
                                      | 'winactieFBVideoleadNL_Form'
                                      | 'winactieKomTotRustOpTexelDE_Form'
                                      | 'winactieKomTotRustOpTexelEN_Form'
                                      | 'winactieKomTotRustOpTexelNL_Form'
                                      | 'winactieKoopplein_Form'
                                      | 'winactieKunstZomerDE_Form'
                                      | 'winactieKunstZomer_Form'
                                      | 'winactieLekkerLokaalDE_Form'
                                      | 'winactieLekkerLokaalEN_Form'
                                      | 'winactieLekkerLokaalNL_Form'
                                      | 'winactieParachutesprongDE_Form'
                                      | 'winactieParachutesprongEN_Form'
                                      | 'winactieParachutesprongNL_Form'
                                      | 'winactieRede_Form'
                                      | 'winactieRondritTexelDE_Form'
                                      | 'winactieRondritTexelNL_Form'
                                      | 'winactieRussstNL_Form'
                                      | 'winactieScheurkalender1_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieScheurkalender_Form'
                                      | 'winactieTexelCulinair2024DE_Form'
                                      | 'winactieTexelCulinairDE_Form'
                                      | 'winactieTexelCulinairNL2_Form'
                                      | 'winactieTexelCulinairNL24_Form'
                                      | 'winactieTexelCulinairNL_Form'
                                      | 'winactieTexelHalveMarathon2023_Form'
                                      | 'winactieTexelHalveMarathon2024_Form'
                                      | 'winactieTexelHalveMarathon20241_Form'
                                      | 'winactieTexelHalveMarathon20242_Form'
                                      | 'winactieTexelHalveMarathon_Form'
                                      | 'winactieTexelanapantoffelsNL_Form'
                                      | 'winactieTexelblues2023NL_Form'
                                      | 'winactieTexelblues2023_Form'
                                      | 'winactieTexelblues2024DE_Form'
                                      | 'winactieTexelblues2024NL_Form'
                                      | 'winactieTexelbues_Form'
                                      | 'winactieTexelshopDE1_Form'
                                      | 'winactieTexelshopNL1_Form'
                                      | 'winactieTexelshopNL_Form';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'winactieThreesAnnaDE_Form'
                                      | 'winactieThreesAnnaEN_Form'
                                      | 'winactieThreesAnnaNL_Form'
                                      | 'winactieWaves2023NL_Form'
                                      | 'winactieWaves2024DE_Form'
                                      | 'winactieWaves2024EN_Form'
                                      | 'winactieWaves2024NL_Form'
                                      | 'winactieWebshopDe_Form'
                                      | 'winactie_Form'
                                      | 'winactiefacebookbdu_Form'
                                      | 'winactiefbnl_Form';
                              })
                            | null;
                    } & {
                        __typename?:
                            | 'button_Asset_LinkType'
                            | 'button_Category_LinkType'
                            | 'button_Custom_LinkType'
                            | 'button_Email_LinkType'
                            | 'button_Embed_LinkType'
                            | 'button_Entry_LinkType'
                            | 'button_Phone_LinkType'
                            | 'button_Site_LinkType'
                            | 'button_Url_LinkType'
                            | 'button_User_LinkType'
                            | 'menuLink_Asset_LinkType'
                            | 'menuLink_Category_LinkType'
                            | 'menuLink_Custom_LinkType'
                            | 'menuLink_Email_LinkType'
                            | 'menuLink_Embed_LinkType'
                            | 'menuLink_Entry_LinkType'
                            | 'menuLink_Phone_LinkType'
                            | 'menuLink_Site_LinkType'
                            | 'menuLink_Url_LinkType'
                            | 'menuLink_User_LinkType';
                    })
                  | null
              >;
          } & { __typename?: 'notification_GlobalSet' })
        | null;
    search?:
        | ({ uri?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ uri?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null;
} & { __typename?: 'Query' };

export type BreadcrumbFragment = {
    id?: string | null;
    title?: string | null;
    slug?: string | null;
    uri?: string | null;
    settingsMenuHidden?: boolean | null;
    settingsMenuTitle?: string | null;
    parent?:
        | ({ uri?: string | null; id?: string | null } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'page_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry';
          })
        | ({ uri?: string | null; id?: string | null } & {
              __typename?:
                  | 'sectionBanner_Entry'
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | null;
    children: Array<
        | ({
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
          } & {
              __typename?:
                  | 'action_Entry'
                  | 'agenda_Entry'
                  | 'article_Entry'
                  | 'banner_Entry'
                  | 'companies_Entry'
                  | 'contact2_Entry'
                  | 'contact_Entry'
                  | 'footerCategory_Entry'
                  | 'footerLink_Entry'
                  | 'homepage_Entry'
                  | 'location_Entry'
                  | 'menu_Entry'
                  | 'myTexel_Entry'
                  | 'news_Entry'
                  | 'search_Entry'
                  | 'sectionAccommodations_Entry'
                  | 'sectionAccordion_Entry'
                  | 'sectionAction_Entry'
                  | 'sectionActivities_Entry'
                  | 'sectionBanner_Entry';
          })
        | ({
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
          } & {
              __typename?:
                  | 'sectionButton_Entry'
                  | 'sectionCompanies_Entry'
                  | 'sectionEmbed_Entry'
                  | 'sectionForms_Entry'
                  | 'sectionHeader_Entry'
                  | 'sectionHierarchicalTiles_Entry'
                  | 'sectionImages_Entry'
                  | 'sectionMap_Entry'
                  | 'sectionText_Entry'
                  | 'sectionTiles_Entry'
                  | 'sectionTip_Entry'
                  | 'sectionTravelbaseTiles_Entry'
                  | 'sectionVideo_Entry'
                  | 'sectionWebcam_Entry'
                  | 'thankYou_Entry'
                  | 'tip_Entry'
                  | 'voucher_Entry';
          })
        | ({
              slug?: string | null;
              id?: string | null;
              title?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              children: Array<
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry'
                            | 'sectionBanner_Entry';
                    })
                  | ({
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                    } & {
                        __typename?:
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children: Array<
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry'
                                      | 'sectionBanner_Entry';
                              })
                            | ({
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                              } & {
                                  __typename?:
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children: Array<
                                      | {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry'
                                                | 'sectionBanner_Entry';
                                        }
                                      | {
                                            __typename?:
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_Entry'
                                                          | 'agenda_Entry'
                                                          | 'article_Entry'
                                                          | 'banner_Entry'
                                                          | 'companies_Entry'
                                                          | 'contact2_Entry'
                                                          | 'contact_Entry'
                                                          | 'footerCategory_Entry'
                                                          | 'footerLink_Entry'
                                                          | 'homepage_Entry'
                                                          | 'location_Entry'
                                                          | 'menu_Entry'
                                                          | 'myTexel_Entry'
                                                          | 'news_Entry'
                                                          | 'page_Entry'
                                                          | 'search_Entry'
                                                          | 'sectionAccommodations_Entry'
                                                          | 'sectionAccordion_Entry'
                                                          | 'sectionAction_Entry'
                                                          | 'sectionActivities_Entry';
                                                  })
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'sectionBanner_Entry'
                                                          | 'sectionButton_Entry'
                                                          | 'sectionCompanies_Entry'
                                                          | 'sectionEmbed_Entry'
                                                          | 'sectionForms_Entry'
                                                          | 'sectionHeader_Entry'
                                                          | 'sectionHierarchicalTiles_Entry'
                                                          | 'sectionImages_Entry'
                                                          | 'sectionMap_Entry'
                                                          | 'sectionText_Entry'
                                                          | 'sectionTiles_Entry'
                                                          | 'sectionTip_Entry'
                                                          | 'sectionTravelbaseTiles_Entry'
                                                          | 'sectionVideo_Entry'
                                                          | 'sectionWebcam_Entry'
                                                          | 'thankYou_Entry'
                                                          | 'tip_Entry'
                                                          | 'voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'page_Entry' })
                                  >;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_Entry'
                                                | 'agenda_Entry'
                                                | 'article_Entry'
                                                | 'banner_Entry'
                                                | 'companies_Entry'
                                                | 'contact2_Entry'
                                                | 'contact_Entry'
                                                | 'footerCategory_Entry'
                                                | 'footerLink_Entry'
                                                | 'homepage_Entry'
                                                | 'location_Entry'
                                                | 'menu_Entry'
                                                | 'myTexel_Entry'
                                                | 'news_Entry'
                                                | 'page_Entry'
                                                | 'search_Entry'
                                                | 'sectionAccommodations_Entry'
                                                | 'sectionAccordion_Entry'
                                                | 'sectionAction_Entry'
                                                | 'sectionActivities_Entry';
                                        })
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'sectionBanner_Entry'
                                                | 'sectionButton_Entry'
                                                | 'sectionCompanies_Entry'
                                                | 'sectionEmbed_Entry'
                                                | 'sectionForms_Entry'
                                                | 'sectionHeader_Entry'
                                                | 'sectionHierarchicalTiles_Entry'
                                                | 'sectionImages_Entry'
                                                | 'sectionMap_Entry'
                                                | 'sectionText_Entry'
                                                | 'sectionTiles_Entry'
                                                | 'sectionTip_Entry'
                                                | 'sectionTravelbaseTiles_Entry'
                                                | 'sectionVideo_Entry'
                                                | 'sectionWebcam_Entry'
                                                | 'thankYou_Entry'
                                                | 'tip_Entry'
                                                | 'voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'page_Entry' })
                        >;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_Entry'
                                      | 'agenda_Entry'
                                      | 'article_Entry'
                                      | 'banner_Entry'
                                      | 'companies_Entry'
                                      | 'contact2_Entry'
                                      | 'contact_Entry'
                                      | 'footerCategory_Entry'
                                      | 'footerLink_Entry'
                                      | 'homepage_Entry'
                                      | 'location_Entry'
                                      | 'menu_Entry'
                                      | 'myTexel_Entry'
                                      | 'news_Entry'
                                      | 'page_Entry'
                                      | 'search_Entry'
                                      | 'sectionAccommodations_Entry'
                                      | 'sectionAccordion_Entry'
                                      | 'sectionAction_Entry'
                                      | 'sectionActivities_Entry';
                              })
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'sectionBanner_Entry'
                                      | 'sectionButton_Entry'
                                      | 'sectionCompanies_Entry'
                                      | 'sectionEmbed_Entry'
                                      | 'sectionForms_Entry'
                                      | 'sectionHeader_Entry'
                                      | 'sectionHierarchicalTiles_Entry'
                                      | 'sectionImages_Entry'
                                      | 'sectionMap_Entry'
                                      | 'sectionText_Entry'
                                      | 'sectionTiles_Entry'
                                      | 'sectionTip_Entry'
                                      | 'sectionTravelbaseTiles_Entry'
                                      | 'sectionVideo_Entry'
                                      | 'sectionWebcam_Entry'
                                      | 'thankYou_Entry'
                                      | 'tip_Entry'
                                      | 'voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'page_Entry' })
              >;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_Entry'
                            | 'agenda_Entry'
                            | 'article_Entry'
                            | 'banner_Entry'
                            | 'companies_Entry'
                            | 'contact2_Entry'
                            | 'contact_Entry'
                            | 'footerCategory_Entry'
                            | 'footerLink_Entry'
                            | 'homepage_Entry'
                            | 'location_Entry'
                            | 'menu_Entry'
                            | 'myTexel_Entry'
                            | 'news_Entry'
                            | 'page_Entry'
                            | 'search_Entry'
                            | 'sectionAccommodations_Entry'
                            | 'sectionAccordion_Entry'
                            | 'sectionAction_Entry'
                            | 'sectionActivities_Entry';
                    })
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'sectionBanner_Entry'
                            | 'sectionButton_Entry'
                            | 'sectionCompanies_Entry'
                            | 'sectionEmbed_Entry'
                            | 'sectionForms_Entry'
                            | 'sectionHeader_Entry'
                            | 'sectionHierarchicalTiles_Entry'
                            | 'sectionImages_Entry'
                            | 'sectionMap_Entry'
                            | 'sectionText_Entry'
                            | 'sectionTiles_Entry'
                            | 'sectionTip_Entry'
                            | 'sectionTravelbaseTiles_Entry'
                            | 'sectionVideo_Entry'
                            | 'sectionWebcam_Entry'
                            | 'thankYou_Entry'
                            | 'tip_Entry'
                            | 'voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'page_Entry' })
    >;
} & { __typename?: 'page_Entry' };

export const FieldFragmentDoc = gql`
    fragment Field on FieldInterface {
        instructions
        instructionsPosition
        label
        placeholder
        required
        handle
        errorMessage
    }
`;
export const SingleTextFieldFragmentDoc = gql`
    fragment SingleTextField on Field_SingleLineText {
        limit
        maxType
        max
        defaultValue
    }
`;
export const MultipleTextFieldFragmentDoc = gql`
    fragment MultipleTextField on Field_MultiLineText {
        limit
        maxType
        max
        defaultValue
    }
`;
export const EmailTextFieldFragmentDoc = gql`
    fragment EmailTextField on Field_Email {
        defaultValue
    }
`;
export const PhoneTextFieldFragmentDoc = gql`
    fragment PhoneTextField on Field_Phone {
        defaultValue
    }
`;
export const TextFieldFragmentDoc = gql`
    fragment TextField on FieldInterface {
        ...SingleTextField
        ...MultipleTextField
        ...EmailTextField
        ...PhoneTextField
    }
    ${SingleTextFieldFragmentDoc}
    ${MultipleTextFieldFragmentDoc}
    ${EmailTextFieldFragmentDoc}
    ${PhoneTextFieldFragmentDoc}
`;
export const NumberFieldFragmentDoc = gql`
    fragment NumberField on Field_Number {
        defaultValue
        min
        max
    }
`;
export const CheckboxesFieldFragmentDoc = gql`
    fragment CheckboxesField on Field_Checkboxes {
        options {
            isDefault
            value
        }
    }
`;
export const RadioFieldFragmentDoc = gql`
    fragment RadioField on Field_Radio {
        options {
            isDefault
            value
        }
    }
`;
export const DropdownFieldFragmentDoc = gql`
    fragment DropdownField on Field_Dropdown {
        options {
            label
            isDefault
            value
        }
    }
`;
export const DateFieldFragmentDoc = gql`
    fragment DateField on Field_Date {
        displayType
        defaultDate
        defaultValue
    }
`;
export const FileFieldFragmentDoc = gql`
    fragment FileField on Field_FileUpload {
        allowedKinds
        limitFiles
        sizeLimit
        sizeMinLimit
    }
`;
export const HeadingFieldFragmentDoc = gql`
    fragment HeadingField on Field_Heading {
        headingSize
    }
`;
export const AddressFieldFragmentDoc = gql`
    fragment AddressField on Field_Address {
        fields {
            label
            enabled
            errorMessage
            defaultValue
            required
            placeholder
            handle
        }
    }
`;
export const BaseAgreeFieldFragmentDoc = gql`
    fragment BaseAgreeField on Field_Agree {
        checkedValue
        uncheckedValue
        descriptionHtml
        defaultState
        required
    }
`;
export const NewsletterAgreeFieldFragmentDoc = gql`
    fragment NewsletterAgreeField on Field_Newsletter {
        checkedValue
        uncheckedValue
        descriptionHtml
        defaultState
        required
    }
`;
export const AgreeFieldFragmentDoc = gql`
    fragment AgreeField on FieldInterface {
        ...BaseAgreeField
        ...NewsletterAgreeField
    }
    ${BaseAgreeFieldFragmentDoc}
    ${NewsletterAgreeFieldFragmentDoc}
`;
export const NameFieldFragmentDoc = gql`
    fragment NameField on Field_Name {
        fields {
            label
            enabled
            required
            handle
            placeholder
        }
    }
`;
export const FieldRendererFragmentDoc = gql`
    fragment FieldRenderer on FieldInterface {
        ...Field
        ...TextField
        ...NumberField
        ...CheckboxesField
        ...RadioField
        ...DropdownField
        ...DateField
        ...FileField
        ...HeadingField
        ...AddressField
        ...AgreeField
        ...NameField
    }
    ${FieldFragmentDoc}
    ${TextFieldFragmentDoc}
    ${NumberFieldFragmentDoc}
    ${CheckboxesFieldFragmentDoc}
    ${RadioFieldFragmentDoc}
    ${DropdownFieldFragmentDoc}
    ${DateFieldFragmentDoc}
    ${FileFieldFragmentDoc}
    ${HeadingFieldFragmentDoc}
    ${AddressFieldFragmentDoc}
    ${AgreeFieldFragmentDoc}
    ${NameFieldFragmentDoc}
`;
export const GroupFieldFragmentDoc = gql`
    fragment GroupField on Field_Group {
        fields {
            ...FieldRenderer
        }
    }
    ${FieldRendererFragmentDoc}
`;
export const FormFragmentDoc = gql`
    fragment Form on FormInterface {
        id
        handle
        siteId
        settings {
            submitActionFormHide
            submitActionMessageHtml
            submitAction
            submitActionTab
            redirectUrl
            integrations {
                handle
                enabled
            }
        }
        formFields {
            ...FieldRenderer
            ...GroupField
        }
    }
    ${FieldRendererFragmentDoc}
    ${GroupFieldFragmentDoc}
`;
export const ListImageFragmentDoc = gql`
    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
    }
`;
export const RentalUnitFragmentDoc = gql`
    fragment rentalUnit on RentalUnit {
        id
        name
        slug
        type
        amountBedrooms
        maxOccupancy
        tagline
        accommodation {
            name
            hasPublicPage
            place
        }
        reviewStats {
            average
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const TransformFragmentDoc = gql`
    fragment Transform on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;
export const OrderItemFragmentDoc = gql`
    fragment OrderItem on OrderItem {
        ... on Booking {
            downloadVoucherUrl
            status
            requiresApproval
            amountAdults
            amountPets
            amountChildren
            amountBabies
            amountYouths
            arrivalDate
            departureDate
            handleDepositPayment
            deposit
            paymentPrice
            rentalUnit {
                id
                code
                name
                slug
                accommodation {
                    id
                    hasPublicPage
                    name
                    place
                    checkInEndTime
                    checkInStartTime
                    checkOutTime
                    type
                }
                type
                maxOccupancy
                amountBedrooms
                jpegThumbnail: listImage {
                    transform(config: TILE, format: JPEG) {
                        ...Transform
                    }
                }
                webpThumbnail: listImage {
                    transform(config: TILE, format: WEBP) {
                        ...Transform
                    }
                }
            }
        }
    }
    ${TransformFragmentDoc}
`;
export const MyOrderFragmentDoc = gql`
    fragment MyOrder on Order {
        id
        number
        createdDate
        paymentOptions {
            fullPaymentDueDate
            totalAmountDue
        }
        orderItems {
            ...OrderItem
        }
    }
    ${OrderItemFragmentDoc}
`;
export const RentalUnitsBySlugFragmentDoc = gql`
    fragment RentalUnitsBySlug on RentalUnit {
        id
        name
        slug
        tagline
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const AccommodationsBySlugFragmentDoc = gql`
    fragment AccommodationsBySlug on Accommodation {
        id
        name
        slug
        descriptionGeneral
        webpListImage: mainImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        mainImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const HeroTransformFragmentDoc = gql`
    fragment HeroTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 48, height: 15, mode: "crop", immediately: false)
        extension
        url
        ogImageUrl: url @transform(width: 1200, immediately: false)
        srcset: url
            @transformsrcset(
                sizes: ["1024w", "1920w", "2560w"]
                width: 1920
                height: 640
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["1024w", "1920w", "2560w"]
                width: 1920
                height: 640
                mode: "crop"
                immediately: false
            )
        srcsetMobile: url
            @transformsrcset(sizes: ["320w", "640w"], width: 640, height: 480, mode: "crop", immediately: false)
        srcsetMobileWebp: url
            @transformsrcset(
                sizes: ["320w", "640w"]
                width: 640
                height: 480
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
    }
`;
export const GalleryTransformFragmentDoc = gql`
    fragment GalleryTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 48, height: 27, mode: "crop", immediately: false)
        extension
        url
        width
        height
        srcset: url
            @transformsrcset(
                sizes: ["320w", "640w", "1024w", "1920w", "2560w"]
                width: 1920
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["320w", "640w", "1024w", "1920w", "2560w"]
                width: 1920
                format: "webp"
                immediately: false
                quality: 70
            )
    }
`;
export const PhotosCarouselGalleryFragmentDoc = gql`
    fragment PhotosCarouselGallery on AssetInterface {
        ... on library_Asset {
            assetSource
        }
        ...GalleryTransform
    }
    ${GalleryTransformFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
    fragment Article on article_Entry {
        newsHeroImage {
            ...HeroTransform
        }
        postDate @formatDateTime(format: "j F Y", locale: $lang)
        title
        textBody
        gallery: newsImages {
            ...PhotosCarouselGallery
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        id
        parent {
            id
        }
        settingsSearchTags {
            title
        }
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${PhotosCarouselGalleryFragmentDoc}
`;
export const LinkFieldFragmentDoc = gql`
    fragment LinkField on HyperLinkInterface {
        text
        target
        url
        type
        element {
            title
            uri
        }
    }
`;
export const ContactMatrixFragmentDoc = gql`
    fragment ContactMatrix on contact2_Entry {
        whatsapp
        phone
        email
        messenger
        myTexel
        shop
    }
`;
export const PinsFragmentDoc = gql`
    fragment Pins on pins_TableRow {
        latitude
        longitude
        symbol
        label
    }
`;
export const TipFragmentDoc = gql`
    fragment Tip on tip_Entry {
        title
        tipText
        tipName
        tipRole
        tipAvatar {
            height
            width
            thumbUrl: url @transform(width: 56, height: 56, immediately: true)
        }
    }
`;
export const BannerFragmentDoc = gql`
    fragment Banner on banner_Entry {
        title
        heroImage {
            thumbUrl: url @transform(width: 328, height: 340, immediately: true)
        }
        button {
            ...LinkField
        }
    }
    ${LinkFieldFragmentDoc}
`;
export const TileTransformFragmentDoc = gql`
    fragment TileTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 64, height: 45, mode: "crop", immediately: false)
        extension
        url
        srcset: url
            @transformsrcset(
                sizes: ["64w", "320w", "640w"]
                width: 1920
                height: 1080
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["64w", "320w", "640w"]
                width: 1920
                height: 1080
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
    }
`;
export const CardImageFragmentDoc = gql`
    fragment CardImage on AssetInterface {
        ...TileTransform
    }
    ${TileTransformFragmentDoc}
`;
export const PageCardFragmentDoc = gql`
    fragment PageCard on page_Entry {
        id
        settingsMetaDescription
        settingsNotSearchable
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const NewsCardFragmentDoc = gql`
    fragment NewsCard on news_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const ArticleCardFragmentDoc = gql`
    fragment ArticleCard on article_Entry {
        postDate @formatDateTime(format: "j F Y", locale: $lang)
        settingsMetaDescription
        heroImage: newsHeroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const HomeCardFragmentDoc = gql`
    fragment HomeCard on homepage_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const ContactCardFragmentDoc = gql`
    fragment ContactCard on contact_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const AgendaCardFragmentDoc = gql`
    fragment AgendaCard on agenda_Entry {
        settingsMetaDescription
    }
`;
export const ThankYouCardFragmentDoc = gql`
    fragment ThankYouCard on thankYou_Entry {
        settingsMetaDescription
    }
`;
export const CompanyCardFragmentDoc = gql`
    fragment CompanyCard on companies_Entry {
        settingsMetaDescription
    }
`;
export const MyTexelCardFragmentDoc = gql`
    fragment MyTexelCard on myTexel_Entry {
        settingsMetaDescription
    }
`;
export const CardFragmentDoc = gql`
    fragment Card on EntryInterface {
        title
        uri
        ...PageCard
        ...NewsCard
        ...ArticleCard
        ...HomeCard
        ...ContactCard
        ...AgendaCard
        ...ThankYouCard
        ...CompanyCard
        ...MyTexelCard
    }
    ${PageCardFragmentDoc}
    ${NewsCardFragmentDoc}
    ${ArticleCardFragmentDoc}
    ${HomeCardFragmentDoc}
    ${ContactCardFragmentDoc}
    ${AgendaCardFragmentDoc}
    ${ThankYouCardFragmentDoc}
    ${CompanyCardFragmentDoc}
    ${MyTexelCardFragmentDoc}
`;
export const TilesBlockFragmentDoc = gql`
    fragment TilesBlock on sectionTiles_Entry {
        tilesChildren
        tilesSiblings
        tilesEntries {
            ...Card
        }
    }
    ${CardFragmentDoc}
`;
export const HierarchicalTilesBlockFragmentDoc = gql`
    fragment HierarchicalTilesBlock on sectionHierarchicalTiles_Entry {
        relationType
    }
`;
export const TravelbaseTileFragmentDoc = gql`
    fragment TravelbaseTile on sectionTravelbaseTiles_Entry {
        travelbaseSlugs {
            slug
        }
        travelbaseEntityType
    }
`;
export const ContentMatrixFragmentDoc = gql`
    fragment ContentMatrix on contentMatrix_MatrixField {
        ... on sectionText_Entry {
            text
        }
        ... on sectionHeader_Entry {
            header
        }
        ... on sectionVideo_Entry {
            videoUrl
            autoplay
        }
        ... on sectionImages_Entry {
            gallery: images {
                ...PhotosCarouselGallery
            }
        }
        ... on sectionAccordion_Entry {
            header
            text
        }
        ... on sectionButton_Entry {
            button {
                ...LinkField
            }
        }
        ... on sectionWebcam_Entry {
            webcamUrl
        }
        ... on sectionMap_Entry {
            pins {
                ...Pins
            }
        }
        ... on sectionAccommodations_Entry {
            accommodationsQuery
        }
        ... on sectionCompanies_Entry {
            companiesQuery
        }
        ... on sectionActivities_Entry {
            activitiesQuery
        }
        ... on sectionTip_Entry {
            tip {
                ...Tip
            }
        }
        ... on sectionBanner_Entry {
            banner {
                ...Banner
            }
        }
        ... on sectionTiles_Entry {
            ...TilesBlock
        }
        ... on sectionHierarchicalTiles_Entry {
            ...HierarchicalTilesBlock
        }
        ... on sectionEmbed_Entry {
            embed
        }
        ... on sectionForms_Entry {
            id
        }
        ... on sectionTravelbaseTiles_Entry {
            ...TravelbaseTile
        }
    }
    ${PhotosCarouselGalleryFragmentDoc}
    ${LinkFieldFragmentDoc}
    ${PinsFragmentDoc}
    ${TipFragmentDoc}
    ${BannerFragmentDoc}
    ${TilesBlockFragmentDoc}
    ${HierarchicalTilesBlockFragmentDoc}
    ${TravelbaseTileFragmentDoc}
`;
export const ContactFragmentDoc = gql`
    fragment Contact on contact_Entry {
        title
        heroImage {
            ...HeroTransform
        }
        textBody
        button {
            ...LinkField
        }
        contactMatrix {
            ...ContactMatrix
        }
        contentMatrix {
            ...ContentMatrix
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        settingsCookieConsent
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${LinkFieldFragmentDoc}
    ${ContactMatrixFragmentDoc}
    ${ContentMatrixFragmentDoc}
`;
export const ContactGlobalsFragmentDoc = gql`
    fragment ContactGlobals on contact_GlobalSet {
        contactEmailText
        contactMessengerText
        contactMyTexelText
        contactPhoneText
        contactShopText
        contactWhatsappText
    }
`;
export const HighlightTransformFragmentDoc = gql`
    fragment HighlightTransform on AssetInterface {
        width
        height
        focalPoint
        title
        extension
        url
        srcset: url
            @transformsrcset(
                sizes: ["64w", "128w", "80w", "160w"]
                width: 64
                height: 64
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["64w", "128w", "80w", "160w"]
                width: 64
                height: 64
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
        placeholderUrl: url @transform(width: 4, height: 4, mode: "crop", immediately: false, quality: 70)
    }
`;
export const HighlightFragmentDoc = gql`
    fragment Highlight on EntryInterface {
        title
        uri
        __typename
        ... on page_Entry {
            heroImage {
                ...HighlightTransform
            }
        }
        ... on article_Entry {
            newsHeroImage {
                ...HighlightTransform
            }
        }
    }
    ${HighlightTransformFragmentDoc}
`;
export const ActionFragmentDoc = gql`
    fragment Action on action_Entry {
        title
        description
        button {
            ...LinkField
        }
    }
    ${LinkFieldFragmentDoc}
`;
export const HomeFragmentDoc = gql`
    fragment Home on homepage_Entry {
        heroImage {
            ...HeroTransform
        }
        title
        video
        homeVideoEnabled
        homeVideoTitle
        homeVideoDescription
        homeHighlightsTitle
        homeHighlights {
            ...Highlight
            ... on page_Entry {
                settingsSeoTitle
                settingsMetaDescription
            }
        }
        homeCurrent {
            ...Card
        }
        action {
            ...Action
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        header
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${HighlightFragmentDoc}
    ${CardFragmentDoc}
    ${ActionFragmentDoc}
`;
export const NewsFragmentDoc = gql`
    fragment News on news_Entry {
        title
        heroImage {
            ...HeroTransform
        }
        textBody
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
`;
export const PageFragmentDoc = gql`
    fragment Page on page_Entry {
        uri
        title
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        settingsNotSearchable
        heroImage {
            ...HeroTransform
        }
        heroImageVisible
        root
        contentMatrix {
            ...ContentMatrix
        }
        children {
            ...Card
        }
        id
        parent {
            id
            children(section: ["pages"]) {
                ...Card
            }
        }
        settingsSearchTags {
            title
        }
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${ContentMatrixFragmentDoc}
    ${CardFragmentDoc}
`;
export const SearchFragmentDoc = gql`
    fragment Search on search_Entry {
        title
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
`;
export const SearchHitFragmentDoc = gql`
    fragment SearchHit on SearchType {
        postDate
        title
        type
        uri
        image {
            extension
            placeholderUrl
            srcset
            srcsetWebp
            title
            url
        }
    }
`;
export const AgendaFragmentDoc = gql`
    fragment Agenda on agenda_Entry {
        title
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
`;
export const MenuItemPageFieldsFragmentDoc = gql`
    fragment MenuItemPageFields on page_Entry {
        slug
        id
        title
        uri
        settingsMenuHidden
        settingsMenuTitle
        parent {
            uri
            id
        }
    }
`;
export const MenuItemPageRecursiveFragmentDoc = gql`
    fragment MenuItemPageRecursive on page_Entry {
        children {
            ...MenuItemPageFields
            children {
                ...MenuItemPageFields
                children {
                    ...MenuItemPageFields
                    children {
                        ...MenuItemPageFields
                    }
                }
            }
        }
    }
    ${MenuItemPageFieldsFragmentDoc}
`;
export const MenuItemsFragmentDoc = gql`
    fragment MenuItems on menu_Entry {
        id
        title
        menuLink {
            ...LinkField
            element {
                ...MenuItemPageFields
                ...MenuItemPageRecursive
            }
        }
    }
    ${LinkFieldFragmentDoc}
    ${MenuItemPageFieldsFragmentDoc}
    ${MenuItemPageRecursiveFragmentDoc}
`;
export const BreadcrumbFragmentDoc = gql`
    fragment Breadcrumb on page_Entry {
        id
        title
        ...MenuItemPageFields
        ...MenuItemPageRecursive
    }
    ${MenuItemPageFieldsFragmentDoc}
    ${MenuItemPageRecursiveFragmentDoc}
`;
export const FooterSubmenuFragmentDoc = gql`
    fragment FooterSubmenu on footerLink_Entry {
        title
        __typename
        menuLink {
            ...LinkField
        }
    }
    ${LinkFieldFragmentDoc}
`;
export const FooterItemsFragmentDoc = gql`
    fragment FooterItems on footerCategory_Entry {
        title
        children {
            ...FooterSubmenu
        }
    }
    ${FooterSubmenuFragmentDoc}
`;
export const NotificationFragmentDoc = gql`
    fragment Notification on notification_GlobalSet {
        button {
            ...LinkField
            text
        }
        header
        active
        enabled
        description
    }
    ${LinkFieldFragmentDoc}
`;
export const GlobalQueryFragmentDoc = gql`
    fragment GlobalQuery on Query {
        menu: entries(level: 1, site: [$lang], section: "menu") {
            ...MenuItems
        }
        breadcrumb: entries(level: 1, site: [$lang], section: "pages") {
            ...Breadcrumb
        }
        footer: entries(level: 1, site: [$lang], section: "footer") {
            ...FooterItems
        }
        notification: globalSet(handle: "notification", site: [$lang]) {
            ...Notification
        }
        search: entry(type: "search", site: [$lang]) {
            uri
        }
    }
    ${MenuItemsFragmentDoc}
    ${BreadcrumbFragmentDoc}
    ${FooterItemsFragmentDoc}
    ${NotificationFragmentDoc}
`;
export const ReadMoreDocument = gql`
    query ReadMore($parentId: Int, $id: Int, $q: String, $notId: [QueryArgument], $lang: String) {
        siblings: entries(
            descendantOf: $parentId
            descendantDist: 1
            limit: 4
            type: ["page", "article"]
            id: $notId
            site: [$lang]
        ) {
            ...Highlight
        }
        ancestors: entries(ancestorOf: $id, limit: 4, type: ["page", "article"], site: [$lang]) {
            ...Highlight
        }
        search(q: $q, language: $lang, type: ["pages", "articles"]) {
            ...SearchHit
        }
    }
    ${HighlightFragmentDoc}
    ${SearchHitFragmentDoc}
`;

/**
 * __useReadMoreQuery__
 *
 * To run a query within a React component, call `useReadMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMoreQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      id: // value for 'id'
 *      q: // value for 'q'
 *      notId: // value for 'notId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useReadMoreQuery(baseOptions?: Apollo.QueryHookOptions<ReadMoreQuery, ReadMoreQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReadMoreQuery, ReadMoreQueryVariables>(ReadMoreDocument, options);
}
export function useReadMoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMoreQuery, ReadMoreQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReadMoreQuery, ReadMoreQueryVariables>(ReadMoreDocument, options);
}
export type ReadMoreQueryHookResult = ReturnType<typeof useReadMoreQuery>;
export type ReadMoreLazyQueryHookResult = ReturnType<typeof useReadMoreLazyQuery>;
export type ReadMoreQueryResult = Apollo.QueryResult<ReadMoreQuery, ReadMoreQueryVariables>;
export const RentalUnitsDocument = gql`
    query RentalUnits($slugs: [String!]!) {
        rentalUnits(slugs: $slugs) {
            ...rentalUnit
        }
    }
    ${RentalUnitFragmentDoc}
`;

/**
 * __useRentalUnitsQuery__
 *
 * To run a query within a React component, call `useRentalUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitsQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useRentalUnitsQuery(baseOptions: Apollo.QueryHookOptions<RentalUnitsQuery, RentalUnitsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RentalUnitsQuery, RentalUnitsQueryVariables>(RentalUnitsDocument, options);
}
export function useRentalUnitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RentalUnitsQuery, RentalUnitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RentalUnitsQuery, RentalUnitsQueryVariables>(RentalUnitsDocument, options);
}
export type RentalUnitsQueryHookResult = ReturnType<typeof useRentalUnitsQuery>;
export type RentalUnitsLazyQueryHookResult = ReturnType<typeof useRentalUnitsLazyQuery>;
export type RentalUnitsQueryResult = Apollo.QueryResult<RentalUnitsQuery, RentalUnitsQueryVariables>;
export const SearchFieldDocument = gql`
    query SearchField($locale: String!, $q: String!) {
        globalSearch(language: $locale, query: $q, limit: 50) {
            totalHits
            hits {
                ...SearchHit
            }
        }
    }
    ${SearchHitFragmentDoc}
`;

/**
 * __useSearchFieldQuery__
 *
 * To run a query within a React component, call `useSearchFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFieldQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchFieldQuery(baseOptions: Apollo.QueryHookOptions<SearchFieldQuery, SearchFieldQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchFieldQuery, SearchFieldQueryVariables>(SearchFieldDocument, options);
}
export function useSearchFieldLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchFieldQuery, SearchFieldQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchFieldQuery, SearchFieldQueryVariables>(SearchFieldDocument, options);
}
export type SearchFieldQueryHookResult = ReturnType<typeof useSearchFieldQuery>;
export type SearchFieldLazyQueryHookResult = ReturnType<typeof useSearchFieldLazyQuery>;
export type SearchFieldQueryResult = Apollo.QueryResult<SearchFieldQuery, SearchFieldQueryVariables>;
export const UpcomingReservationsDocument = gql`
    query UpcomingReservations {
        viewer {
            id
            orders {
                ...MyOrder
            }
        }
    }
    ${MyOrderFragmentDoc}
`;

/**
 * __useUpcomingReservationsQuery__
 *
 * To run a query within a React component, call `useUpcomingReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingReservationsQuery(
    baseOptions?: Apollo.QueryHookOptions<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>(
        UpcomingReservationsDocument,
        options
    );
}
export function useUpcomingReservationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>(
        UpcomingReservationsDocument,
        options
    );
}
export type UpcomingReservationsQueryHookResult = ReturnType<typeof useUpcomingReservationsQuery>;
export type UpcomingReservationsLazyQueryHookResult = ReturnType<typeof useUpcomingReservationsLazyQuery>;
export type UpcomingReservationsQueryResult = Apollo.QueryResult<
    UpcomingReservationsQuery,
    UpcomingReservationsQueryVariables
>;
export const RentalUnitsBySlugDocument = gql`
    query RentalUnitsBySlug($slugs: [String!]!) {
        rentalUnits(slugs: $slugs) {
            ...RentalUnitsBySlug
        }
    }
    ${RentalUnitsBySlugFragmentDoc}
`;

/**
 * __useRentalUnitsBySlugQuery__
 *
 * To run a query within a React component, call `useRentalUnitsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitsBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useRentalUnitsBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>(RentalUnitsBySlugDocument, options);
}
export function useRentalUnitsBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>(
        RentalUnitsBySlugDocument,
        options
    );
}
export type RentalUnitsBySlugQueryHookResult = ReturnType<typeof useRentalUnitsBySlugQuery>;
export type RentalUnitsBySlugLazyQueryHookResult = ReturnType<typeof useRentalUnitsBySlugLazyQuery>;
export type RentalUnitsBySlugQueryResult = Apollo.QueryResult<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>;
export const CompaniesBySlugDocument = gql`
    query CompaniesBySlug($slugs: [String!]!) {
        companiesBySlug(slugs: $slugs) {
            id
            name
            slug
            description
            webpListImage: listImage {
                transform(config: TILE, format: WEBP) {
                    ...ListImage
                }
            }
            listImage {
                transform(config: TILE, format: JPEG) {
                    ...ListImage
                }
            }
        }
    }
    ${ListImageFragmentDoc}
`;

/**
 * __useCompaniesBySlugQuery__
 *
 * To run a query within a React component, call `useCompaniesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useCompaniesBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>(CompaniesBySlugDocument, options);
}
export function useCompaniesBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>(CompaniesBySlugDocument, options);
}
export type CompaniesBySlugQueryHookResult = ReturnType<typeof useCompaniesBySlugQuery>;
export type CompaniesBySlugLazyQueryHookResult = ReturnType<typeof useCompaniesBySlugLazyQuery>;
export type CompaniesBySlugQueryResult = Apollo.QueryResult<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>;
export const ActivitiesBySlugDocument = gql`
    query ActivitiesBySlug($slugs: [String!]!) {
        activitiesBySlug(slugs: $slugs) {
            id
            name
            slug
            description
            webpListImage: listImage {
                transform(config: TILE, format: WEBP) {
                    ...ListImage
                }
            }
            listImage {
                transform(config: TILE, format: JPEG) {
                    ...ListImage
                }
            }
        }
    }
    ${ListImageFragmentDoc}
`;

/**
 * __useActivitiesBySlugQuery__
 *
 * To run a query within a React component, call `useActivitiesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useActivitiesBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>(ActivitiesBySlugDocument, options);
}
export function useActivitiesBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>(
        ActivitiesBySlugDocument,
        options
    );
}
export type ActivitiesBySlugQueryHookResult = ReturnType<typeof useActivitiesBySlugQuery>;
export type ActivitiesBySlugLazyQueryHookResult = ReturnType<typeof useActivitiesBySlugLazyQuery>;
export type ActivitiesBySlugQueryResult = Apollo.QueryResult<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>;
export const AccommodationsBySlugDocument = gql`
    query AccommodationsBySlug($slugs: [String!]!) {
        accommodationsBySlug(slugs: $slugs) {
            ...AccommodationsBySlug
        }
    }
    ${AccommodationsBySlugFragmentDoc}
`;

/**
 * __useAccommodationsBySlugQuery__
 *
 * To run a query within a React component, call `useAccommodationsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccommodationsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccommodationsBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useAccommodationsBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>(
        AccommodationsBySlugDocument,
        options
    );
}
export function useAccommodationsBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>(
        AccommodationsBySlugDocument,
        options
    );
}
export type AccommodationsBySlugQueryHookResult = ReturnType<typeof useAccommodationsBySlugQuery>;
export type AccommodationsBySlugLazyQueryHookResult = ReturnType<typeof useAccommodationsBySlugLazyQuery>;
export type AccommodationsBySlugQueryResult = Apollo.QueryResult<
    AccommodationsBySlugQuery,
    AccommodationsBySlugQueryVariables
>;
export const ArticleDocument = gql`
    query Article($slug: String, $lang: String, $notSlug: String) {
        entry(type: ["article"], slug: [$slug], site: [$lang]) {
            ...Article
        }
        related: entries(slug: [$notSlug], section: ["articles"], limit: 3, orderBy: "postDate DESC", site: [$lang]) {
            ...Card
        }
    }
    ${ArticleFragmentDoc}
    ${CardFragmentDoc}
`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      lang: // value for 'lang'
 *      notSlug: // value for 'notSlug'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
}
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlePathsDocument = gql`
    query ArticlePaths {
        entries(site: ["*"], section: ["articles"]) {
            __typename
            slug
            language
        }
    }
`;

/**
 * __useArticlePathsQuery__
 *
 * To run a query within a React component, call `useArticlePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlePathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlePathsQuery(
    baseOptions?: Apollo.QueryHookOptions<ArticlePathsQuery, ArticlePathsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticlePathsQuery, ArticlePathsQueryVariables>(ArticlePathsDocument, options);
}
export function useArticlePathsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ArticlePathsQuery, ArticlePathsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticlePathsQuery, ArticlePathsQueryVariables>(ArticlePathsDocument, options);
}
export type ArticlePathsQueryHookResult = ReturnType<typeof useArticlePathsQuery>;
export type ArticlePathsLazyQueryHookResult = ReturnType<typeof useArticlePathsLazyQuery>;
export type ArticlePathsQueryResult = Apollo.QueryResult<ArticlePathsQuery, ArticlePathsQueryVariables>;
export const ContactDocument = gql`
    query Contact($lang: String) {
        globalSet(handle: "contact", site: [$lang]) {
            ...ContactGlobals
        }
        entry(section: "contact", site: [$lang]) {
            ...Contact
        }
    }
    ${ContactGlobalsFragmentDoc}
    ${ContactFragmentDoc}
`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useContactQuery(baseOptions?: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
}
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>;
export const ContactMeDocument = gql`
    query ContactMe {
        viewer {
            id
        }
    }
`;

/**
 * __useContactMeQuery__
 *
 * To run a query within a React component, call `useContactMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactMeQuery(baseOptions?: Apollo.QueryHookOptions<ContactMeQuery, ContactMeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactMeQuery, ContactMeQueryVariables>(ContactMeDocument, options);
}
export function useContactMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactMeQuery, ContactMeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactMeQuery, ContactMeQueryVariables>(ContactMeDocument, options);
}
export type ContactMeQueryHookResult = ReturnType<typeof useContactMeQuery>;
export type ContactMeLazyQueryHookResult = ReturnType<typeof useContactMeLazyQuery>;
export type ContactMeQueryResult = Apollo.QueryResult<ContactMeQuery, ContactMeQueryVariables>;
export const HomeDocument = gql`
    query Home($lang: String) {
        entry(uri: ["__home__"], site: [$lang]) {
            ...Home
        }
    }
    ${HomeFragmentDoc}
`;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
}
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const NewsDocument = gql`
    query News($lang: String, $offset: Int, $limit: Int) {
        articleCount: entryCount(site: [$lang], section: "articles")
        articles: entries(site: [$lang], section: "articles", offset: $offset, limit: $limit) {
            ...Card
        }
        entry(section: ["news"], site: [$lang]) {
            ...News
        }
    }
    ${CardFragmentDoc}
    ${NewsFragmentDoc}
`;

/**
 * __useNewsQuery__
 *
 * To run a query within a React component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNewsQuery(baseOptions?: Apollo.QueryHookOptions<NewsQuery, NewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
}
export function useNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsQuery, NewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
}
export type NewsQueryHookResult = ReturnType<typeof useNewsQuery>;
export type NewsLazyQueryHookResult = ReturnType<typeof useNewsLazyQuery>;
export type NewsQueryResult = Apollo.QueryResult<NewsQuery, NewsQueryVariables>;
export const PageDocument = gql`
    query Page($uri: String, $lang: String, $retourUri: String) {
        entry(uri: [$uri], site: [$lang]) {
            ...Page
        }
        retourResolveRedirect(uri: $retourUri) {
            redirectDestUrl
            redirectSrcUrl
            redirectHttpCode
            redirectSrcMatch
            siteId
        }
    }
    ${PageFragmentDoc}
`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      uri: // value for 'uri'
 *      lang: // value for 'lang'
 *      retourUri: // value for 'retourUri'
 *   },
 * });
 */
export function usePageQuery(baseOptions?: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
}
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
}
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PagePathsDocument = gql`
    query PagePaths {
        entries(site: ["*"], type: ["page"]) {
            __typename
            uri
            slug
            language
        }
    }
`;

/**
 * __usePagePathsQuery__
 *
 * To run a query within a React component, call `usePagePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagePathsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePagePathsQuery(baseOptions?: Apollo.QueryHookOptions<PagePathsQuery, PagePathsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PagePathsQuery, PagePathsQueryVariables>(PagePathsDocument, options);
}
export function usePagePathsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PagePathsQuery, PagePathsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PagePathsQuery, PagePathsQueryVariables>(PagePathsDocument, options);
}
export type PagePathsQueryHookResult = ReturnType<typeof usePagePathsQuery>;
export type PagePathsLazyQueryHookResult = ReturnType<typeof usePagePathsLazyQuery>;
export type PagePathsQueryResult = Apollo.QueryResult<PagePathsQuery, PagePathsQueryVariables>;
export const SearchPageDocument = gql`
    query SearchPage($lang: String!, $q: String!, $types: [String!]) {
        globalSearch(language: $lang, query: $q, limit: 50, type: $types) {
            totalHits
            typeCounts {
                count
                label
            }
            hits {
                ...SearchHit
            }
        }
    }
    ${SearchHitFragmentDoc}
`;

/**
 * __useSearchPageQuery__
 *
 * To run a query within a React component, call `useSearchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      q: // value for 'q'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useSearchPageQuery(baseOptions: Apollo.QueryHookOptions<SearchPageQuery, SearchPageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchPageQuery, SearchPageQueryVariables>(SearchPageDocument, options);
}
export function useSearchPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchPageQuery, SearchPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchPageQuery, SearchPageQueryVariables>(SearchPageDocument, options);
}
export type SearchPageQueryHookResult = ReturnType<typeof useSearchPageQuery>;
export type SearchPageLazyQueryHookResult = ReturnType<typeof useSearchPageLazyQuery>;
export type SearchPageQueryResult = Apollo.QueryResult<SearchPageQuery, SearchPageQueryVariables>;
export const SearchPageAllAvailableFiltersDocument = gql`
    query SearchPageAllAvailableFilters($lang: String!, $q: String!) {
        globalSearch(language: $lang, query: $q, limit: 50) {
            totalHits
            typeCounts {
                count
                label
            }
        }
    }
`;

/**
 * __useSearchPageAllAvailableFiltersQuery__
 *
 * To run a query within a React component, call `useSearchPageAllAvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageAllAvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageAllAvailableFiltersQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchPageAllAvailableFiltersQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchPageAllAvailableFiltersQuery,
        SearchPageAllAvailableFiltersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchPageAllAvailableFiltersQuery, SearchPageAllAvailableFiltersQueryVariables>(
        SearchPageAllAvailableFiltersDocument,
        options
    );
}
export function useSearchPageAllAvailableFiltersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchPageAllAvailableFiltersQuery,
        SearchPageAllAvailableFiltersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchPageAllAvailableFiltersQuery, SearchPageAllAvailableFiltersQueryVariables>(
        SearchPageAllAvailableFiltersDocument,
        options
    );
}
export type SearchPageAllAvailableFiltersQueryHookResult = ReturnType<typeof useSearchPageAllAvailableFiltersQuery>;
export type SearchPageAllAvailableFiltersLazyQueryHookResult = ReturnType<
    typeof useSearchPageAllAvailableFiltersLazyQuery
>;
export type SearchPageAllAvailableFiltersQueryResult = Apollo.QueryResult<
    SearchPageAllAvailableFiltersQuery,
    SearchPageAllAvailableFiltersQueryVariables
>;
export const SearchDocument = gql`
    query Search($lang: String) {
        entry(section: ["search"], site: [$lang]) {
            ...Search
        }
    }
    ${SearchFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const AgendaDocument = gql`
    query Agenda($lang: String) {
        entry(section: ["agenda"], site: [$lang]) {
            ...Agenda
        }
    }
    ${AgendaFragmentDoc}
`;

/**
 * __useAgendaQuery__
 *
 * To run a query within a React component, call `useAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendaQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useAgendaQuery(baseOptions?: Apollo.QueryHookOptions<AgendaQuery, AgendaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AgendaQuery, AgendaQueryVariables>(AgendaDocument, options);
}
export function useAgendaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgendaQuery, AgendaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AgendaQuery, AgendaQueryVariables>(AgendaDocument, options);
}
export type AgendaQueryHookResult = ReturnType<typeof useAgendaQuery>;
export type AgendaLazyQueryHookResult = ReturnType<typeof useAgendaLazyQuery>;
export type AgendaQueryResult = Apollo.QueryResult<AgendaQuery, AgendaQueryVariables>;
export const AccoFeedDocument = gql`
    query AccoFeed($amount: Int, $offset: Int) {
        unitSearch(params: {}, limit: $amount, offset: $offset) {
            hits {
                rentalUnit {
                    id
                    name
                    slug
                    images {
                        transform(config: TILE, format: JPEG) {
                            src
                        }
                    }
                    tagline
                    descriptionLayout
                    type
                    accommodation {
                        place
                    }
                    maxOccupancy
                    livingArea
                    outdoorArea
                }
                trips {
                    price
                }
            }
            totalHits
        }
    }
`;

/**
 * __useAccoFeedQuery__
 *
 * To run a query within a React component, call `useAccoFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccoFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccoFeedQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAccoFeedQuery(baseOptions?: Apollo.QueryHookOptions<AccoFeedQuery, AccoFeedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccoFeedQuery, AccoFeedQueryVariables>(AccoFeedDocument, options);
}
export function useAccoFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccoFeedQuery, AccoFeedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccoFeedQuery, AccoFeedQueryVariables>(AccoFeedDocument, options);
}
export type AccoFeedQueryHookResult = ReturnType<typeof useAccoFeedQuery>;
export type AccoFeedLazyQueryHookResult = ReturnType<typeof useAccoFeedLazyQuery>;
export type AccoFeedQueryResult = Apollo.QueryResult<AccoFeedQuery, AccoFeedQueryVariables>;
export const SitemapDocument = gql`
    query Sitemap($locale: String, $size: Int) {
        entries(site: [$locale], uri: "!= ''") {
            url
            dateUpdated
            ... on page_Entry {
                heroImage {
                    url
                    title
                }
            }
            ... on news_Entry {
                heroImage {
                    url
                    title
                }
            }
        }
        travelbaseObjectsSitemap(language: $locale, size: $size) {
            url
            image {
                url
                title
            }
        }
    }
`;

/**
 * __useSitemapQuery__
 *
 * To run a query within a React component, call `useSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSitemapQuery(baseOptions?: Apollo.QueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
}
export function useSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
}
export type SitemapQueryHookResult = ReturnType<typeof useSitemapQuery>;
export type SitemapLazyQueryHookResult = ReturnType<typeof useSitemapLazyQuery>;
export type SitemapQueryResult = Apollo.QueryResult<SitemapQuery, SitemapQueryVariables>;
export const SqzlyFeedDocument = gql`
    query SqzlyFeed($amount: Int, $offset: Int) {
        unitSearch(params: {}, limit: $amount, offset: $offset) {
            hits {
                rentalUnit {
                    code
                    name
                    listImage {
                        transform(format: JPEG, config: TILE) {
                            src
                        }
                    }
                    slug
                    code
                    accommodation {
                        name
                        id
                    }
                    type
                }
                trips {
                    price
                }
            }
            totalHits
        }
    }
`;

/**
 * __useSqzlyFeedQuery__
 *
 * To run a query within a React component, call `useSqzlyFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSqzlyFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSqzlyFeedQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSqzlyFeedQuery(baseOptions?: Apollo.QueryHookOptions<SqzlyFeedQuery, SqzlyFeedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SqzlyFeedQuery, SqzlyFeedQueryVariables>(SqzlyFeedDocument, options);
}
export function useSqzlyFeedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SqzlyFeedQuery, SqzlyFeedQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SqzlyFeedQuery, SqzlyFeedQueryVariables>(SqzlyFeedDocument, options);
}
export type SqzlyFeedQueryHookResult = ReturnType<typeof useSqzlyFeedQuery>;
export type SqzlyFeedLazyQueryHookResult = ReturnType<typeof useSqzlyFeedLazyQuery>;
export type SqzlyFeedQueryResult = Apollo.QueryResult<SqzlyFeedQuery, SqzlyFeedQueryVariables>;
export const GlobalDocument = gql`
    query Global($lang: String) {
        ...GlobalQuery
    }
    ${GlobalQueryFragmentDoc}
`;

/**
 * __useGlobalQuery__
 *
 * To run a query within a React component, call `useGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGlobalQuery(baseOptions?: Apollo.QueryHookOptions<GlobalQuery, GlobalQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GlobalQuery, GlobalQueryVariables>(GlobalDocument, options);
}
export function useGlobalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalQuery, GlobalQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GlobalQuery, GlobalQueryVariables>(GlobalDocument, options);
}
export type GlobalQueryHookResult = ReturnType<typeof useGlobalQuery>;
export type GlobalLazyQueryHookResult = ReturnType<typeof useGlobalLazyQuery>;
export type GlobalQueryResult = Apollo.QueryResult<GlobalQuery, GlobalQueryVariables>;
